import {Type} from 'class-transformer';
import {IsString, ValidateNested} from 'class-validator';
import {IKeyValue} from 'interfaces/key-value';

interface IRay {
  ray: string;
  max_w: number;
  max_h: number;
  v_kbps: number;
  a_kbps: number;
}

interface IProfile {
  bools: IKeyValue<boolean>;
  desc: string;
  id: string;
  rays: IRay[];
  transition_to: string;
}

interface ILiveProfile {
  profile: string;
  slicer: string;
}

interface IPricingBreakdown {
  id: number;
  hours: string;
  cost: string;
  label: string;
}

interface IBreakdown {
  breakdown: IPricingBreakdown[];
}

interface IPricing {
  created: number;
  default: boolean;
  desc: string;
  encoding: IBreakdown;
  id: string;
  lastmod: number;
  num_owners: string;
}

export interface IOwner {
  account_state: string;
  ad_timeouts: object;
  billing_balance: number;
  billing_contacts: string;
  billing_moocher: number;
  brokerGroup: string;
  brokerGroups: string[];
  cloudProvider: string;
  cloudProviders: string[];
  cms_entitlements: IKeyValue<boolean>;
  cms_flags: IKeyValue<string>;
  cms_su_entitlements: IKeyValue<boolean>;
  contact_emails: string;
  created: string;
  deadbeat: number;
  default_live_profile: IProfile;
  default_profile: IProfile;
  deleted: number;
  demo_expires: boolean;
  id: string;
  ids_email?: string;
  ids_username?: string;
  lastmod: string;
  live_profiles: ILiveProfile;
  note: string;
  ops_entitlements: IKeyValue<boolean>;
  permissions: IKeyValue<boolean>;
  pricing: IPricing;
  secureStorageEnabled: boolean;
  sharing_enabled: number;
  slicer_profiles: IKeyValue<IProfile>;
  studiodrm: number;
  syndicationEnabled: number;
  username: string;
}

export class Owner {
  @IsString()
  public id: string;

  @IsString()
  public username: string;
}

export class OwnerResponse {
  @ValidateNested()
  @Type(() => Owner)
  public owners: Owner[];
}
