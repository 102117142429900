/* eslint-disable camelcase */
import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, LogManager, singleton} from 'aurelia-framework';
import {classToPlain} from 'class-transformer';
import {Acceo} from 'services/acceo';
import {DEFAULT_PER_PAGE_LIMIT} from './models/defaults';
import {SyndicationTarget, SyndicationTargetCollection, TargetsMeta} from './models/syndication';
import {
  SyndicationClippingProfile,
  SyndicationClippingProfileCollection,
  SyndicationFaceBookPage,
  SyndicationFaceBookPageCollection,
} from './models/syndication-facebook-page';

export const SYNDICATION_TARGET_URL: string = '/api/v4/syndication-target/';
export const SYNDICATION_TARGET_FACEBOOK_PAGES: string = '/api/v4/syndication-fb-pages';
export const SYNDICATION_TARGET_CLIPPING_PROFILES: string = '/api/v4/syndication-clipping-profiles';

const log = LogManager.getLogger('syndication-service-service');
@autoinject()
@singleton()
export class SyndicationTargetService {
  public meta: TargetsMeta = {};
  public params: any = {};
  public syndicationTargets: SyndicationTarget[] = [];
  public syndicationFaceBookPages: SyndicationFaceBookPage[] = [];

  constructor(public acceo: Acceo, public notification: CToastsService) {}

  public getSyndicationTargets(overrideTargets = true): Promise<SyndicationTarget[]> {
    let path = SYNDICATION_TARGET_URL;
    if (Object.keys(this.params).length > 0) {
      const {page, page_size} = this.params;
      const url_params = $.param({page, page_size});
      path += `?${url_params}`;
    }
    return new Promise((resolve, reject) => {
      this.acceo
        .get(SyndicationTargetCollection)(path)
        .then((resp: SyndicationTargetCollection) => {
          if (overrideTargets) {
            this.syndicationTargets = resp.items;
          } else {
            this.syndicationTargets = this.syndicationTargets.concat(resp.items);
          }
          this.meta.total = resp.total_items;
          this.meta.showing = this.syndicationTargets.length;
          this.meta.limit = this.params.page_size || null;
          this.meta.hasMore = this.meta.showing < this.meta.total;
          resolve(this.syndicationTargets);
        })
        .catch(er => {
          const msg = 'GET Error';
          log.error(`${msg}:`, er);
          reject(new Error(msg));
        });
    });
  }

  public getSyndicationTarget(id): Promise<SyndicationTarget> {
    return new Promise((resolve, reject) => {
      this.acceo
        .get(SyndicationTarget)(`${SYNDICATION_TARGET_URL}${id}`)
        .then((resp: SyndicationTarget) => {
          resolve(resp);
        })
        .catch(er => {
          const msg = 'GET Error';
          log.error(`${msg}:`, er);
          reject(new Error(msg));
        });
    });
  }

  public async deleteTarget(id) {
    let shouldRemove = true;

    try {
      await this.acceo.delete()(`${SYNDICATION_TARGET_URL}${id}/`);
    } catch (e) {
      if (e.status_code === 404) {
        // this will go to the finally and remove it from the away since it has
        // already been deleted!
        return;
      }

      shouldRemove = false;
      log.error(e);
      this.notification.error('Could not delete Target. Please try again.');
      throw Error(e.message);
    } finally {
      if (shouldRemove) {
        _.remove(this.syndicationTargets, {id});
        this.meta.total -= 1;
        this.meta.showing = this.syndicationTargets.length;
        this.meta.hasMore = this.meta.showing < this.meta.total;
      }
    }
  }

  public async testTarget(id): Promise<SyndicationTarget> {
    const errorMsg = 'Error testing Target';
    // eslint-disable-next-line no-return-await
    return await new Promise((resolve, reject) => {
      this.acceo
        .patch()(`${SYNDICATION_TARGET_URL}${id}/action`, {test: 'rtmp-connection'})
        .then((resp: SyndicationTarget) => {
          resolve(resp);
        })
        .catch(() => {
          log.error(errorMsg);
          reject(new Error(errorMsg));
        });
    });
  }

  public displayErrorInformation(errorInfo) {
    if (errorInfo.details !== undefined && errorInfo.details.length) {
      _.forEach(errorInfo.details, detail => {
        this.notification.error(detail);
      });
    } else if (errorInfo.description !== undefined) {
      this.notification.error(errorInfo.description);
    } else if (errorInfo.message !== undefined) {
      this.notification.error(errorInfo.message);
    }
  }

  public async saveSyndicationTarget(syndicationTarget: SyndicationTarget): Promise<SyndicationTarget[]> {
    const data: any = classToPlain(syndicationTarget);
    const {id} = syndicationTarget;
    const errorMsg = `Error ${id ? 'sav' : 'creat'}ing Syndication Target. Please try again.`;
    delete data.id;
    delete data.status;
    delete data.checkbox;
    // eslint-disable-next-line no-return-await
    return await new Promise((resolve, reject) => {
      if (id) {
        this.acceo
          .patch(SyndicationTarget)(`${SYNDICATION_TARGET_URL}${id}/`, data)
          .then((resp: SyndicationTarget) => {
            this.syndicationTargets = this.syndicationTargets.map(item => (item.id === resp.id ? resp : item));
            resolve(this.syndicationTargets);
          })
          .catch(e => {
            this.displayErrorInformation(e);
            log.error(errorMsg);
            reject(new Error(errorMsg));
          });
      } else {
        // added new target to targets list
        this.acceo
          .post(SyndicationTargetCollection)(SYNDICATION_TARGET_URL, data)
          .then((resp: SyndicationTargetCollection) => {
            this.syndicationTargets = _.uniqBy(this.syndicationTargets.concat(resp.items), 'id');
            this.meta.total += 1;
            this.meta.showing = this.syndicationTargets.length;
            this.meta.hasMore = this.meta.showing < this.meta.total;
            resolve(this.syndicationTargets);
          })
          .catch(e => {
            this.displayErrorInformation(e);
            log.error(errorMsg);
            reject(new Error(errorMsg));
          });
      }
    });
  }

  public getSyndicationTargetFaceBookPages(profile_id: string = ''): Promise<SyndicationFaceBookPage[]> {
    let url = SYNDICATION_TARGET_FACEBOOK_PAGES;
    if (profile_id) {
      url += `?profile_id=${profile_id}`;
    }
    return new Promise((resolve, reject) => {
      this.acceo
        .get(SyndicationFaceBookPageCollection)(url)
        .then((resp: SyndicationFaceBookPageCollection) => {
          this.syndicationFaceBookPages = resp.items;
          resolve(this.syndicationFaceBookPages);
        })
        .catch(er => {
          const msg = 'GET Error';
          log.error(`${msg}:`, er);
          reject(new Error(msg));
        });
    });
  }

  public getSyndicationTargetClippingProfiles(platform: string = ''): Promise<SyndicationClippingProfile[]> {
    let url = SYNDICATION_TARGET_CLIPPING_PROFILES;
    if (platform) {
      url += `?service=${platform}`;
    }
    return new Promise((resolve, reject) => {
      this.acceo
        .get(SyndicationClippingProfileCollection)(url)
        .then((resp: SyndicationClippingProfileCollection) => {
          resolve(resp.items);
        })
        .catch(er => {
          const msg = 'GET Error';
          log.error(`${msg}:`, er);
          reject(new Error(msg));
        });
    });
  }

  public updateParams(params: any = null, isLoadMore: boolean = false) {
    if (!params.page_size) {
      params.page_size = DEFAULT_PER_PAGE_LIMIT;
    }

    if (isLoadMore) {
      params.page =
        Math.floor((this.meta.total - (this.meta.total - this.syndicationTargets.length)) / params.page_size) + 1;
    }
    this.params = _.cloneDeep(params);
  }

  /**
   * Gets more jobs based on scrolling the table
   */
  public getMoreTargets() {
    if (this.meta.hasMore) {
      const params = _.cloneDeep(this.params);
      this.updateParams(params, true);
    }
  }
}
