import {autoinject, computedFrom, BindingEngine} from 'aurelia-framework';
import {CToastsService} from '@bindable-ui/bindable';

import * as moment from 'moment';

import {FormValidator, IFieldValidations} from 'apps/cms/utils/form-validator';
import {IToggleGroup} from 'components/toggle-group/c-toggle-group';

import {IWorkspaceKeyParams} from '../../models/workspace-key';
import {KeyFieldValidators, RevisionFieldValidators} from '../../models/validators';

import {WorkspaceKeyService} from '../../services/workspace-key-service';

export interface IActions {
  onClose: () => void;
  onSave: () => void;
}

export interface ICreateKeyModel {
  actions: IActions;
}

@autoinject()
export class CreateKey {
  public model: IWorkspaceKeyParams;
  public formValidator: FormValidator;
  public saveEnabled: boolean = true;

  public groups: IToggleGroup[];

  public invalidAfter;

  private actions: IActions;
  private trackValidation: boolean;
  private validators: IFieldValidations = {
    desc: KeyFieldValidators.desc,
    domain: RevisionFieldValidators.domain,
    invalid_after: RevisionFieldValidators.invalid_after,
    scope: RevisionFieldValidators.scope,
    title: KeyFieldValidators.title,
  };

  @computedFrom('formValidator.errors.title', 'formValidator.errors.desc', 'trackValidation')
  public get hasDetailsErrors() {
    if (!this.formValidator?.errors || !this.trackValidation) {
      return false;
    }

    const {title, desc} = this.formValidator.errors;

    return !!title || !!desc;
  }

  @computedFrom('formValidator.errors.scope', 'trackValidation')
  public get hasScopeErrors() {
    if (!this.formValidator?.errors || !this.trackValidation) {
      return false;
    }

    const {scope} = this.formValidator.errors;

    return !!scope;
  }

  @computedFrom('formValidator.errors.domain', 'formValidator.errors.invalid_after', 'trackValidation')
  public get hasAdvancedErrors() {
    if (!this.formValidator?.errors || !this.trackValidation) {
      return false;
    }

    const {domain, invalid_after} = this.formValidator.errors;

    return !!domain || !!invalid_after;
  }

  constructor(
    public bindingEngine: BindingEngine,
    public keyService: WorkspaceKeyService,
    public notificationService: CToastsService,
  ) {}

  /*
    Aurelia Hooks
  */

  public activate(model: ICreateKeyModel) {
    this.actions = model.actions;
    this.trackValidation = false;

    this.model = {
      desc: '',
      domain: '',
      invalid_after: '',
      scope: [],
      title: '',
      user_id: '',
    };

    this.formValidator = new FormValidator(this.model, this);
    this.formValidator.register(this.validators, this.bindingEngine, () => this.isValid());

    this.groups = [...this.keyService.scopes];
  }

  public deactivate() {
    this.formValidator = null;
    this.model = null;
  }

  /*
    Public Methods
  */

  public closeDialog(e?, preventClose: boolean = false) {
    e?.preventDefault();

    if (preventClose) {
      return;
    }

    if (this.keyService.isProcessing) {
      this.notificationService.warning('Processing, please wait.');
      return;
    }

    this.actions.onClose();
  }

  public async createKey() {
    this.formValidator.validate();
    this.trackValidation = true;

    if (!this.isValid()) {
      this.notificationService.error('Please fix issues and try again.');
      return;
    }

    try {
      await this.keyService.createKey(this.model);
      this.notificationService.success('Key created');

      this.actions.onSave();
    } catch (err) {
      this.notificationService.error(`Failed to create key: ${err.message}`);
    }
  }

  public updateInvalidAfter() {
    this.model.invalid_after = this.invalidAfter?.length > 0 ? moment(this.invalidAfter).toISOString() : null;
  }

  /*
    Private Methods
  */

  private isValid(): boolean {
    // We don't want to run the validations unless the user
    // has clicked the save button
    if (!this.trackValidation) {
      return true;
    }

    if (!this.formValidator) {
      this.saveEnabled = false;
      return this.saveEnabled;
    }

    this.saveEnabled = this.formValidator.isValid();

    return this.saveEnabled;
  }
}
