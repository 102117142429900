import {PLATFORM} from 'aurelia-pal';
import {AureliaConfiguration} from 'aurelia-configuration';
import {EventAggregator} from 'aurelia-event-aggregator';
import {inject} from 'aurelia-framework';
import {SessionService} from 'services/session';
import {Authorization} from 'services/authorization';
import {AuthorizeStep} from 'services/auth';
import {CheckIfLiveStep} from 'services/check-if-live';
import {BetaMode} from 'services/beta-mode';
import {HttpClient} from 'aurelia-fetch-client';

import * as moment from 'moment';

@inject(EventAggregator, AureliaConfiguration, SessionService, Authorization, BetaMode, HttpClient)
export class App {
  constructor(eventAggregator, config, sessionService, authorization, betaMode, httpClient) {
    this.eventAggregator = eventAggregator;
    // this.router = router;
    this.httpClient = httpClient;
    this.config = config;
    this.session = sessionService;
    this.betaMode = betaMode;

    this.copyrightYear = new Date().getFullYear();
    this.isOldAccount = false;

    const createdDate = new Date(this.session.sessionInfo.created * 1000);
    this.isOldAccount = moment(createdDate).isBefore('2020-04-01', 'day');

    document.documentElement.classList.add('lynk-theme-dark');

    const restrict = authorization.restrictRoute;

    const ENVS = [
      'downlynk',
      'uplynk',
    ];
    // anything containing localhost in the domain
    // or if neither downlynk or uplynk is found will default to localhost ENV
    const ENV = ENVS.find(str => window.location.hostname.indexOf(str) >= 0) || 'localhost';

    this.newMonitoring = {
      downlynk: 'https://monitor.downlynk.com',
      uplynk: 'https://monitor.uplynk.com',
    }[ENV];

    this.routes = [
      {
        route: '',
        redirect: 'content',
      },
      restrict({
        route: 'content',
        name: 'allContentIndex',
        moduleId: PLATFORM.moduleName('./routes/content/index'),
        nav: true,
        title: 'Content',
        settings: {
          live: true,
          icon: 'video-file',
          primary: true,
          legacy: true,
        },
      }),
      restrict({
        route: 'live-channels',
        name: 'liveChannelsIndex',
        moduleId: PLATFORM.moduleName('./routes/live-channels/index'),
        nav: true,
        title: 'Channels',
        settings: {
          live: true,
          icon: 'channel',
          primary: true,
          legacy: true,
        },
      }),
      restrict({
        route: 'live-events',
        name: 'liveEventsIndex',
        moduleId: PLATFORM.moduleName('./routes/live-events/index'),
        nav: true,
        title: 'Events',
        settings: {
          live: true,
          icon: 'camera-instant',
          primary: true,
          legacy: true,
        },
      }),
      {
        route: 'slicers',
        name: 'slicersIndex',
        moduleId: PLATFORM.moduleName('./routes/slicers/index'),
        nav: true,
        title: 'Ingest',
        settings: {
          live: true,
          icon: 'ingest-alt',
          primary: true,
          legacy: true,
        },
      },
      {
        route: 'syndication',
        name: 'syndicationIndex',
        moduleId: PLATFORM.moduleName('./routes/syndication/index'),
        nav: this.betaMode.hasScope('syndtab'),
        title: 'Syndication',
        settings: {
          live: true,
          icon: 'broadcast-video',
          primary: true,
          legacy: true,
        },
      },
      {
        route: 'services',
        name: 'servicesIndex',
        moduleId: PLATFORM.moduleName('./routes/services/index'),
        nav: true,
        title: 'Services',
        settings: {
          live: true,
          legacy: true,
        },
      },
      restrict({
        route: 'analytics',
        name: 'analyticsIndex',
        moduleId: PLATFORM.moduleName('./routes/analytics/index'),
        nav: true,
        title: 'Analytics',
        settings: {
          live: true,
          icon: 'analytics',
          primary: true,
          legacy: true,
        },
      }),
      {
        route: this.newMonitoring,
        name: 'monitoringExternal',
        navigationStrategy: () => undefined,
        nav: true,
        title: 'Monitoring',
        settings: {
          live: true,
          icon: 'monitoring',
          external: true,
          primary: true,
          legacy: false,
        },
      },
      {
        route: 'ad-server-debug',
        name: 'adServerDebugIndex',
        moduleId: PLATFORM.moduleName('./routes/ad-server-debug/index'),
        nav: true,
        title: 'Ad Debug',
        settings: {
          live: true,
          icon: 'ad-debug',
          primary: true,
          legacy: false,
        },
      },
      restrict({
        route: 'settings',
        name: 'settingsIndex',
        moduleId: PLATFORM.moduleName('./routes/settings/index'),
        nav: true,
        title: 'Settings',
        settings: {
          live: true,
          icon: 'settings',
          primary: true,
          legacy: true,
        },
      }),
      {
        route: 'downloads',
        name: 'downloadsIndex',
        moduleId: PLATFORM.moduleName('./routes/downloads/index'),
        nav: true,
        title: 'Downloads',
        settings: {
          live: true,
          legacy: false,
          icon: 'cloud-download',
          secondary: true,
          external: true,
        },
      },
      {
        route: 'https://support.uplynk.com',
        name: 'supportExternal',
        navigationStrategy: () => undefined,
        nav: true,
        title: 'Support',
        settings: {
          live: true,
          legacy: false,
          icon: 'life-preserver',
          secondary: true,
          external: true,
        },
      },
    ];

    // Handle new tab transitions.
    this.eventAggregator.subscribe('router:navigation:processing', resp => {
      const {live, newWindow} = resp.instruction.config.settings;

      if (live && newWindow) {
        window.open(newWindow);
      }
    });
  }

  getLogoLink() {
    let url = '#/';
    if (this.router.currentInstruction.config.name === 'adServerDebugIndex') {
      url = '#/ad-server-debug';
    }
    return url;
  }

  configureRouter(config, router) {
    this.router = router;
    config.addPipelineStep('authorize', CheckIfLiveStep);
    config.addPipelineStep('authorize', AuthorizeStep);
    config.map(this.routes);
  }

  attached() {
    this.session.sessionPing();
    this.session.notificationPing();
  }
}
