import {PLATFORM} from 'aurelia-pal';
import {DialogService} from 'aurelia-dialog';
import {computedFrom, inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Notification} from 'resources/notification/service';
import {SessionService} from 'services/session';
import {Authorization} from 'services/authorization';
import {LiveEventsService} from '../services/live-events';

const dirtyModel = {
  header: 'Are you sure you want to leave?',
  question: 'There are unsaved changes. If you leave before saving, your changes will be lost.',
  yes: 'Yes, Discard My Changes',
  hideNo: true,
  cancel: 'Cancel',
  footerClass: 'btn-split',
};

export const PUBLISH_TITLE = 'Publish';

export const eventRoute = '/live-events/events';

@inject(DialogService, Router, LiveEventsService, SessionService, Notification, Authorization)
export class LiveEventSingle {
  constructor(dialogService, router, liveEvents, sessionService, notification, authorization) {
    this.dialogService = dialogService;
    this.router = router;
    this.liveEvents = liveEvents;
    this.sessionService = sessionService;
    this.notification = notification;

    this.changeVOD = {};

    const restrict = authorization.restrictRoute;

    this.routes = [
      {
        route: '',
        name: 'liveEventDetails',
        moduleId: PLATFORM.moduleName('./details'),
        nav: true,
        title: 'Details',
        settings: {
          displayIfIsNew: true,
        },
      },
      {
        route: 'event-config',
        name: 'liveEventEventConfig',
        moduleId: PLATFORM.moduleName('./event-config'),
        nav: true,
        title: 'Config',
        settings: {
          displayIfIsNew: true,
        },
      },
      {
        route: 'slicers',
        name: 'liveEventSlicers',
        moduleId: PLATFORM.moduleName('./slicers'),
        nav: true,
        title: 'Slicers',
        settings: {
          displayIfIsNew: true,
        },
      },
      {
        route: 'slate',
        name: 'liveEventSlate',
        moduleId: PLATFORM.moduleName('./slate'),
        nav: true,
        title: 'Slate',
        settings: {
          displayIfIsNew: true,
        },
      },
      {
        route: 'pod-format',
        name: 'liveEventPodFormat',
        moduleId: PLATFORM.moduleName('./pod-format'),
        nav: true,
        title: 'Pod Format',
        settings: {
          displayIfIsNew: true,
        },
      },
      {
        route: 'metadata',
        name: 'liveEventMetadata',
        moduleId: PLATFORM.moduleName('./metadata'),
        nav: true,
        title: 'Metadata',
        settings: {
          displayIfIsNew: true,
        },
      },
      {
        route: 'event-playback',
        name: 'liveEventPlayback',
        moduleId: PLATFORM.moduleName('./playback'),
        nav: true,
        title: 'Playback',
        settings: {
          displayIfIsNew: false,
        },
      },
      {
        route: 'event-assets',
        name: 'liveEventEventAssets',
        moduleId: PLATFORM.moduleName('./event-assets'),
        nav: true,
        title: 'Assets',
        settings: {
          displayIfIsNew: false,
        },
      },
      {
        route: 'markers',
        name: 'liveEventMarkerLogs',
        moduleId: PLATFORM.moduleName('./marker-logs'),
        nav: true,
        title: 'Markers',
        settings: {
          displayIfIsNew: false,
        },
      },
      {
        route: 'logs',
        name: 'liveEventLogs',
        moduleId: PLATFORM.moduleName('./logs'),
        nav: true,
        title: 'Logs',
        settings: {
          displayIfIsNew: false,
        },
      },
      restrict({
        route: 'publish',
        name: 'liveEventPublish',
        moduleId: PLATFORM.moduleName('./publish'),
        nav: true,
        title: PUBLISH_TITLE,
        settings: {
          displayIfIsNew: false,
        },
      }),
    ];

    this.jumpLoading = false;
    this.jumpSearchText = '';
    this.jumpSearchTimeout = null;
  }

  /**
   * TODO - This is temporarily only popping dirty prompt on NEW. Also, isn't
   *        actually doing a check for dirty fields.
   */
  canDeactivate() {
    if (!this.liveEvents.isNew) {
      return true;
    }

    const self = this;

    if (!this.liveEvents.checkDirty) {
      return true;
    }

    return this.dialogService
      .open({
        viewModel: PLATFORM.moduleName('resources/dialog/yes-no-cancel'),
        model: dirtyModel,
      })
      .whenClosed(response => {
        if (!response.wasCancelled) {
          self.liveEvents.isLeavingNewRoute = true;
        }

        return !response.wasCancelled;
      });
  }

  deactivate() {
    if (!this.liveEvents.isNew) {
      return;
    }

    if (this.liveEvents.isLeavingNewRoute) {
      this.liveEvents.cleanupNewEvent();
    }
    this.liveEvents.checkDirty = true;
  }

  @computedFrom('liveEvents.model')
  get model() {
    return this.liveEvents.model;
  }

  @computedFrom(
    'router.navigation',
    'liveEvents.isNew',
    'liveEvents.tabs.liveEventDetails.isValid',
    'liveEvents.tabs.liveEventEventConfig.isValid',
    'liveEvents.tabs.liveEventSlicers.isValid',
    'liveEvents.conflicts',
    'liveEvents.tabs.liveEventMetadata.isValid',
    'liveEvents.tabs.liveEventPublish.isValid',
  )
  get LENavigation() {
    const nav = [];
    const {hasConflicts} = this.liveEvents;
    const routerNav = this.router.navigation;
    const newNav = routerNav.filter(row => row.settings.displayIfIsNew);
    const navigation = this.liveEvents.isNew ? newNav : routerNav;

    navigation.forEach(row => {
      const route = row;
      const tab = this.liveEvents.tabs[row.config.name];

      if (tab.isValid !== null && !tab.isValid) {
        route.settings.alert = 'danger';
      }

      if (row.config.name === 'liveEventSlicers' && hasConflicts) {
        route.settings.alert = 'warning';
      }

      nav.push(route);
    });

    return nav;
  }

  @computedFrom(
    'liveEvents.tabs.liveEventDetails.isValid',
    'liveEvents.tabs.liveEventEventConfig.isValid',
    'liveEvents.tabs.liveEventSlicers.isValid',
    'liveEvents.tabs.liveEventMetadata.isValid',
    'liveEvents.tabs.liveEventPublish.isValid',
  )
  get canCreateEvent() {
    const tabsAreValid =
      this.liveEvents.tabs.liveEventDetails.isValid &&
      this.liveEvents.tabs.liveEventEventConfig.isValid &&
      this.liveEvents.tabs.liveEventSlicers.isValid &&
      this.liveEvents.tabs.liveEventMetadata.isValid &&
      this.liveEvents.tabs.liveEventPublish.isValid;

    return tabsAreValid;
  }

  configureRouter(config, router) {
    this.router = router;

    // If they don't have access, remove it from the navigation
    if (!this.sessionService.isSyndicationEnabled()) {
      this.routes = this.routes.filter(r => !PUBLISH_TITLE.includes(r.title));
    }

    // fallback to account settings page.
    config.mapUnknownRoutes({redirect: this.routes[0].route[1]});
    config.map(this.routes);
  }

  openDashboard() {
    let dashboardUrl = `/static/cms2/live-event-dashboard.html?event=${this.model.id}`;
    if (this.sessionService.isIdsEnabled()) {
      dashboardUrl = `${dashboardUrl}&idsEnabled=true`;
    }
    window.open(dashboardUrl);
  }

  navJumpTo(eventId) {
    this.liveEvents.isLeavingNewRoute = true;
    window.location.href = `#${eventRoute}/${eventId}/${this.router.currentInstruction.config.route}`;
    this.liveEvents.setRecordIndex(eventId);
  }

  navEventList() {
    this.router.navigateToRoute('liveEventList');
  }

  navPrev() {
    if (this.prev) {
      const configRoute = this.router.currentInstruction.config.route;

      if (this.liveEvents.eventIsDirty && this.liveEvents.sessionWrite) {
        this.dialogService
          .open({
            viewModel: PLATFORM.moduleName('resources/dialog/yes-no-cancel'),
            model: dirtyModel,
          })
          .whenClosed(response => {
            if (!response.wasCancelled) {
              this.liveEvents.isLeavingNewRoute = true;
              this.liveEvents.checkDirty = false;

              // eslint-disable-next-line max-len
              window.location.href = `#${eventRoute}/${this.prev.id}/${configRoute}`;
              this.liveEvents.recordIndex -= 1;
            }
          });
      } else {
        this.liveEvents.isLeavingNewRoute = true;
        window.location.href = `#${eventRoute}/${this.prev.id}/${configRoute}`;
        this.liveEvents.recordIndex -= 1;
      }
    }
  }

  @computedFrom('liveEvents.recordIndex', 'liveEvents.data')
  get prev() {
    const current = this.liveEvents.recordIndex;
    if (current != null) {
      if (current > 0) {
        return this.liveEvents.data[current - 1];
      }
    }
    return undefined;
  }

  navNext() {
    if (this.next) {
      const configRoute = this.router.currentInstruction.config.route;

      if (this.liveEvents.eventIsDirty && this.liveEvents.sessionWrite) {
        this.dialogService
          .open({
            viewModel: PLATFORM.moduleName('resources/dialog/yes-no-cancel'),
            model: dirtyModel,
          })
          .whenClosed(response => {
            if (!response.wasCancelled) {
              this.liveEvents.isLeavingNewRoute = true;
              this.liveEvents.checkDirty = false;

              // eslint-disable-next-line max-len
              window.location.href = `#${eventRoute}/${this.next.id}/${configRoute}`;
              this.liveEvents.recordIndex += 1;
            }
          });
      } else {
        this.liveEvents.isLeavingNewRoute = true;
        this.router.navigate(`${eventRoute}/${this.next.id}/${configRoute}`);
        this.liveEvents.recordIndex += 1;
      }
    }
  }

  @computedFrom('liveEvents.recordIndex', 'liveEvents.data')
  get next() {
    const current = this.liveEvents.recordIndex;
    if (current != null) {
      if (current < this.liveEvents.data.length - 1) {
        return this.liveEvents.data[current + 1];
      }
    }
    return undefined;
  }

  clearFilter() {
    this.jumpSearchText = '';
    this.jumpLoading = true;
    this.liveEvents.isFiltered = false;

    const params = {
      order: '-created',
      limit: 20,
      skip: 0,
    };
    this.liveEvents.searchText = '';
    this.liveEvents
      .getLiveEvents(this.liveEvents.sanitizeParams(params))
      .then(() => {
        this.jumpLoading = false;
        this.liveEvents.setRecordIndex(this.model.id);
      })
      .catch(() => {});
  }

  jumpSearch() {
    if (this.jumpSearchTimeout) {
      clearTimeout(this.jumpSearchTimeout);
    }
    this.jumpSearchTimeout = setTimeout(() => {
      this.jumpLoading = true;
      const params = {
        order: '-created',
        limit: 20,
        skip: 0,
        search: this.jumpSearchText,
      };
      this.liveEvents.searchText = this.jumpSearchText;
      this.liveEvents
        .getLiveEvents(this.liveEvents.sanitizeParams(params))
        .then(() => {
          this.jumpLoading = false;
          this.liveEvents.setRecordIndex(this.model.id);
        })
        .catch(() => {});
    }, 500);
  }

  async tabClick(row, event) {
    const route = row.config.name;

    // Prevent the tab-group from activating the new tab immediately
    event.stopPropagation();

    // Fire off the dialog alert if dirty is detected
    this.liveEvents.checkDirty = true;

    // Wait until the router actually navigates
    await this.router.navigateToRoute(route);

    // Use the lynk-tab-group show() method to manually activate the tabs
    if (this.router.currentInstruction.config.name === route) {
      this.leTabs.show(route);
    }
  }

  openChangeVODModal() {
    this.changeVOD.altVodAsset = this.liveEvents.origEvent.assets.join(', ');
    this.changeVOD.error = null;

    this.changeVodDialog.show();
  }

  saveVOD() {
    const uuidRegex = /^[0-9a-fA-F]{32}$/;

    if (this.changeVOD.isSaving || !this.changeVOD.altVodAsset || !this.changeVOD.altVodAsset.length) {
      return false;
    }

    this.changeVOD.error = null;

    const assetList = this.changeVOD.altVodAsset.split(',');

    _.forEach(assetList, asset => {
      if (!uuidRegex.test(asset.trim())) {
        this.changeVOD.error = `Not a valid Asset ID - ${asset}`;
      }
    });

    if (this.changeVOD.error !== null) {
      return false;
    }

    this.changeVOD.isSaving = true;

    return this.liveEvents
      .setVodReplay(this.model.id, this.changeVOD.altVodAsset)
      .then(() => {
        this.notification.success('Successfully updated VOD asset');
        this.changeVodDialog.hide();
        this.changeVOD.isSaving = false;
      })
      .catch(err => {
        this.changeVOD.error = err;
        this.changeVOD.isSaving = false;
      });
  }
}
