import {bindable, computedFrom} from 'aurelia-framework';
import {EMPTY_ACTION_MODAL} from '../constants';
import {SyndicationPlatformType} from '../../../services/models/syndication';
import {SessionService} from '../../../services/session';

export interface ActionModal {
  callback?: () => void;
  title: string;
  body: string;
  color: string;
  buttonText: string;
}

export class ActionButtons {
  @bindable public description: string;
  @bindable public platform: SyndicationPlatformType;
  @bindable public status: string;
  @bindable public session: SessionService;
  @bindable public resumeIntervalAction: () => void;
  @bindable public pauseIntervalAction: () => void;
  @bindable public startScheduleAction: () => void;
  @bindable public stopScheduleAction: () => void;
  public actionData: ActionModal;
  public actionModal: any;

  constructor() {
    this.actionData = EMPTY_ACTION_MODAL;
  }

  public verifyStart() {
    this.actionData = {
      callback: this.start.bind(this),
      title: 'Start Schedule',
      body: `Would you like to start the ${this.description} publishing job?`,
      color: 'primary',
      buttonText: 'Start',
    };

    this.actionModal.show();
  }

  public verifyStop() {
    this.actionData = {
      callback: this.stop.bind(this),
      title: 'Stop Schedule',
      body: `Would you like to stop the ${this.description} publishing job?`,
      color: 'primary',
      buttonText: 'Stop',
    };

    this.actionModal.show();
  }

  public resumeInterval() {
    if (this.actionModal.open) {
      return;
    }
    this.resumeIntervalAction();
  }

  public pauseInterval() {
    this.pauseIntervalAction();
  }

  private start() {
    this.startScheduleAction();
    this.actionModal.hide();
  }

  private stop() {
    this.stopScheduleAction();
    this.actionModal.hide();
  }

  @computedFrom('status')
  get disableStart() {
    return this.status !== 'stopped' || this.wurlWithoutAccess;
  }

  @computedFrom('status')
  get disableStop() {
    return this.status !== 'active' || this.wurlWithoutAccess;
  }

  @computedFrom('platform', 'session')
  get wurlWithoutAccess() {
    return this.platform === SyndicationPlatformType.WURL && !this.session.hasFastAccess;
  }
}
