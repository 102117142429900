import {autoinject, LogManager} from 'aurelia-framework';
import {Logger} from 'aurelia-logging';

import {IPaginationMeta, IHyperionFilterParams} from 'interfaces/hyperion';
import {Acceo} from 'services/acceo';

import {ISsoDomainParams} from '../models/sso-domain';
import {SsoDomainUserResponse, SsoDomainUser} from '../models/sso-domain-user';

import {BASE_DOMAIN_URL} from '../constants';

export const DEFAULT_PER_PAGE_LIMIT = 20;

@autoinject()
export class SsoDomainUserService {
  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;
  public isProcessing: boolean = false;

  public params: IHyperionFilterParams = {};
  public meta: IPaginationMeta = {};

  public records: SsoDomainUser[] = [];
  public selected: string[] = [];

  protected logger: Logger;

  constructor(protected acceo: Acceo) {
    this.logger = LogManager.getLogger('SSO Domain Service');
  }

  /* ******************** */
  /*    Public Methods    */
  /* ******************** */

  /**
   * Get IDS Users
   * @param params
   * @param isLoadingMore
   */
  public async getRecords(
    domainId: string,
    params: ISsoDomainParams = {},
    isLoadingMore: boolean = false,
  ): Promise<void> {
    if (this.isLoading || this.isLoadingMore) {
      return;
    }

    if (!params.page_size) {
      params.page_size = DEFAULT_PER_PAGE_LIMIT;
    }

    if (params.order) {
      delete params.order;
    }

    if (!isLoadingMore) {
      this.records = [];
      this.isLoading = true;
      params.page = 1;
    } else {
      if (!this.meta.hasMore) {
        return;
      }

      this.isLoadingMore = true;
      params.page = (params.page || 1) + 1;
    }

    this.params = _.cloneDeep(params);

    try {
      const urlParams = $.param(this.params);

      const url = `${BASE_DOMAIN_URL}/${domainId}/users?${urlParams}`;

      const resp = await this.acceo.get(SsoDomainUserResponse)(url);

      this.records = _.uniqBy(this.records.concat(resp.items), 'id');

      // Safety check to prevent query spamming
      const totalPages = Math.ceil(resp.total_items / params.page_size);

      this.meta.total = resp.total_items;
      this.meta.showing = this.records.length;
      this.meta.limit = params.page_size || null;
      this.meta.hasMore = params.page < totalPages;
    } catch (err) {
      this.logger.error(err);
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
    }
  }

  /**
   * Loads more records
   */
  public async getMore(domainId: string, continueLoading: boolean = false) {
    if (this.isLoading || this.isLoadingMore || !this.meta.hasMore) {
      return;
    }

    const params = _.cloneDeep(this.params);

    await this.getRecords(domainId, params, true);

    if (continueLoading) {
      await this.getMore(domainId, true);
    }
  }

  /**
   * Poll workspace keys for changes
   * @param params
   */
  public async pollRecords(domainId: string, params: IHyperionFilterParams): Promise<SsoDomainUserResponse> {
    const pollArgs = _.cloneDeep(params);
    delete pollArgs.page_size;
    delete pollArgs.page;
    delete pollArgs.order;

    const queryParams = $.param(pollArgs);

    const url = `${BASE_DOMAIN_URL}/${domainId}/users?${queryParams}`;

    return this.acceo.get(SsoDomainUserResponse)(url);
  }

  /**
   * Processes changes from polling
   * @param changes
   */
  public processPollData(changes: SsoDomainUserResponse): boolean {
    if (!changes || changes.total_items === 0) {
      return false;
    }

    const {items = []} = changes;

    items.forEach(i => this.updateLocalRecord(i));
    this.records = _.uniqBy(this.records.concat(), 'id');

    return true;
  }

  /* ********************* */
  /*    Private Methods    */
  /* ********************* */

  /**
   * Updates or adds a record to the keys collection
   * @param record
   */
  private addOrUpdateRecord(record: SsoDomainUser): void {
    const index = this.records.findIndex(k => k.id === record.id);

    if (index < 0) {
      this.meta.total += 1;
      this.meta.showing += 1;
      this.records.unshift(record);
    } else {
      this.records[index] = record;
    }
  }

  /**
   * Removes record from keys collection if found
   * @param record
   */
  private removeLocalRecord(record: SsoDomainUser) {
    const index = this.records.findIndex(k => k.id === record.id);

    if (index < 0) {
      return;
    }

    this.meta.total -= 1;
    this.meta.showing -= 1;
    this.records.splice(index, 1);
  }

  /**
   * Updates record in keys collection
   * @param record
   */
  private updateLocalRecord(record: SsoDomainUser) {
    if (record.deleted) {
      this.removeLocalRecord(record);
    } else {
      this.addOrUpdateRecord(record);
    }
  }
}
