import {Exclude, Expose, Type} from 'class-transformer';
import {IsArray, IsBoolean, IsInt, IsNumber, IsOptional, IsString, ValidateNested} from 'class-validator';

export enum SyndicationPlatformType {
  FACEBOOK = 'Facebook',
  HULU = 'Hulu',
  CUSTOM = 'Custom',
  TWITCH = 'Twitch',
  YOUTUBE = 'YouTube',
  AWS_MEDIACONNECT = 'AWS Elemental MediaConnect',
  PLUTO_TV = 'Pluto TV',
  TIKTOK = 'TikTok',
  WURL = 'Wurl',
}

export enum SyndicationTargetProtocolType {
  RTMP = 'rtmp',
  RTMPS = 'rtmps',
  RTMP_PULL = 'rtmp-pull',
  ZIXI = 'zixi',
  HLS_PUSH = 'hls',
  HLS_PULL = 'hls-pull',
  SRT = 'srt',
  RIST = 'rist',
}

export const SYNDICATION_TARGET_PROTOCOLS_TYPES_TEXTS = {
  [SyndicationTargetProtocolType.RTMP]: 'RTMP',
  [SyndicationTargetProtocolType.RTMPS]: 'RTMPS',
  [SyndicationTargetProtocolType.RTMP_PULL]: 'RTMP - PULL',
  [SyndicationTargetProtocolType.ZIXI]: 'ZIXI - PUSH',
  [SyndicationTargetProtocolType.HLS_PUSH]: 'HLS - PUSH',
  [SyndicationTargetProtocolType.HLS_PULL]: 'HLS - PULL',
  [SyndicationTargetProtocolType.SRT]: 'SRT',
  [SyndicationTargetProtocolType.RIST]: 'RIST',
};

export const SYNDICATION_TARGET_PLATFORM_PROTOCOLS = {
  [SyndicationPlatformType.FACEBOOK]: [SyndicationTargetProtocolType.RTMPS],
  [SyndicationPlatformType.YOUTUBE]: [SyndicationTargetProtocolType.RTMP],
  [SyndicationPlatformType.TWITCH]: [SyndicationTargetProtocolType.RTMP],
  [SyndicationPlatformType.HULU]: [SyndicationTargetProtocolType.HLS_PUSH],
  [SyndicationPlatformType.AWS_MEDIACONNECT]: [
    SyndicationTargetProtocolType.ZIXI,
    SyndicationTargetProtocolType.SRT,
    SyndicationTargetProtocolType.RIST,
  ],
  [SyndicationPlatformType.PLUTO_TV]: [SyndicationTargetProtocolType.ZIXI],
  [SyndicationPlatformType.TIKTOK]: [SyndicationTargetProtocolType.RTMP],
  [SyndicationPlatformType.WURL]: [SyndicationTargetProtocolType.HLS_PULL],
};

export const SYNDICATION_TARGET_PLATFORM_BETA = [
  SyndicationPlatformType.HULU,
  SyndicationPlatformType.WURL,
];
export const SYNDICATION_TARGET_PROTOCOL_BETA = [SyndicationTargetProtocolType.RTMP_PULL];

export enum SyndicationScheduleVideoQualityType {
  HIGH = 'high',
  LOW = 'low',
  MEDIUM = 'medium',
}

export enum SyndicationScheduleType {
  DOWNLOADER = 'downloader',
}

export enum SyndicationScheduleStatus {
  SCHEDULED = 'scheduled',
  ACTIVE = 'active',
  STOPPED = 'stopped',
  ERROR = 'error',
}

export enum SyndicationScheduleContentType {
  CHANNEL = 'c',
  LIVE_EVENT = 'e',
}

export enum SyndicationHlsUriType {
  CHANNEL_ID = 'channel_id',
  NONE = 'none',
}

export enum SyndicationYouTubeIntegratedPrivacy {
  PUBLIC = 'public',
  PRIVATE = 'private',
  UNLISTED = 'unlisted',
}

export const SYNDICATION_SCTE35_ALLOWED = [
  SyndicationTargetProtocolType.ZIXI,
  SyndicationTargetProtocolType.HLS_PUSH,
  SyndicationTargetProtocolType.HLS_PULL,
  SyndicationTargetProtocolType.SRT,
  SyndicationTargetProtocolType.RIST,
];

export enum SyndicationScte35Types {
  DATERANGE = 'daterange',
  CUE = 'cue',
}

export class PlatformConfig {
  // START ZIXI
  @IsString()
  @IsOptional()
  public host?: string = '';

  @IsInt()
  @IsOptional()
  public port?: number = 2088;

  @IsString()
  @IsOptional()
  public stream_id?: string = '';

  @IsString()
  @IsOptional()
  public remote_id?: string = '';

  @IsString()
  @IsOptional()
  public password?: string = '';
  // END ZIXI

  // START HLS
  @IsString()
  @IsOptional()
  public hls_uri_type?: string = '';
  // END HLS

  @IsBoolean()
  @IsOptional()
  public integrated?: boolean = false; // Facebook + YouTube

  @IsBoolean()
  @IsOptional()
  public clipping_profile_id?: string = ''; // Facebook + YouTube

  @IsString()
  @IsOptional()
  public page_id?: string = ''; // Facebook

  @IsBoolean()
  @IsOptional()
  public re_schedule?: boolean = false; // Facebook
}

export class SyndicationTarget {
  @IsString()
  public description: string;

  @IsString()
  public id: string;

  @IsString()
  public platform?: SyndicationPlatformType = SyndicationPlatformType.TWITCH;

  @IsString()
  @IsOptional()
  public status: string;

  @IsString()
  public target_protocol: SyndicationTargetProtocolType = SyndicationTargetProtocolType.RTMP;

  @IsString()
  // doesn't like rtmp://xxxx  Class validator gives an invalid URL
  // @IsUrl()
  public target_stream: string;

  @Type(() => PlatformConfig)
  @IsOptional()
  public platform_config: PlatformConfig = new PlatformConfig();

  @Exclude()
  public test: any;

  // seems like since this is initialized we need to apply @Exclude tag to skip this field.
  @Exclude()
  public checkbox?: boolean = false;
}

export class SyndicationTargetCollection {
  @ValidateNested()
  @Type(() => SyndicationTarget)
  public items: SyndicationTarget[];

  @IsInt()
  public total_items: number;
}

export class TargetPlatformConfig {
  @IsString()
  @IsOptional()
  public channel_id?: string = '';

  @IsString()
  @IsOptional()
  public hls_master_url?: string = '';

  @IsString()
  @IsOptional()
  public rtmp_pull_url?: string = '';

  @IsArray()
  @IsOptional()
  public rays?: any[] = [];

  @IsString()
  @IsOptional()
  public integrated_title?: string = '';

  @IsString()
  @IsOptional()
  public integrated_description?: string = '';

  @IsString()
  @IsOptional()
  public integrated_yt_privacy?: string = '';

  @IsBoolean()
  @IsOptional()
  public integrated_yt_made_for_kids?: boolean = false;

  @IsString()
  @IsOptional()
  public scte35_type?: string = SyndicationScte35Types.DATERANGE;

  @IsNumber()
  @IsOptional()
  public srt_latency?: number = 500;
}

export class SyndicationSchedule {
  @IsString()
  public audio_track: string = '';

  @IsString()
  public content_id: string;

  @IsString()
  public content_type: SyndicationScheduleContentType = SyndicationScheduleContentType.CHANNEL;

  @IsString()
  @IsOptional()
  public err_msg: string = '';

  @IsString()
  public id: string;

  // @IsInt()
  // public last_status: number;

  @IsString()
  @IsOptional()
  public region: string = '';

  @IsBoolean()
  @IsOptional()
  public region_auto_selected: boolean = false;

  @IsString()
  public source_stream = '';

  @IsString()
  @IsOptional()
  public status: string = '';

  // @IsString()
  // public start: string = '';

  // @IsString()
  // public stop: string = '';

  @IsString()
  public syndication_target_description: string;

  @IsString()
  public syndication_target_id: string = '';

  @IsString()
  public type?: SyndicationScheduleType = SyndicationScheduleType.DOWNLOADER;

  @IsString()
  public video_quality?: SyndicationScheduleVideoQualityType = SyndicationScheduleVideoQualityType.HIGH;

  @IsOptional()
  @IsInt()
  public scte35_enabled: number;

  @Type(() => TargetPlatformConfig)
  @IsOptional()
  public target_platform_config: TargetPlatformConfig = new TargetPlatformConfig();

  @IsOptional()
  public source_url_params: {[key: string]: string} = {};

  @IsOptional()
  @IsBoolean()
  public url_params_active: boolean = false;

  // seems like since this is initialized we need to apply @Exclude tag to skip this field.
  @Exclude()
  public checkbox?: boolean = false;

  @Exclude()
  public is_new?: boolean = false;

  @Exclude()
  public is_staged?: boolean = false;

  @Exclude()
  public canUseSCTE?: boolean = false;

  @Exclude()
  public platform?: SyndicationPlatformType;

  @Exclude()
  public target_protocol?: SyndicationTargetProtocolType;
}

export class SyndicationScheduleCollection {
  @ValidateNested()
  @Type(() => SyndicationSchedule)
  public items: SyndicationSchedule[];

  @IsInt()
  public total_items: number;
}

export class SyndicationRay {
  @IsBoolean()
  @IsOptional()
  public active: boolean;

  @IsString()
  @IsOptional()
  public ray_name: string;

  @IsString()
  public audio_codec: string;

  @IsInt()
  public audio_kbps: number;

  @IsInt()
  public fps: number;

  // @IsInt()
  // public id: number;

  @IsInt()
  public max_height: number;

  @IsInt()
  public max_width: number;

  @IsString()
  public video_codec: string;

  @IsInt()
  public video_kbps: number;
}

export class SyndicationRayDetail {
  @ValidateNested()
  @Type(() => SyndicationRay)
  public low: SyndicationRay[];

  @ValidateNested()
  @Type(() => SyndicationRay)
  public medium: SyndicationRay[];

  @ValidateNested()
  @Type(() => SyndicationRay)
  public high: SyndicationRay[];

  @ValidateNested()
  @Type(() => SyndicationRay)
  public all: SyndicationRay[];
}

export class SyndicationRegion {
  @Expose({name: 'id'})
  @IsString()
  public value: string;

  @Expose({name: 'name'})
  @IsString()
  public text: string;
}

export class SyndicationRegionCollection {
  @ValidateNested()
  @Type(() => SyndicationRegion)
  public items: SyndicationRegion[];

  @IsInt()
  public total_items: number;
}

export interface TargetsMeta {
  total?: number;
  showing?: number;
  limit?: number;
  nextToken?: number;
  hasMore?: boolean;
}
