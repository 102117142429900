import {CToastsService} from '@bindable-ui/bindable';
import {autoinject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {authorizationConstants} from 'services/authorization';
import {SyndicationOwnerConfig} from 'services/models/syndication-owner-config';
import {SyndicationOwnerConfigService} from 'services/syndication-owner-config-service';

@authorizationConstants
@autoinject()
export class PublishingConfiguration {
  public routeName;
  public isLoading: boolean = false;
  public loadingError: boolean = false;
  public ownerConfig: SyndicationOwnerConfig;

  constructor(
    public router: Router,
    public notification: CToastsService,
    public syndicationOwnerConfigService: SyndicationOwnerConfigService,
  ) {}

  public activate({}, routeConfig) {
    this.routeName = routeConfig.name || '';
    this.init();
  }

  public async init() {
    this.isLoading = true;
    await this.syndicationOwnerConfigService
      .getSyndicationOwnerConfig()
      .then((ownerConfig: SyndicationOwnerConfig) => {
        this.ownerConfig = _.cloneDeep(ownerConfig);
      })
      .catch(() => {
        this.loadingError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public async save() {
    const ownerConfig = new SyndicationOwnerConfig();
    ownerConfig.sns_topic = this.ownerConfig.sns_topic;
    try {
      await this.syndicationOwnerConfigService
        .saveSyndicationOwnerConfig(ownerConfig)
        .then((data: SyndicationOwnerConfig) => {
          this.ownerConfig = _.cloneDeep(data);
        });
    } catch (e) {
      return false;
    }
    return true;
  }
}
