import {CToastsService} from '@bindable-ui/bindable';
import {DialogService} from 'aurelia-dialog';
import {autoinject, computedFrom} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {BetaMode} from 'services/beta-mode';
import {
  SYNDICATION_SCTE35_ALLOWED,
  SyndicationHlsUriType,
  SyndicationPlatformType,
  SyndicationRayDetail,
  SyndicationRegion,
  SyndicationSchedule,
  SyndicationScheduleContentType,
  SyndicationScheduleVideoQualityType,
  SyndicationScte35Types,
  SyndicationTarget,
  SyndicationTargetProtocolType,
  SyndicationYouTubeIntegratedPrivacy,
} from 'services/models/syndication';
import {SessionService} from 'services/session';
import {syndicationScheduleActions, SyndicationScheduleService} from 'services/syndication-schedule-service';
import {SyndicationTargetService} from 'services/syndication-target-service';
import {SAFE_TO_DELETE} from './constants';

export const PENDING_SAVE = 'Pending Save';

@autoinject()
export class PublishController {
  @computedFrom('targets', 'schedules', 'searchText')
  get remainingTargets() {
    const result = _.differenceWith(
      this.targets,
      this.schedules,
      (target, schedule) => target.id === schedule.syndication_target_id,
    );
    if (this.searchText.length) {
      return result.filter(target => target.description.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0);
    }
    return result;
  }

  @computedFrom('schedules')
  get countSelected() {
    return this.schedules ? this.schedules.filter(x => x.checkbox === true).length : 0;
  }

  public contentType: SyndicationScheduleContentType = SyndicationScheduleContentType.CHANNEL;
  public contentId: string = '';
  public externalId: string = '';

  public searchText: string = '';
  public selectedTargets: any[] = [];
  public target;
  public targetKey: string = '';
  public targetModel: any = {};
  public schedules: SyndicationSchedule[] = [];
  public targets: SyndicationTarget[] = [];
  public schedulePullInterval: any;
  public targetPullInterval: any;
  public isLoading: boolean = false;
  public contentIsDRM: boolean = false;
  public contentTitle: string = '';

  private intervalPaused = false;
  private TARGET_PULL_INTERVAL_MS = 10000;
  private SCHEDULE_PULL_INTERVAL_MS = 10000;

  constructor(
    public dialogService: DialogService,
    public notification: CToastsService,
    public syndicationScheduleService: SyndicationScheduleService,
    public syndicationTargetService: SyndicationTargetService,
    public session: SessionService,
    public betaMode: BetaMode,
  ) {}

  public async init(
    contentType: SyndicationScheduleContentType,
    contentId: string,
    contentIsDRM: boolean = false,
    contentTitle: string = '',
  ) {
    this.schedules = [];
    this.targets = [];
    this.selectedTargets = [];
    this.contentType = contentType;
    this.contentId = contentId;
    this.isLoading = true;
    // await this.loadData();
    await this.pollTargets();
    await this.pollSchedules();

    this.isLoading = false;
    this.contentIsDRM = contentIsDRM;
    this.contentTitle = contentTitle;
    this.searchText = '';
  }

  public loadData() {
    return Promise.all([
      this.syndicationScheduleService.getSyndicationSchedules(this.contentType, this.contentId),
      this.syndicationTargetService.getSyndicationTargets(),
    ]).then(() => {
      this.schedules = _.cloneDeep(this.syndicationScheduleService.syndicationSchedules);
      this.targets = _.cloneDeep(this.syndicationTargetService.syndicationTargets);
    });
  }

  public findMatch(item, target) {
    if (
      (item.is_new && item.syndication_target_id === target.syndication_target_id) ||
      (!item.is_new && item.id === target.id)
    ) {
      return true;
    }
    return false;
  }

  public trackSelectedTargets(target) {
    if (target.checkbox) {
      this.selectedTargets.push({
        id: target.id,
        is_new: target.is_new,
        status: target.status,
        syndication_target_id: target.syndication_target_id,
      });
    } else {
      _.remove(this.selectedTargets, item => this.findMatch(item, target));
    }
  }

  /**
   * Select/Deselect all meta items
   *
   * @param isSelected
   */
  public selectAllTargets(isSelected: boolean) {
    _.forEach(this.schedules, schedule => {
      schedule.checkbox = isSelected;
    });
  }

  public async testTarget(id: string) {
    try {
      const result = await this.syndicationTargetService.testTarget(id);
      if (result.test && result.test.failed && result.test.failed.length) {
        _.forEach(result.test.failed, x => {
          this.notification.error(x);
        });
      } else if (result.test && result.test.passed && result.test.passed.length) {
        _.forEach(result.test.passed, x => {
          this.notification.success(x);
        });
      } else {
        this.notification.error('Unable to test at this time');
      }
    } catch (e) {
      this.notification.error(e.message);
    }
    return false;
  }

  public async startSchedule(id: string) {
    try {
      await this.syndicationScheduleService.syndicationScheduleAction(id, syndicationScheduleActions.START);
      this.notification.info('Starting in progress');
      this.updateStatus(id);
      return true;
    } catch (e) {
      this.notification.error(e.message);
      return false;
    }
  }

  public async stopSchedule(id: string) {
    try {
      await this.syndicationScheduleService.syndicationScheduleAction(id, syndicationScheduleActions.STOP);
      this.notification.info('Stopping in progress');
      this.updateStatus(id);
      return true;
    } catch (e) {
      this.notification.error(e.message);
      return false;
    }
  }

  public async resetSchedule(id: string) {
    try {
      await this.syndicationScheduleService.syndicationScheduleAction(id, syndicationScheduleActions.RESET_STATUS);
      this.notification.info('Reset in progress');
      this.updateStatus(id);
      return true;
    } catch (e) {
      this.notification.error(e.message);
      return false;
    }
  }

  public async saveSchedule(model) {
    const data = new SyndicationSchedule();

    data.video_quality = model.video_quality;
    if (model.is_new) {
      data.syndication_target_id = model.syndication_target_id;
    } else {
      data.id = model.id;
      delete data.syndication_target_id;
    }

    if (model.canUseSCTE) {
      data.scte35_enabled = model.scte35_enabled ? 1 : 0;
    }

    data.region = model.region;
    data.region_auto_selected = data.region === '';
    data.target_platform_config = model.target_platform_config;
    data.source_url_params = model.source_url_params;
    data.url_params_active = model.url_params_active;

    delete data.err_msg;
    delete data.source_stream;
    delete data.status;
    delete data.type;
    delete data.is_new;

    data.content_id = this.contentId;
    data.content_type = this.contentType;
    try {
      const ans = await this.syndicationScheduleService.saveSyndicationSchedule(data);
      model.id = ans.id;
      model.status = ans.status;
      await this.pollSchedules();
      return true;
    } catch (e) {
      this.notification.error(e.message);
      return false;
    }
  }

  public async openTarget(target, is_new = false) {
    let rayDetail: SyndicationRayDetail;
    if (!is_new && target.is_staged !== true) {
      try {
        rayDetail = await this.syndicationScheduleService.getSyndicationScheduleRay(target.id);
      } catch (e) {
        // Don't do anything on a failed poll
      }
    } else {
      try {
        rayDetail = await this.syndicationScheduleService.getSyndicationScheduleRay(
          this.contentId,
          this.contentType,
          target.target_protocol,
        );
      } catch (e) {
        // Don't do anything on a failed poll
      }
    }
    // get the target information (needed for SCTE-35)
    const targetInfo = _.find(this.targets, {id: is_new ? target.id : target.syndication_target_id});
    const syndRegions: SyndicationRegion[] = await this.syndicationScheduleService.getSyndicationRegions(
      targetInfo.target_protocol,
    );
    this.target = target;
    const defaultScte35Type =
      targetInfo.platform === SyndicationPlatformType.WURL
        ? SyndicationScte35Types.CUE
        : SyndicationScte35Types.DATERANGE;
    this.targetModel = {
      is_new,
      rayDetail,
      audio_track: '',
      canUseSCTE: SYNDICATION_SCTE35_ALLOWED.includes(targetInfo.target_protocol),
      channelId_pristine: '',
      contentIsDRM: this.contentIsDRM,
      description: target.syndication_target_description,
      error: target.error,
      err_msg: target.err_msg,
      hls_uri_type:
        targetInfo.target_protocol === SyndicationTargetProtocolType.HLS_PUSH
          ? targetInfo.platform_config.hls_uri_type
          : SyndicationHlsUriType.NONE,
      is_modified: false,
      is_staged: target.is_staged,
      url_params_active: target.url_params_active ? target.url_params_active : false,
      url_params_active_pristine: target.url_params_active ? target.url_params_active : false,
      notification: this.notification,
      resetTargetModel: () => this.resetTargetModel(),
      region: '',
      regions: syndRegions,
      resetSchedule: id => this.resetSchedule(id),
      source_url_params: {},
      source_url_params_pristine: {},
      startSchedule: id => this.startSchedule(id),
      status: target.status,
      stopSchedule: id => this.stopSchedule(id),
      saveSchedule: schedule => this.saveSchedule(schedule),
      syndication_target_id: '',
      target_info: targetInfo,
      target_platform_config: {
        channel_id: '',
        channel_id_pristine: '',
        hls_master_url: '',
        integrated_description: '',
        integrated_description_pristine: '',
        integrated_title: this.contentTitle,
        integrated_title_pristine: this.contentTitle,
        integrated_yt_privacy: SyndicationYouTubeIntegratedPrivacy.PUBLIC,
        integrated_yt_privacy_pristine: SyndicationYouTubeIntegratedPrivacy.PUBLIC,
        integrated_yt_made_for_kids: false,
        integrated_yt_made_for_kids_pristine: false,
        rays: [],
        rays_pristine: [],
        scte35_type: defaultScte35Type,
        scte35_type_pristine: defaultScte35Type,
        srt_latency: 500,
        srt_latency_pristine: 500,
        fast_channel_id: '',
        fast_channel_id_pristine: '',
      },
      target_protocol: targetInfo.target_protocol,
      testTarget: id => this.testTarget(id),
      video_quality: target.quality,
      video_quality_pristine: '',
      session: this.session,
    };

    let defaultRays = [];
    // eslint-disable-next-line no-prototype-builtins
    if (_.isObject(rayDetail) && rayDetail.hasOwnProperty('all') && rayDetail.all.length) {
      defaultRays = rayDetail.all.map(ray => ray.ray_name);
    }

    // for edit
    if (is_new) {
      this.targetModel.syndication_target_id = target.id;
      this.targetModel.video_quality = SyndicationScheduleVideoQualityType.HIGH;
      this.targetModel.syndication_target_description = target.description;
      this.targetModel.status = PENDING_SAVE;
      this.targetModel.scte35_enabled = targetInfo.platform === SyndicationPlatformType.WURL;
      this.targetModel.scte35_enabled_pristine = targetInfo.platform === SyndicationPlatformType.WURL;
      this.targetModel.region_pristine = '';
      this.targetModel.target_platform_config.rays = _.cloneDeep(defaultRays);
      this.targetModel.target_platform_config.rays_pristine = _.cloneDeep(defaultRays);
      this.targetModel.source_url_params_pristine = {};
      this.targetModel.url_params_active = target.url_params_active !== undefined ? target.url_params_active : false;
      this.targetModel.url_params_active_pristine =
        target.url_params_active !== undefined ? target.url_params_active : false;
    } else {
      this.targetModel.id = target.id;
      this.targetModel.is_new = target.is_new;
      this.targetModel.status = target.status;
      this.targetModel.syndication_target_description = target.syndication_target_description;
      this.targetModel.syndication_target_id = target.syndication_target_id;
      this.targetModel.video_quality = target.video_quality;
      this.targetModel.video_quality_pristine = target.video_quality;
      this.targetModel.description = target.syndication_target_description;
      this.targetModel.scte35_enabled = target.scte35_enabled !== undefined ? !!target.scte35_enabled : false;
      this.targetModel.scte35_enabled_pristine = target.scte35_enabled !== undefined ? !!target.scte35_enabled : false;
      // eslint-disable-next-line no-multi-assign
      this.targetModel.region = this.targetModel.region_pristine = target.region !== undefined ? target.region : '';
      if (target.region_auto_selected !== undefined) {
        this.targetModel.region_auto_selected = target.region_auto_selected;
      } else {
        this.targetModel.region_auto_selected = this.targetModel.region === '';
      }
      this.targetModel.url_params_active = target.url_params_active !== undefined ? target.url_params_active : false;
      this.targetModel.url_params_active_pristine =
        target.url_params_active !== undefined ? target.url_params_active : false;
      this.targetModel.source_url_params =
        target.source_url_params !== undefined ? _.cloneDeep(target.source_url_params) : {};
      this.targetModel.source_url_params_pristine =
        target.source_url_params !== undefined ? _.cloneDeep(target.source_url_params) : {};
      // this.targetModel.region_pristine = target.region !== undefined ? target.region : '';
      if (this.target.target_platform_config.channel_id) {
        this.targetModel.target_platform_config.channel_id = this.target.target_platform_config.channel_id;
        // Had to add this because the formatter changes it back to this if I put it on a separate line
        // tslint:disable-next-line:max-line-length
        this.targetModel.target_platform_config.channel_id_pristine = this.target.target_platform_config.channel_id;
      }
      if (this.target.target_platform_config.hls_master_url) {
        // tslint:disable-next-line:max-line-length
        this.targetModel.target_platform_config.hls_master_url = this.target.target_platform_config.hls_master_url;
      }
      if (this.target.target_platform_config.rtmp_pull_url) {
        // tslint:disable-next-line:max-line-length
        this.targetModel.target_platform_config.rtmp_pull_url = this.target.target_platform_config.rtmp_pull_url;
      } else {
        this.targetModel.target_platform_config.rtmp_pull_url = 'Allocating…';
      }
      if (
        // eslint-disable-next-line no-prototype-builtins
        this.target.target_platform_config.hasOwnProperty('rays') &&
        this.target.target_platform_config.rays.length
      ) {
        this.targetModel.target_platform_config.rays = _.cloneDeep(this.target.target_platform_config.rays);
        // tslint:disable-next-line:max-line-length
        this.targetModel.target_platform_config.rays_pristine = _.cloneDeep(this.target.target_platform_config.rays);
      } else {
        this.targetModel.target_platform_config.rays = _.cloneDeep(defaultRays);
        this.targetModel.target_platform_config.rays_pristine = _.cloneDeep(defaultRays);
      }

      if (this.target.target_platform_config.integrated_title) {
        // tslint:disable-next-line:max-line-length
        this.targetModel.target_platform_config.integrated_title = this.target.target_platform_config.integrated_title;
      }
      if (this.target.target_platform_config.integrated_description) {
        // tslint:disable-next-line:max-line-length
        this.targetModel.target_platform_config.integrated_description =
          this.target.target_platform_config.integrated_description;
      }
      if (this.target.target_platform_config.integrated_yt_privacy) {
        // tslint:disable-next-line:max-line-length
        this.targetModel.target_platform_config.integrated_yt_privacy =
          this.target.target_platform_config.integrated_yt_privacy;
      }
      if (this.target.target_platform_config.integrated_yt_made_for_kids) {
        // tslint:disable-next-line:max-line-length
        this.targetModel.target_platform_config.integrated_yt_made_for_kids =
          this.target.target_platform_config.integrated_yt_made_for_kids;
      }
      if (this.target.target_platform_config.scte35_type) {
        // tslint:disable-next-line:max-line-length
        this.targetModel.target_platform_config.scte35_type = this.target.target_platform_config.scte35_type;
      }
      if (this.target.target_platform_config.srt_latency) {
        // tslint:disable-next-line:max-line-length
        this.targetModel.target_platform_config.srt_latency = this.target.target_platform_config.srt_latency;
      }
      if (this.target.target_platform_config.fast_channel_id) {
        // tslint:disable-next-line:max-line-length
        this.targetModel.target_platform_config.fast_channel_id = this.target.target_platform_config.fast_channel_id;
      }
    }

    // tslint:disable-next-line:max-line-length
    this.targetModel.target_platform_config.integrated_title_pristine =
      this.targetModel.target_platform_config.integrated_title;
    // tslint:disable-next-line:max-line-length
    this.targetModel.target_platform_config.integrated_description_pristine =
      this.targetModel.target_platform_config.integrated_description;
    // tslint:disable-next-line:max-line-length
    this.targetModel.target_platform_config.integrated_yt_privacy_pristine =
      this.targetModel.target_platform_config.integrated_yt_privacy;
    // tslint:disable-next-line:max-line-length
    this.targetModel.target_platform_config.integrated_yt_made_for_kids_pristine =
      this.targetModel.target_platform_config.integrated_yt_made_for_kids;
    // tslint:disable-next-line:max-line-length
    this.targetModel.target_platform_config.scte35_type_pristine = this.targetModel.target_platform_config.scte35_type;
    this.targetModel.target_platform_config.srt_latency_pristine = this.targetModel.target_platform_config.srt_latency;
    this.targetModel.target_platform_config.fast_channel_id_pristine =
      this.targetModel.target_platform_config.fast_channel_id;

    // if (is_new) {
    //     this.targetModel.syndication_target_id = target.id;
    //     this.targetModel.video_quality = SyndicationScheduleVideoQualityType.HIGH;
    // }

    this.dialogService.open({
      model: {
        bodyViewModel: PLATFORM.moduleName('shared/syndication/modals/publish-target-body'),
        footerEnable: true,
        footerText: 'footer',
        footerViewModel: PLATFORM.moduleName('shared/syndication/modals/publish-target-footer'),
        sharedModel: this.targetModel,
        size: 'medium',
        title: target.syndication_target_description
          ? `${target.syndication_target_description}`
          : `${target.description}`,
      },
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
    });
  }

  public async pollSchedules() {
    if (!this.intervalPaused) {
      try {
        await this.syndicationScheduleService.getSyndicationSchedules(this.contentType, this.contentId);
        const updatedSchedules = _.cloneDeep(this.syndicationScheduleService.syndicationSchedules);

        const allUpdated = _.map(updatedSchedules, schedule => {
          const scheduleTableItem = _.find(this.schedules, {id: schedule.id});
          const updatedSchedule = schedule;

          if (scheduleTableItem) {
            updatedSchedule.checkbox = scheduleTableItem.checkbox;
          }
          const targetInfo = _.find(this.targets, {id: updatedSchedule.syndication_target_id});
          if (targetInfo) {
            updatedSchedule.platform = targetInfo.platform;
            updatedSchedule.target_protocol = targetInfo.target_protocol;
          }

          return updatedSchedule;
        });
        this.schedules = allUpdated;
      } catch (e) {
        // Don't do anything on a failed poll
      }
    }

    this.schedulePullInterval = setTimeout(() => {
      this.pollSchedules();
    }, this.SCHEDULE_PULL_INTERVAL_MS);
  }

  public async pollTargets() {
    if (!this.intervalPaused) {
      try {
        await this.syndicationTargetService.getSyndicationTargets();

        this.targets = _.cloneDeep(this.syndicationTargetService.syndicationTargets);
      } catch (e) {
        // Don't do anything on a failed poll
      }
    }
    this.targetPullInterval = setTimeout(() => {
      this.pollTargets();
    }, this.TARGET_PULL_INTERVAL_MS);
  }

  public resetTargetModel() {
    this.targetModel.is_new = null;
    this.targetModel.video_quality_pristine = this.targetModel.video_quality;
    this.targetModel.scte35_enabled_pristine = this.targetModel.scte35_enabled;
    this.targetModel.region_pristine = this.targetModel.region;
    this.targetModel.url_params_active_pristine = this.targetModel.url_params_active;
    this.targetModel.source_url_params_pristine = this.targetModel.source_url_params;
    this.targetModel.target_platform_config.channel_id_pristine = this.targetModel.target_platform_config.channel_id;
    this.targetModel.target_platform_config.rays_pristine = _.cloneDeep(this.targetModel.target_platform_config.rays);
    this.targetModel.target_platform_config.integrated_title_pristine =
      this.targetModel.target_platform_config.integrated_title;
    this.targetModel.target_platform_config.integrated_description_pristine =
      this.targetModel.target_platform_config.integrated_description;
    this.targetModel.target_platform_config.integrated_yt_privacy_pristine =
      this.targetModel.target_platform_config.integrated_yt_privacy;
    this.targetModel.target_platform_config.integrated_yt_made_for_kids_pristine =
      this.targetModel.target_platform_config.integrated_yt_made_for_kids;
    this.targetModel.target_platform_config.scte35_type_pristine = this.targetModel.target_platform_config.scte35_type;
    this.targetModel.target_platform_config.srt_latency_pristine = this.targetModel.target_platform_config.srt_latency;
    this.targetModel.target_platform_config.fast_channel_id_pristine =
      this.targetModel.target_platform_config.fast_channel_id;
  }

  public async deleteSelectedTargets() {
    this.selectedTargets = this.schedules.filter(x => x.checkbox);

    const deleted = await this.deleteTargets(this.selectedTargets);
    if (deleted) {
      await this.pollSchedules();
      this.notification.success('Targets removed successfully.');
    }
    _.forEach(this.schedules, schedule => {
      schedule.checkbox = false;
    });
    this.selectedTargets = [];
  }

  public async deleteTargets(targets: any[]) {
    if (_.isEmpty(targets)) {
      return false;
    }

    const undeletedTargets = await Promise.filter(targets, async schedule => {
      if (schedule.status === 'scheduled') {
        this.notification.warning(`
                Unable to delete a scheduled job. Please stop it first and try again.`);
        return true;
      }
      if (!SAFE_TO_DELETE.includes(schedule.status)) {
        this.notification.warning(`
                Unable to delete ${schedule.status} schedule.  Schedule must be fully stopped before delete.`);
        return true;
      }
      if (schedule.platform === SyndicationPlatformType.WURL && !this.session.hasFastAccess) {
        this.notification.warning('This job cannot be deleted, please reach out to Support.');
        return true;
      }
      if (schedule.id) {
        try {
          await this.syndicationScheduleService.deleteSchedule(schedule.id);
          return false;
        } catch (e) {
          this.notification.error(e.message);
          return true;
        }
      }
      return false;
    });
    return targets.length > undeletedTargets.length;
  }

  public deactivate() {
    if (this.schedulePullInterval) {
      clearTimeout(this.schedulePullInterval);
      this.schedulePullInterval = undefined;
    }
    if (this.targetPullInterval) {
      clearTimeout(this.targetPullInterval);
      this.targetPullInterval = undefined;
    }
  }

  public updateStatus(id: string) {
    const updatedSchedule = _.find(this.syndicationScheduleService.syndicationSchedules, {id});
    const currentSchedule = _.find(this.schedules, {id});
    if (currentSchedule !== undefined && updatedSchedule !== undefined) {
      currentSchedule.status = updatedSchedule.status;
    }
  }

  public pauseInterval() {
    this.intervalPaused = true;
  }

  public resumeInterval() {
    this.intervalPaused = false;
  }
}
