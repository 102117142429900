import {autoinject, computedFrom, LogManager} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {CToastsService} from '@bindable-ui/bindable';
import {HyperionPolling} from 'services/hyperion-polling';
import {LynkDialog} from '@uplynk/lynk-design';
import {SlicerService} from '../../services/csl-slicer-service';
import {Slicer, SlicerListResponse} from '../../models/csl-slicers';
import {SessionService} from '../../../../../../services/session';

const logger = LogManager.getLogger('Live Cloud Slicer');

@autoinject
export class CSLSlicerList {
  public slicer: Slicer;
  public slicers: SlicerListResponse;
  public createSlicerDialog: LynkDialog;

  public isSaving: boolean = false;
  public isLoading: boolean = false;
  public isDeleting: boolean = false;

  protected hyperionPolling: HyperionPolling;
  protected hyperionPollingInterval = 5000;

  public slicerActionState: string | undefined = undefined;

  public protocols: any;
  public regions: any;

  public slicerId: string = '';
  public searchText: string = '';
  public createErrorMessage: string = '';
  public createErrorState: boolean = false;

  constructor(
    protected router: Router,
    protected service: SlicerService,
    private notification: CToastsService,
    protected sessionService: SessionService,
  ) {
    this.hyperionPolling = new HyperionPolling({
      callbackFn: this.mergeTableRows.bind(this),
      // includeDeleted: this.config.state.canViewDeleted,
      ms: this.hyperionPollingInterval,
      promiseFn: this.requestReadInterval.bind(this),
      useAfter: true,
    });
  }

  @computedFrom('slicers.items')
  public get allSelected() {
    const selected = this.slicers && this.slicers.items.filter(x => x.selected === true).length;
    return selected === (this.slicers ? this.slicers.items.length : 0);
  }

  @computedFrom('searchText', 'slicers.items')
  public get slicersFiltered() {
    return this.slicers && this.slicers.items.filter(slicer => slicer.slicer_id.includes(this.searchText));
  }

  @computedFrom('slicers.items')
  public get countSelected() {
    return this.slicers ? this.slicers.items.filter(x => x.selected === true).length : 0;
  }

  public async attached() {
    this.isLoading = true;

    try {
      this.slicers = await this.service.getSlicers();
    } catch (error) {
      this.notification.error(`Failed to get slicers: ${error}`);
    }

    const [
      protocols,
      regions,
    ] = await Promise.all([
      this.service.getProtocols(),
      this.service.getRegions(),
    ]);

    this.protocols = protocols;
    this.regions = regions.map(r => ({name: r.name, code: r.code}));

    this.isLoading = false;

    this.hyperionPolling.start();
  }

  deactivate() {
    this.hyperionPolling.stop();
  }

  public mergeTableRows(rows: SlicerListResponse) {
    this.slicers.items = rows.items
      // .filter(slicer => slicer.loading === false)
      .map(slicer => {
        const s = this.slicers.items.find(i => i.slicer_id === slicer.slicer_id);
        slicer.selected = s ? s.selected : false;
        return s && s.loading === true ? s : slicer;
      });

    this.slicers.total_items = rows.total_items;
    this.slicers = {...this.slicers};
  }

  public requestReadInterval() {
    return Promise.resolve(this.service.getSlicers());
  }

  public rowClick(event, slicer: Slicer) {
    event.stopPropagation();
    if (event.target.nodeName === 'LYNK-TD') {
      this.router.navigate(`/slicers/cloud-slicers/${slicer.slicer_id}`);
    }
  }

  public onSlicerIdChange(_event, value) {
    const invalid = value.trim().match(/\W/);
    this.createErrorState = !!invalid;
    this.createErrorMessage = this.createErrorState
      ? 'Slicer ID should not contain special characters or whitespaces'
      : '';
  }

  public async onClickAction(_event, slicer: Slicer, action: string) {
    let res: Slicer | undefined;

    try {
      slicer.loading = true;
      this.slicerActionState = action;

      switch (action) {
        case 'start':
          res = await this.service.startSlicer(slicer);
          break;
        case 'stop':
          res = await this.service.stopSlicer(slicer);
          break;
        case 'restart':
          await this.service.restartSlicer(slicer);
          break;
        default:
          break;
      }

      if (res) {
        this.slicer = res;
      }

      // await this.service.getSlicers();
    } catch (error) {
      this.notification.error(`Failed to ${action} slicer: ${error}`);
    } finally {
      slicer.loading = false;
      this.slicerActionState = undefined;
    }
  }

  public async deleteSlicers() {
    const slicers = this.slicers.items.filter(x => x.selected);
    const promises = slicers.map(slicer => this.service.deleteSlicer(slicer.slicer_id));

    this.isDeleting = true;
    try {
      await Promise.all(promises);
      this.service.getSlicers();
    } catch (error) {
      this.notification.error(`Failed to delete slicer: ${error}`);
    }
    this.isDeleting = false;
  }

  public onChecked(event, slicer: Slicer) {
    event.stopPropagation();
    const {checked} = event.target;
    slicer.selected = checked;
    this.slicers.items = [...this.slicers.items]; // trigger for slicers change
  }

  public onCheckedAll(event) {
    const {checked} = event.target;
    this.slicers.items.forEach(x => {
      x.selected = checked;
    });
    this.slicers.items = [...this.slicers.items]; // trigger for slicers change
  }

  public formCancel() {
    this.createSlicerDialog.hide();
  }

  public onCreateSlicerShow() {
    this.createErrorState = false;
    this.createErrorMessage = '';
    this.slicer = new Slicer();
    this.createSlicerDialog.show();
  }

  public async createSlicer(edit: boolean = false) {
    if (this.isSaving) {
      return;
    }

    if (this.slicer.slicer_id.trim() === '') {
      this.createErrorState = true;
      this.createErrorMessage = 'Slicer ID is required';
      this.slicer.slicer_id = '';
      return;
    }

    try {
      this.isSaving = true;
      const res: any = await this.service.createSlicer(this.slicer);
      await this.createSlicerDialog.hide();
      this.notification.success(`Slicer saved successfully`);
      if (edit) {
        this.router.navigateToRoute('hostedSlicerSingle', {id: res.slicer_id});
      }
    } catch (e) {
      logger.error(e.message);
      this.notification.error(`Failed to create slicer: ${e}`);
    } finally {
      this.isSaving = false;
    }
  }
}
