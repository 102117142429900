import {bindable, computedFrom} from 'aurelia-framework';
import {PublishController} from '../publish-controller';
import {SyndicationSchedule} from '../../../services/models/syndication';

export class PublishTable {
  @bindable public publishController: PublishController;

  @computedFrom('publishController.schedules')
  public get allSelected() {
    const selected =
      this.publishController.schedules && this.publishController.schedules.filter(x => x.checkbox === true).length;
    return selected === (this.publishController.schedules ? this.publishController.schedules.length : 0);
  }

  public onChecked(event, schedule: SyndicationSchedule) {
    event.stopPropagation();
    const {checked} = event.target;
    schedule.checkbox = checked;
    this.publishController.schedules = [...this.publishController.schedules]; // trigger for schedules change
  }

  public onCheckedAll(event) {
    const {checked} = event.target;
    _.forEach(this.publishController.schedules, schedule => {
      schedule.checkbox = checked;
    });
    this.publishController.schedules = [...this.publishController.schedules]; // trigger for schedules change
  }

  public rowClick(event, schedule: SyndicationSchedule) {
    event.stopPropagation();
    this.publishController.openTarget(schedule);
  }
}
