import {IToggleGroup} from 'components/toggle-group/c-toggle-group';

export interface IScopeOption {
  value: string;
  text: string;
}

export function asOptions(groups: IToggleGroup[]): IScopeOption[] {
  return groups.reduce((col, g) => col.concat(g.options.map(o => ({text: `${g.text} ${o.text}`, value: o.value}))), []);
}

export function filterOptions(options: IScopeOption[], scp: string[] = null): IScopeOption[] {
  return scp.reduce((_options, scope) => {
    if (/:.*$/.test(scope)) {
      _options.push(options.find(o => o.value === scope));
      return _options;
    }

    return _options.concat(options.filter(o => o.value.includes(scope)));
  }, []);
}

export const API_SCOPE_GROUPS: IToggleGroup[] = [
  {
    text: 'Ads',
    value: 'video.services.ads',
    options: [
      {
        value: 'video.services.ads:read',
        text: 'Read',
      },
      {
        syncValue: 'video.services.ads:read',
        value: 'video.services.ads:write',
        text: 'Write',
      },
    ],
  },
  {
    text: 'Assets',
    value: 'video.services.assets',
    options: [
      {
        value: 'video.services.assets:read',
        text: 'Read',
      },
      {
        syncValue: 'video.services.assets:read',
        value: 'video.services.assets:write',
        text: 'Write',
      },
    ],
  },
  {
    text: 'Channels',
    value: 'video.services.channels',
    options: [
      {
        value: 'video.services.channels:read',
        text: 'Read',
      },
      {
        syncValue: 'video.services.channels:read',
        value: 'video.services.channels:write',
        text: 'Write',
      },
      {
        value: 'video.services.channels.blackout:read',
        text: 'Blackouts - Read',
      },
      {
        syncValue: 'video.services.channels.blackout:read',
        value: 'video.services.channels.blackout:write',
        text: 'Blackouts - Write',
      },
      {
        value: 'video.services.channels.schedule:read',
        text: 'Schedules - Read',
      },
      {
        syncValue: 'video.services.channels.schedule:read',
        value: 'video.services.channels.schedule:write',
        text: 'Schedules - Write',
      },
    ],
  },
  {
    text: 'Clipping',
    value: 'video.services.clipping',
    options: [
      {
        value: 'video.services.clipping:read',
        text: 'Read',
      },
      {
        syncValue: 'video.services.clipping:read',
        value: 'video.services.clipping:write',
        text: 'Write',
      },
      {
        value: 'video.services.clipping.drafts:read',
        text: 'Drafts - Read',
      },
      {
        syncValue: 'video.services.clipping.drafts:read',
        value: 'video.services.clipping.drafts:write',
        text: 'Drafts - Write',
      },
    ],
  },
  {
    text: 'Failover Group',
    value: 'video.services.failover-groups',
    options: [
      {
        value: 'video.services.failover-groups:read',
        text: 'Read',
      },
      {
        syncValue: 'video.services.failover-groups:read',
        value: 'video.services.failover-groups:write',
        text: 'Write',
      },
    ],
  },
  {
    text: 'Ingest',
    value: 'video.services.ingest',
    options: [
      {
        value: 'video.services.ingest.external.vod:read',
        text: 'External VOD Read',
      },
      {
        syncValue: 'video.services.ingest.external.vod:read',
        value: 'video.services.ingest.external.vod:write',
        text: 'External VOD Write',
      },
      {
        value: 'video.services.ingest.external.live:read',
        text: 'External Live Read',
      },
      {
        syncValue: 'video.services.ingest.external.live:read',
        value: 'video.services.ingest.external.live:write',
        text: 'External Live Write ',
      },
    ],
  },
  {
    text: 'Libraries',
    value: 'video.services.libraries',
    options: [
      {
        value: 'video.services.libraries:read',
        text: 'Read',
      },
      {
        syncValue: 'video.services.libraries:read',
        value: 'video.services.libraries:write',
        text: 'Write',
      },
      {
        value: 'video.services.libraries.users:admin',
        text: 'User(s) Add/Remove',
      },
    ],
  },
  {
    text: 'Linear Playlists',
    value: 'video.services.linear-playlists',
    options: [
      {
        value: 'video.services.linear-playlists:read',
        text: 'Read',
      },
      {
        syncValue: 'video.services.linear-playlists:read',
        value: 'video.services.linear-playlists:write',
        text: 'Write',
      },
      {
        value: 'video.services.linear-playlists:smart-start',
        text: 'Smart Start',
      },
    ],
  },
  {
    text: 'Live Events 2',
    value: 'video.services.le2',
    options: [
      {
        value: 'video.services.le2:read',
        text: 'Read',
      },
      {
        syncValue: 'video.services.le2:read',
        value: 'video.services.le2:write',
        text: 'Write',
      },
      {
        value: 'video.services.le2:ops',
        text: 'Ops',
      },
    ],
  },
  {
    text: 'Owners',
    value: 'video.services.owners',
    options: [
      {
        value: 'video.services.owners.integration-keys:admin',
        text: 'Integration Keys',
      },
      {
        value: 'video.services.owners.sub-owners:admin',
        text: 'Sub Owners',
      },
    ],
  },
  {
    text: 'Slicers',
    value: 'video.services.slicer',
    options: [
      {
        value: 'video.services.slicer:read',
        text: 'Read',
      },
      {
        syncValue: 'video.services.slicer:read',
        value: 'video.services.slicer:write',
        text: 'Write',
      },
      {
        value: 'video.services.slicer.live.config:read',
        text: 'Live Slicer Config - Read',
      },
      {
        syncValue: 'video.services.slicer.live.config:read',
        value: 'video.services.slicer.live.config:write',
        text: 'Live Slicer Config - Write',
      },
      {
        value: 'video.services.slicer.cloudslicer.live:read',
        text: 'Cloud Slicers (LIVE)- Read',
      },
      {
        syncValue: 'video.services.slicer.cloudslicer.live:read',
        value: 'video.services.slicer.cloudslicer.live:write',
        text: 'Cloud Slicers (LIVE)- Write',
      },
      {
        value: 'video.services.slicer.cloudslicer.vod:read',
        text: 'Cloud Slicers VOD - Read',
      },
      {
        syncValue: 'video.services.slicer.cloudslicer.vod:read',
        value: 'video.services.slicer.cloudslicer.vod:write',
        text: 'Cloud Slicers VOD - Write',
      },
      {
        value: 'video.services.slicer.cloudslicer.vod.job:read',
        text: 'Cloud Slicers VOD Jobs - Read',
      },
      {
        syncValue: 'video.services.slicer.cloudslicer.vod.job:read',
        value: 'video.services.slicer.cloudslicer.vod.job:write',
        text: 'Cloud Slicers VOD Jobs - Write',
      },
    ],
  },
  {
    text: 'Tokens',
    value: 'video.services.tokens',
    options: [
      {
        value: 'video.services.token:read',
        text: 'Tokens - Read',
      },
    ],
  },
  {
    text: 'Workspace',
    value: 'video.services.workspace',
    options: [
      {
        value: 'video.services.workspace.api-key:read',
        text: 'API Keys - Read',
      },
      {
        value: 'video.services.workspace.api-key:update',
        text: 'API Keys - Update',
      },
      {
        value: 'video.services.workspace.api-key:admin',
        text: 'API Keys - Admin',
      },
      {
        value: 'video.services.workspace.scopes:read',
        text: 'Owner API Scopes',
      },
    ],
  },
];

export const IDS_SCOPE_GROUPS: IToggleGroup[] = [
  {
    text: 'IDS Ops',
    value: 'video.identity',
    options: [
      {
        value: 'video.identity.integrations:read',
        text: 'Integrations - Read',
      },
      {
        syncValue: 'video.identity.integrations:read',
        value: 'video.identity.integrations:write',
        text: 'Integrations - Write',
      },
      {
        value: 'video.identity.users:read',
        text: 'Users - Read',
      },
      {
        syncValue: 'video.identity.users:read',
        value: 'video.identity.users:write',
        text: 'Users - Write',
      },
      {
        value: 'video.identity.tenants:read',
        text: 'Tenants - Read',
      },
      {
        syncValue: 'video.identity.tenants:read',
        value: 'video.identity.tenants:write',
        text: 'Tenants - Write',
      },
    ],
  },
];

export const OPS_SCOPE_GROUPS: IToggleGroup[] = [
  {
    text: 'Acuity Ops',
    value: 'video.ops',
    options: [
      {
        value: 'video.ops.acuity:admin',
        text: 'Admin',
      },
    ],
  },
];
