import {autoinject, computedFrom} from 'aurelia-framework';
import {DialogService} from 'aurelia-dialog';
import {PLATFORM} from 'aurelia-pal';
import {NavigationInstruction, RouteConfig, Router, RouterConfiguration} from 'aurelia-router';
import {CToastsService} from '@bindable-ui/bindable';
import {classToPlain, plainToClass} from 'class-transformer';
import {LynkDialog} from '@uplynk/lynk-design';
import {SlicerService} from '../../services/csl-slicer-service';
import {Plugin, Region, Slicer, SlicerStreamStats, SlicerVersion} from '../../models/csl-slicers';
import {HyperionPolling} from '../../../../../../services/hyperion-polling';
import {ProfilesService} from '../../services/profiles-service';
import {ProfilesResponseItem, ProfilesResponseItems} from '../../models/profiles';

const dirtyModel = {
  header: 'Are you sure you want to leave?',
  question: 'There are unsaved changes. If you leave before saving, your changes will be lost.',
  yes: 'Yes, Discard My Changes',
  hideNo: true,
  cancel: 'Cancel',
  footerClass: 'btn-split',
};

@autoinject()
export class HostedSlicersSingle {
  public slicerID: string = '';
  public slicer: Slicer;
  public slicerCopy: Slicer;
  public slicerClone: Slicer;
  public protocols = [];
  public regions: Region[];
  public versions: SlicerVersion[];
  public plugins: Plugin[] | undefined = undefined;

  public profile: ProfilesResponseItem = undefined;
  public profiles: ProfilesResponseItems;

  public showSidebar: boolean = false;
  public routeName: string;

  public hyperionPolling: HyperionPolling;
  public hyperionPollingInterval = 5000;

  public slicerActionState: string | undefined = undefined;
  public cloneSlicerDialog: LynkDialog;

  public sessionStreamStats: SlicerStreamStats;
  public recentStreamStats: SlicerStreamStats;
  public streamStatus: string;
  public statusColor: string;

  @computedFrom(
    'slicer.loading',
    'slicer.slicer_version',
    'slicer.description',
    'slicer.configuration.length',
    'slicer.encoding_profile_id',
    'slicer.plugin.id',
    'slicer.plugin.version',
    'slicer.slicer_id',
  )
  get isDisabled() {
    return !this.isDirty() || this.slicer.loading;
  }

  public routes = [
    {
      moduleId: PLATFORM.moduleName('./general'),
      name: 'general',
      nav: true,
      route: [
        '/general',
        '',
      ],
      title: 'General',
    },
    {
      moduleId: PLATFORM.moduleName('./advanced'),
      name: 'advanced',
      nav: true,
      route: '/advanced',
      title: 'Advanced Config',
    },
    {
      moduleId: PLATFORM.moduleName('./plugins'),
      name: 'plugins',
      nav: true,
      route: '/plugins',
      title: 'Plugins',
    },
    {
      moduleId: PLATFORM.moduleName('./logs'),
      name: 'logs',
      nav: true,
      route: '/logs',
      title: 'Logs',
    },
  ];

  public isLoading: boolean;
  public id: string;

  constructor(
    public router: Router,
    public service: SlicerService,
    public profilesService: ProfilesService,
    public notification: CToastsService,
    public dialogService: DialogService,
  ) {
    this.slicer = new Slicer();

    this.hyperionPolling = new HyperionPolling({
      callbackFn: slicer => {
        this.slicerCopy = _.cloneDeep(slicer);
        this.loadSlicerMonitor();
      },
      // includeDeleted: this.config.state.canViewDeleted,
      ms: this.hyperionPollingInterval,
      promiseFn: this.requestReadInterval.bind(this),
      useAfter: true,
    });
  }

  public requestReadInterval() {
    return Promise.resolve(this.service.getSlicer(this.slicerID));
  }

  public activate(params: any, routeConfig: RouteConfig) {
    this.routeName = routeConfig.name || '';
    this.slicerID = params.id;
    this.load();
  }

  public async load() {
    this.hyperionPolling.stop();
    this.isLoading = true;
    this.protocols = await this.service.getProtocols();
    this.regions = await this.service.getRegions();

    try {
      this.slicerCopy = await this.service.getSlicer(this.slicerID);
      this.profile = await this.profilesService.getById(this.slicerCopy.encoding_profile_id);
      this.versions = _.orderBy(await this.service.getVersions(this.slicerCopy.region), 'id', 'desc');
      this.slicer = _.cloneDeep(this.slicerCopy);
      this.slicerClone = _.cloneDeep(this.slicerCopy);
      this.slicerClone.slicer_id = '';

      this.loadSlicerMonitor();
    } catch (error) {
      this.notification.error(`Failed to load slicer: ${error}`);
    }

    this.isLoading = false;
    this.hyperionPolling.start();
  }

  public configureRouter(config: RouterConfiguration, router: Router) {
    this.router = router;
    config.map(this.routes);
  }

  public switchTab(routeName: string) {
    this.router.navigateToRoute(routeName);
  }

  public goToList() {
    this.router.navigate(`/slicers/cloud-slicers/`);
  }

  public toggleSidebar() {
    this.showSidebar = !this.showSidebar;
  }

  public async onSave(target_state = null) {
    const {slicer, slicerCopy} = this;
    try {
      const redirect = slicer.id !== slicer.slicer_id;
      slicer.loading = true;
      slicer.target_state = target_state || slicerCopy.target_state;
      this.slicer = await this.service.updateSlicer(slicer);

      slicer.loading = false;
      this.notification.success(`Slicer saved successfully`);
      this.slicerCopy = _.cloneDeep(this.slicer);
      this.slicerClone = _.cloneDeep(this.slicer);
      this.slicerClone.slicer_id = '';
      if (redirect) {
        this.router.navigate(`/slicers/cloud-slicers/${slicer.slicer_id}`);
      }
    } catch (error) {
      this.notification.error(`Failed to save slicer: ${error}`);
      slicer.loading = false;
    }
  }

  public async onAction(_event, action) {
    let res: Slicer | undefined;
    const {slicerCopy, slicer} = this;

    try {
      slicer.loading = true;
      this.slicerActionState = action;
      switch (action) {
        case 'start':
          res = await this.service.startSlicer(slicerCopy);
          break;
        case 'stop':
          res = await this.service.stopSlicer(slicerCopy);
          break;
        case 'restart':
          await this.service.restartSlicer(slicerCopy);
          break;
        default:
          break;
      }

      if (res && action !== 'stop') {
        this.slicerCopy = res;
        this.slicer = _.cloneDeep(res);
        this.slicerClone = _.cloneDeep(res);
        this.slicerClone.slicer_id = '';
      }
      // this.notification.success(`Successed to ${action} slicer`);
    } catch (error) {
      this.notification.error(`Failed to ${action} slicer: ${error}`);
    } finally {
      this.slicerActionState = undefined;
      slicer.loading = false;
    }
  }

  public async deleteSlicer() {
    try {
      this.slicer.loading = true;
      await this.service.deleteSlicer(this.slicer.slicer_id);
      this.slicer.loading = false;
      this.notification.success(`Slicer deleted successfully`);
      this.goToList();
    } catch (error) {
      this.notification.error(`Failed to delete slicer: ${error}`);
      this.slicer.loading = false;
    }
  }

  public loadSlicerMonitor() {
    if (!this.slicerCopy.status.stream_monitor || !this.slicerCopy.status.isStreaming) {
      this.sessionStreamStats = null;
      this.recentStreamStats = null;
      this.streamStatus = 'No Stream';
      this.statusColor = 'neutral';
      return;
    }

    this.sessionStreamStats = plainToClass(SlicerStreamStats, this.slicerCopy.status.stream_monitor.session);
    this.recentStreamStats = plainToClass(SlicerStreamStats, this.slicerCopy.status.stream_monitor.recent);
    this.statusColor = this.sessionStreamStats.statusColor;

    switch (this.statusColor) {
      case 'danger':
        this.streamStatus = 'Unhealthy';
        break;
      case 'warning':
        this.streamStatus = 'Sketch';
        break;
      case 'success':
        this.streamStatus = 'Healthy';
        break;
      default:
        this.streamStatus = 'No Stream';
        break;
    }
  }

  public isDirty() {
    const s1: any = classToPlain(this.slicer);
    const s2: any = classToPlain(this.slicerCopy);

    if (!s1 || !s2) {
      return false;
    }

    // remove the target state, not need to compare
    s1.target_state = undefined;
    s2.target_state = undefined;

    return !_.isEqual(s1, s2);
  }

  public canDeactivate(instruction: NavigationInstruction) {
    if (instruction.getWildcardPath().includes(this.slicer.slicer_id) || !this.isDirty()) {
      return true;
    }

    return this.dialogService
      .open({
        viewModel: PLATFORM.moduleName('resources/dialog/yes-no-cancel'),
        model: dirtyModel,
      })
      .whenClosed(response => {
        if (!response.wasCancelled) {
          this.slicer = _.cloneDeep(this.slicerCopy);
          this.slicerClone = _.cloneDeep(this.slicerCopy);
          this.slicerClone.slicer_id = '';
        }
        return !response.wasCancelled;
      });
  }

  public async cloneSlicer(edit: boolean = false) {
    try {
      this.slicerClone.target_state = 'INACTIVE';
      const res: any = await this.service.createSlicer(this.slicerClone);
      await this.cloneSlicerDialog.hide();
      this.notification.success(`Slicer cloned successfully`);
      if (edit) {
        this.slicer = res;
        this.slicerClone = _.clone(this.slicer);
        this.slicerClone.slicer_id = '';
        this.router.navigateToRoute('hostedSlicerSingle', {id: res.slicer_id});
      }
    } catch (e) {
      this.notification.error(`Failed to clone slicer: ${e}`);
    }
  }

  public formCancel() {
    this.slicerClone = _.cloneDeep(this.slicerCopy);
    this.slicerClone.slicer_id = '';
    this.cloneSlicerDialog.hide();
  }

  public async loadEncodingProfiles() {
    this.profiles = await this.profilesService.get();
  }

  public detached() {
    this.hyperionPolling.stop();
  }
}
