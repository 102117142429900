import {LogManager} from 'aurelia-framework';
import * as moment from 'moment';

const log = LogManager.getLogger('hyperion-polling');

export interface IPollOptions {
  /** Use `include_deleted` param: tell the server to included deleted items on poll */
  includeDeleted?: boolean;

  /** Number of fails before firing failCallback function */
  maxFails?: number;

  /** Polling interval in ms */
  ms: number;

  /** Use `after` param: tell the server to give me updates after x seconds */
  useAfter?: boolean;

  /** Callback to handle data after it has been received */
  callbackFn?(res?: any): any;

  /** Callback to handle a failing polling job */
  failCallback?(err): any;

  /** Function to get the current params */
  getParams?(): any;

  /** Async call to fire off to get updates */
  promiseFn(params?: any): Promise<any>;
}

export class HyperionPolling {
  private includeDeleted: boolean = true;
  private maxFails: number = 0;
  private ms: number = 60 * 1000; // 60 second default poll
  private useAfter: boolean = false;

  private poll = null;
  private lastSuccessful;
  private numOfFails: number = 0;

  constructor(options: IPollOptions) {
    Object.assign(this, options);
  }

  /**
   * Start the polling operation
   */
  public start() {
    this.stop();
    this.poll = setInterval(() => this.pollingFn(), this.ms);
  }

  /**
   * Stop the polling operation
   */
  public stop() {
    if (!this.poll) {
      return;
    }

    clearInterval(this.poll);
    this.poll = null;
    this.numOfFails = 0;
  }

  public manualPoll() {
    this.stop();

    this.pollingFn();

    this.start();
  }

  private async pollingFn() {
    if (this.maxFails && this.numOfFails >= this.maxFails) {
      this.stop();
      this.failCallback(new Error('Polling Failed'));
      return;
    }

    const currentTime = moment();
    const params = {...this.getParams()};

    if (this.useAfter) {
      if (!this.lastSuccessful) {
        this.lastSuccessful = moment(currentTime).subtract(this.ms, 'ms');
      }
      const diff = Math.ceil((Number(moment().diff(this.lastSuccessful, 'ms', true)) + this.ms) / 1000);
      params.after = `now-${diff}s`;
    } else if ('after' in params) {
      delete params.after;
    }

    if (this.includeDeleted) {
      params.include_deleted = true;
    } else if ('include_deleted' in params) {
      delete params.include_deleted;
    }

    try {
      const res = await this.promiseFn(params);

      // Make sure that the call didn't take too long and get replaced by another
      // We don't trust old results
      if (currentTime.isBefore(this.lastSuccessful)) {
        return;
      }

      // Restamp with last successful start time as a measure - and include latency
      const latency = moment().diff(currentTime, 'ms', true);
      this.lastSuccessful = moment(currentTime).subtract(latency, 'ms');

      this.callbackFn(res);
    } catch (err) {
      log.error(err);
      this.numOfFails += 1;
    }
  }

  // Do nothing functions to have safe defaults
  private callbackFn = (res?: any) => res;
  private failCallback = err => err;
  private getParams = (): any => ({});
  private promiseFn = (params?: any): Promise<any> => Promise.reject(params);
}
