import {autoinject, computedFrom, LogManager} from 'aurelia-framework';
import {Logger} from 'aurelia-logging';

import {IPaginationMeta, IHyperionFilterParams} from 'interfaces/hyperion';
import {IKeyValue} from 'interfaces/key-value';
import {Acceo} from 'services/acceo';

import {IIntegrationNew, IdsIntegration} from '../models/ids-integration';
import {SsoDomain, SsoDomainResponse, ISsoDomainParams, ISsoDomain} from '../models/sso-domain';
import {SsoDomainUserResponse, SsoDomainUserUpdateResponse} from '../models/sso-domain-user';

import {BASE_DOMAIN_URL, BASE_INTEGRATION_URL} from '../constants';

export const DEFAULT_PER_PAGE_LIMIT = 20;
export const DEFAULT_ORDER = 'domain';

@autoinject()
export class SsoDomainService {
  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;
  public isProcessing: boolean = false;

  public params: ISsoDomainParams = {};
  public meta: IPaginationMeta = {};

  public records: SsoDomain[] = [];
  public selected: string[] = [];

  protected logger: Logger;

  @computedFrom('params.order')
  public get order() {
    return this.params?.order || DEFAULT_ORDER;
  }

  constructor(protected acceo: Acceo) {
    this.logger = LogManager.getLogger('SSO Domain Service');
  }

  /*
    Static Methods
  */

  static parseResponseErrors(errors: IKeyValue<string> = null): string {
    if (!errors) {
      return null;
    }

    const keys = Object.keys(errors);

    const _errors = keys.map(k => `User (${k}) - ${errors[k]}`);

    return _errors.join('\n');
  }

  /*
    Public Methods
  */

  public async addUsersToDomain(domainId: string, ids: string[]): Promise<SsoDomainUserUpdateResponse> {
    if (this.isProcessing) {
      return null;
    }

    try {
      this.isProcessing = true;
      const url = `${BASE_DOMAIN_URL}/${domainId}/users`;
      const resp = await this.acceo.post(SsoDomainUserUpdateResponse)(url, {ids});

      return resp;
    } catch (err) {
      this.logger.error(err);
      throw new Error(err.message);
    } finally {
      this.isProcessing = false;
    }
  }

  public async createRecord(domain: ISsoDomain, integration: IIntegrationNew): Promise<SsoDomain> {
    if (this.isProcessing) {
      return null;
    }

    if (!domain.id) {
      delete domain.id;
    }

    const data = {
      domain,
      integration,
    };

    try {
      this.isProcessing = true;
      const resp = await this.acceo.post(SsoDomain)(BASE_DOMAIN_URL, data);

      return resp;
    } catch (err) {
      this.logger.error(err);
      throw new Error(err.message);
    } finally {
      this.isProcessing = false;
    }
  }

  /**
   * Delete workspace key
   * @param id
   */
  public async deleteRecord(id: string): Promise<boolean> {
    if (this.isProcessing) {
      return false;
    }

    try {
      this.isProcessing = true;
      await this.acceo.delete(SsoDomain)(`${BASE_DOMAIN_URL}/${id}`);

      const record = this.records.find(k => k.id === id);

      if (record) {
        this.removeLocalRecord(record);
      }

      return true;
    } catch (err) {
      this.logger.error(err.message);
      throw err;
    } finally {
      this.isProcessing = false;
    }
  }

  public async getIntegration(id: string): Promise<IdsIntegration> {
    if (this.isLoading) {
      return null;
    }

    let integration: IdsIntegration;

    try {
      this.isLoading = true;
      integration = await this.acceo.get(IdsIntegration)(`${BASE_INTEGRATION_URL}/${id}`);
    } catch (err) {
      this.logger.error(err.message);
      throw err;
    } finally {
      this.isLoading = false;
    }

    return integration;
  }

  public async getRecord(id: string, includeIntegration: boolean = false): Promise<SsoDomain> {
    if (this.isLoading) {
      return null;
    }

    let record: SsoDomain;

    this.isLoading = true;

    try {
      let url = `${BASE_DOMAIN_URL}/${id}`;

      if (includeIntegration) {
        url += '?included=integration';
      }

      record = await this.acceo.get(SsoDomain)(url);
    } catch (err) {
      this.logger.error(err.message);
      throw err;
    } finally {
      this.isLoading = false;
    }

    return record;
  }

  /**
   * Get IDS Users
   * @param params
   * @param isLoadingMore
   */
  public async getRecords(params: ISsoDomainParams, isLoadingMore: boolean = false): Promise<void> {
    if (this.isLoading || this.isLoadingMore) {
      return;
    }

    if (!params.page_size) {
      params.page_size = DEFAULT_PER_PAGE_LIMIT;
    }

    if (!params.order) {
      params.order = DEFAULT_ORDER;
    }

    if (!isLoadingMore) {
      this.records = [];
      this.isLoading = true;
      params.page = 1;
    } else {
      if (!this.meta.hasMore) {
        return;
      }

      this.isLoadingMore = true;
      params.page = (params.page || 1) + 1;
    }

    this.params = _.cloneDeep(params);

    try {
      const urlParams = $.param(this.params);

      const url = `${BASE_DOMAIN_URL}?${urlParams}`;

      const resp = await this.acceo.get(SsoDomainResponse)(url);

      this.records = _.uniqBy(this.records.concat(resp.items), 'id');

      // Safety check to prevent query spamming
      const totalPages = Math.ceil(resp.total_items / params.page_size);

      this.meta.total = resp.total_items;
      this.meta.showing = this.records.length;
      this.meta.limit = params.page_size || null;
      this.meta.hasMore = params.page < totalPages;
    } catch (err) {
      this.logger.error(err);
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
    }
  }

  /**
   * Loads more records
   */
  public async getMore() {
    if (this.isLoading || this.isLoadingMore || !this.meta.hasMore) {
      return;
    }

    const params = _.cloneDeep(this.params);

    await this.getRecords(params, true);
  }

  public async getDomainUsers(domainId: string, params: IHyperionFilterParams = {}): Promise<SsoDomainUserResponse> {
    const queryParams = $.param(params);
    const url = `${BASE_DOMAIN_URL}/${domainId}/users?${queryParams}`;

    return this.acceo.get(SsoDomainUserResponse)(url);
  }

  /**
   * Poll workspace keys for changes
   * @param params
   */
  public async pollRecords(params): Promise<SsoDomainResponse> {
    const pollArgs = _.cloneDeep(params);
    delete pollArgs.page_size;
    delete pollArgs.page;
    delete pollArgs.order;

    const queryParams = $.param(pollArgs);
    const url = `${BASE_DOMAIN_URL}?${queryParams}`;

    return this.acceo.get(SsoDomainResponse)(url);
  }

  /**
   * Processes changes from polling
   * @param changes
   */
  public processPollData(changes: SsoDomainResponse): boolean {
    if (!changes || changes.total_items === 0) {
      return false;
    }

    const {items = []} = changes;

    items.forEach(i => this.updateLocalRecord(i));
    this.records = _.uniqBy(this.records.concat(), 'id');

    return true;
  }

  /**
   * Removes link for user to domain
   * @param domainId
   * @param ids
   */
  public async removeUsersFromDomain(domainId: string, ids: string[]): Promise<SsoDomainUserUpdateResponse> {
    try {
      const url = `${BASE_DOMAIN_URL}/${domainId}/users?ids=${ids.join(',')}`;
      return this.acceo.delete(SsoDomainUserUpdateResponse)(url, {ids});
    } catch (err) {
      this.logger.error(err);
      throw new Error(err.message);
    }
  }

  public async updateRecord(id: string, params): Promise<void> {
    if (this.isProcessing) {
      return;
    }

    try {
      this.isProcessing = true;
      await this.acceo.patch(SsoDomain)(`${BASE_DOMAIN_URL}/${id}`, params);
    } catch (err) {
      this.logger.error(err);
      throw new Error(err.message);
    } finally {
      this.isProcessing = false;
    }
  }

  /*
    Private Methods
  */

  /**
   * Updates or adds a record to the keys collection
   * @param record
   */
  private addOrUpdateRecord(record: SsoDomain): void {
    const index = this.records.findIndex(k => k.id === record.id);

    if (index < 0) {
      this.meta.total += 1;
      this.meta.showing += 1;
      this.records.unshift(record);
    } else {
      this.records[index] = record;
    }
  }

  /**
   * Removes record from keys collection if found
   * @param record
   */
  private removeLocalRecord(record: SsoDomain) {
    const index = this.records.findIndex(k => k.id === record.id);

    if (index < 0) {
      return;
    }

    this.meta.total -= 1;
    this.meta.showing -= 1;
    this.records.splice(index, 1);
  }

  /**
   * Updates record in keys collection
   * @param record
   */
  private updateLocalRecord(record: SsoDomain) {
    if (record.deleted) {
      this.removeLocalRecord(record);
    } else {
      this.addOrUpdateRecord(record);
    }
  }
}
