import {autoinject, bindable} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {HyperionPolling} from 'services/hyperion-polling';
import {SyndicationRegion} from 'services/models/syndication';
import {SyndicationJob} from 'services/models/syndication-job';
import {SyndicationJobService} from 'services/syndication-job-service';
import {TableColumn} from 'utils/table-sorting-tools';
import {LiveChannelsService} from 'apps/cms/routes/live-channels/channels/services/live-channels';
import {LiveEventsService} from 'apps/cms/routes/live-events/live-events/services/live-events';
import {SyndicationTargetService} from 'services/syndication-target-service';

export const CHANNELS_HREF: string = '#/live-channels/channels';
export const EVENTS_HREF: string = '#/live-events/events';
const STATUS_REFRESH_INTERVAL = 8000;

@autoinject()
export class SyndicationJobsList {
  public sourceTypes = [
    {label: 'Channel', value: 'assetType_channel', checked: false},
    {label: 'Live Event', value: 'assetType_liveEvent', checked: false},
  ];

  public filteredSourceType: string = 'assetType_all';

  public statusTypes = [
    {label: 'Active', value: 'statusType_active', checked: false},
    {label: 'Scheduled', value: 'statusType_scheduled', checked: false},
    {label: 'Stopped', value: 'statusType_stopped', checked: false},
    {label: 'Error', value: 'statusType_error', checked: false},
  ];

  public filteredStatusType = {
    statusType_active: false,
    statusType_error: false,
    statusType_scheduled: false,
    statusType_stopped: false,
  };

  public isStatusFiltered: boolean = false;

  public jobs: SyndicationJob[] = [];
  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;
  public loadingError: boolean = false;
  public intervalPaused: boolean = false;
  public pollTracker: HyperionPolling;
  public syndRegions: SyndicationRegion[] = [];

  public searchText: string = '';
  public hiddenColumns: any[] = [];
  public selectedJobs: any[] = [];

  @bindable
  public selectedSource: any = null;

  @bindable
  public sourceType: string = 'channel';

  @bindable
  public sourceSearchText: string = '';

  public filteredSourceList: any[] = [];
  public isLoadingSourceList: boolean = false;

  @bindable
  public selectedTarget: any = null;

  @bindable
  public targetSearchText: string = '';

  public filteredTargetList: any[] = [];
  public isLoadingTargetList: boolean = false;

  public tableColumns = [
    new TableColumn('Source', 'content_description', {isHideable: false}),
    new TableColumn('Target', 'description', {isHideable: false}),
    new TableColumn('Status', 'status', {isHideable: false}),
    new TableColumn('Platform', 'platform'),
    new TableColumn('Protocol', 'target_protocol'),
    new TableColumn('Region', 'region'),
    new TableColumn('Last Start', 'start', {isVisible: false}),
    new TableColumn('Last Stop', 'stop', {isVisible: false}),
    new TableColumn('ID', 'id'),
  ];

  constructor(
    public syndicationJobService: SyndicationJobService,
    public syndicationTargetService: SyndicationTargetService,
    public liveChannels: LiveChannelsService,
    public liveEvents: LiveEventsService,
    public router: Router,
  ) {
    this.router = router;

    this.pollTracker = new HyperionPolling({
      callbackFn: () => {
        this.jobsTableFormat();
      },
      ms: STATUS_REFRESH_INTERVAL,
      promiseFn: () => this.getVisibleJobs(),
      useAfter: true,
    });
  }

  public activate() {
    this.syndicationJobService.updateParams({page: 1});
    this.isLoading = true;
    this.loadJobs(true);
  }

  public async loadJobs(overrideJobs = false) {
    try {
      const ans = await this.syndicationJobService.getSyndicationJobs(
        this.filteredSourceType,
        this.filteredStatusType,
        overrideJobs,
      );
      if (ans) {
        this.jobsTableFormat();
      }
    } catch (error) {
      this.loadingError = true;
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
      this.pollTracker.start();
    }
  }

  public pauseInterval() {
    this.intervalPaused = true;
  }

  public resumeInterval() {
    this.intervalPaused = false;
  }

  public async getVisibleJobs() {
    if (this.intervalPaused) {
      return;
    }
    let visibleJobs: string[] = [];

    const scrollElem = $('#jobs-table');
    const jobItemElems = scrollElem.find('lynk-tbody').children();
    // Make sure we have something
    if (!scrollElem.children().length || !jobItemElems.length) {
      return;
    }

    const scrollElemRect = scrollElem[0].getBoundingClientRect();

    jobItemElems.each((_i, elem) => {
      const elemRect = elem.getBoundingClientRect();

      if (!(elemRect.top > scrollElemRect.bottom || elemRect.bottom < scrollElemRect.top)) {
        visibleJobs.push(elem.id);
      }
    });

    // Remove anything that isn't in the current groups list
    _.remove(visibleJobs, jobId => !_.find(this.jobs, {id: jobId}));

    // Make sure values are unique
    visibleJobs = _.uniq(visibleJobs);

    await this.syndicationJobService.getSyndicationJobsUpdates(visibleJobs);
  }

  public jobsTableFormat() {
    this.jobs = _.cloneDeep(this.syndicationJobService.syndicationJobs);
    this.jobs.forEach(job => {
      if (job.start) {
        job.start = new Date(parseInt(job.start, 10)).toLocaleString();
      }
      if (job.stop) {
        job.stop = new Date(parseInt(job.stop, 10)).toLocaleString();
      }

      if (job.status === 'active' || job.status === 'scheduled') {
        job.stop = '';
      }

      if (job.content_type === 'c') {
        job.content_type = 'Channel';
      } else if (job.content_type === 'e') {
        job.content_type = 'Live Event';
      }
    });
  }

  public async filterJobs(item) {
    if (this.sourceTypes.some(st => st.value === item.value)) {
      await this.updateFilteredSourceType(item.value, item.checked);
    } else if (this.statusTypes.some(st => st.value === item.value)) {
      await this.updateFilteredStatusType(item.value, item.checked);
    } else {
      // console.error('Item does not belong to sourceTypes or statusTypes arrays');
    }
    this.syndicationJobService.updateParams({page: 1});
    this.loadJobs(true);
  }

  public updateFilteredSourceType(value: string, checked: boolean): void {
    // Check if the item belongs to the sourceTypes array
    const sourceTypeItem = this.sourceTypes.find(st => st.value === value);

    if (!sourceTypeItem) {
      return;
    }

    // Update the checked value of the matching object in the sourceTypes array
    sourceTypeItem.checked = checked;

    // Count the number of checked items
    const checkedCount = this.sourceTypes.filter(st => st.checked).length;

    // Get the value of the last checked item
    const lastCheckedValue = this.sourceTypes.find(st => st.checked)?.value || '';

    // Determine the filteredSourceType
    if (checkedCount === this.sourceTypes.length) {
      this.filteredSourceType = 'assetType_all';
    } else if (checkedCount === 1) {
      this.filteredSourceType = lastCheckedValue;
    } else {
      this.filteredSourceType = 'assetType_all';
    }
  }

  public updateFilteredStatusType(value: string, checked: boolean): void {
    const statusTypeItem = this.statusTypes.find(st => st.value === value);

    if (!statusTypeItem) {
      return;
    }

    statusTypeItem.checked = checked;

    // Update filteredStatusType to reflect the current state of statusTypes
    this.filteredStatusType = {
      statusType_active: this.statusTypes.find(st => st.value === 'statusType_active')?.checked || false,
      statusType_error: this.statusTypes.find(st => st.value === 'statusType_error')?.checked || false,
      statusType_scheduled: this.statusTypes.find(st => st.value === 'statusType_scheduled')?.checked || false,
      statusType_stopped: this.statusTypes.find(st => st.value === 'statusType_stopped')?.checked || false,
    };

    // Update isStatusFiltered to true if any items are unchecked
    this.isStatusFiltered = Object.values(this.filteredStatusType).some(checked => !checked);
  }

  public async clearFilter(value) {
    if (this.sourceTypes.some(st => st.value === value)) {
      await this.updateFilteredSourceType(value, false);
    } else if (this.statusTypes.some(st => st.value === value)) {
      await this.updateFilteredStatusType(value, false);
    } else {
      // console.error('Item does not belong to sourceTypes or statusTypes arrays');
    }
    this.syndicationJobService.updateParams({page: 1});
    this.loadJobs(true);
  }

  public sourceClickHandler(job, event) {
    event.preventDefault();
    event.stopPropagation();
    this.isLoading = true;
    if (job.content_type === 'Channel') {
      this.router.navigate(`${CHANNELS_HREF}/${job.content_id}/publish`);
    } else if (job.content_type === 'Live Event') {
      this.router.navigate(`${EVENTS_HREF}/${job.content_id}/publish`);
    }
  }

  public detached() {
    this.pollTracker.stop();
  }

  public findColumnByKey(key: string): TableColumn | undefined {
    return this.tableColumns.find(column => column.key === key);
  }

  public showHideColumn(col) {
    col.isVisible = !col.isVisible;

    if (col.isVisible) {
      const idx = this.hiddenColumns.indexOf(col.key);
      if (idx !== -1) {
        this.hiddenColumns.splice(idx, 1);
      }
    } else {
      this.hiddenColumns.push(col.key);
    }
  }

  /**
   * Route to the single for the job that was clicked.
   */
  public goToSingle(id) {
    this.router.navigate(`/syndication/jobs/${id}`);
  }

  public toggleJobSelected(job) {
    job.selected = !job.selected;
    this.trackSelectedEvent(job);
  }

  /**
   * Track an job as it's toggled for deletion or duplication
   */
  public trackSelectedEvent(job) {
    if (job.selected) {
      this.selectedJobs.push(job.id);
    } else {
      _.remove(this.selectedJobs, jobId => jobId === job.id);
    }

    this.selectedJobs = _.uniq(this.selectedJobs);
  }

  /**
   * Toggle all visible jobs for deletion or duplication
   *
   * @param isSelected Whether the select all checkbox is currently selected
   */
  public toggleSelectAll(isSelected: boolean) {
    _.forEach(this.jobs, job => {
      if (isSelected) {
        job.selected = true;
      } else {
        job.selected = false;
      }
    });

    this.selectedJobs = _.map(
      _.filter(this.jobs, job => job.selected),
      job => job.id,
    );
  }

  public async onCreateDialogInit() {
    this.isLoadingSourceList = true;
    this.getTargets();
    await this.getChannels();
    if (!this.liveChannels.channels.length) {
      this.sourceType = 'event'; // Change to 'event' if no channels
      await this.getEvents();
    }
    this.updateFilteredSourceList();
    this.isLoadingSourceList = false; // Reset loading state
  }

  public async updateSourceListData() {
    try {
      if (this.sourceType === 'channel') {
        this.isLoadingSourceList = true; // Set loading state
        await this.getChannels();
        this.updateFilteredSourceList();
        this.isLoadingSourceList = false; // Reset loading state
        this.getEvents(); // Fetch events in the background
      } else if (this.sourceType === 'event') {
        this.isLoadingSourceList = true; // Set loading state
        await this.getEvents();
        this.updateFilteredSourceList();
        this.isLoadingSourceList = false; // Reset loading state
        this.getChannels(); // Fetch channels in the background
      }
    } catch (e) {
      // Handle error notification
      this.isLoadingSourceList = false; // Reset loading state in case of error
    }
  }

  private async getChannels() {
    try {
      await this.liveChannels.search(this.sourceSearchText);
    } catch (e) {
      // Handle error notification
    }
  }

  private async getEvents() {
    try {
      await this.liveEvents.getLiveEvents({search: this.sourceSearchText});
    } catch (e) {
      // Handle error notification
    }
  }

  private async getTargets() {
    this.isLoadingTargetList = true;
    try {
      await this.syndicationTargetService.getSyndicationTargets();
      this.filteredTargetList = this.syndicationTargetService.syndicationTargets;
    } catch (e) {
      // Handle error notification
    }
    this.isLoadingTargetList = false;
  }

  public async sourceTypeChanged() {
    await this.updateSourceListData();
  }

  public async sourceSearchTextChanged() {
    await this.updateSourceListData();
  }

  private updateFilteredSourceList() {
    if (this.sourceType === 'channel') {
      this.filteredSourceList = this.liveChannels.channels;
    } else if (this.sourceType === 'event') {
      this.filteredSourceList = this.liveEvents.data;
    }
  }

  public async targetSearchTextChanged() {
    const filtered = this.syndicationTargetService.syndicationTargets.filter(
      target => target.description.toLowerCase().indexOf(this.targetSearchText.toLowerCase()) >= 0,
    );
    this.filteredTargetList = filtered;
  }

  public handleSourceListSelect(source) {
    if (source.checked) {
      this.selectedSource = source.value;
    } else {
      this.selectedSource = null;
    }
  }

  public handleTargetListSelect(target) {
    if (target.checked) {
      this.selectedTarget = target.value;
    } else {
      this.selectedTarget = null;
    }
  }

  public async loadMoreJobs() {
    if (this.isLoading || this.isLoadingMore || !this.syndicationJobService.meta.hasMore) {
      return;
    }

    this.isLoadingMore = true;
    this.syndicationJobService.getMoreJobs();
    await this.loadJobs(false);
  }
}
