import 'fetch';
import * as CryptoJS from 'crypto-js';
import {Aurelia, inject, singleton} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {HttpClient} from 'aurelia-fetch-client';
import {computedFrom} from 'aurelia-binding';
import {Notification} from 'resources/notification/service';

export const OwnerMetaContext = {
  AUDIENCE: 'audience',
  LIVE_EVENTS: 'live-events',
  SCHEDULE_RULE: 'schedule-rule',
  SLICER_MONITORING: 'slicer-monitoring',
  TEST_PLAYER: 'test-player',
  CLIPPING_PROFILES: 'clipping-profiles',
};

@singleton()
@inject(HttpClient, Notification, Aurelia, Router)
export class SessionService {
  constructor(httpClient, notification, aurelia, router) {
    this.httpClient = httpClient;
    this.notification = notification;
    this.aurelia = aurelia;
    this.router = router;

    this.fingerprint = null;
    this.total_notifications = 0;
    this.SESSION_PING_INTERVAL_MS = 60000;
    this.NOTIFICATION_PING_INTERVAL_MS = 60000;

    this.httpClient.configure(config => config.withDefaults({credentials: 'same-origin'}));
    this.sessionInfo = null;

    this.form_url_encoded = 'application/x-www-form-urlencoded';
    this.loginResponse = {
      error: 0,
      msg: '',
    };

    // These are in the index.html.  May want to move this to a configuration file
    this.appRoot = this.aurelia.host.attributes.start.value;
    if (this.aurelia.host.attributes.login !== undefined) {
      this.loginRoot = this.aurelia.host.attributes.login.value;
    } else {
      this.loginRoot = this.appRoot;
    }
  }

  checkResponseForError(res) {
    if ('error' in res) {
      if ('msg' in res) {
        this.error = res.msg;
      } else {
        this.error = 'An Unknown Error Has Occurred in AnalyticsData';
      }
      if (res.error !== 0) {
        return true;
      }
    }
    return false;
  }

  check() {
    return this.httpClient
      .fetch(`/session/check/?origin=${encodeURIComponent(window.location.href)}`)
      .then(r => r.json())
      .then(data => {
        this.sessionInfo = data;
        return data;
      });
  }

  ping() {
    return this.httpClient
      .fetch(`/session/ping/?origin=${encodeURIComponent(window.location.href)}`)
      .then(r => r.json())
      .then(data => {
        if (data.cms_session_fingerprint != null) {
          this.fingerprint = data.cms_session_fingerprint;
        } else {
          this.fingerprint = null;
        }
        this.total_notifications = data.notifications && data.notifications !== null ? data.notifications : 0;
      });
  }

  // Call to keep session alive
  sessionPing() {
    this.ping().then(() => {});
    this.sessionPingPolling = setTimeout(() => {
      this.sessionPing();
    }, this.SESSION_PING_INTERVAL_MS);
  }

  pingNotification() {
    return this.httpClient
      .fetch('/api/v4/owner_notification-notifyee-ping')
      .then(r => r.json())
      .then(data => {
        this.total_notifications = data.notifications && data.notifications !== null ? data.notifications : 0;
      });
  }

  notificationPing() {
    this.pingNotification().then(() => {});
    this.notificationPingPolling = setTimeout(() => {
      this.notificationPing();
    }, this.NOTIFICATION_PING_INTERVAL_MS);
  }

  logout(doMigration = false) {
    // const cur = window.location.href;
    // var result = null;
    return this.httpClient
      .fetch(`/session/logout/?origin=${encodeURIComponent(window.location.href)}`)
      .then(r => r.json())
      .then(data => {
        // We want to stop pinging notifications if we are logged out
        clearInterval(this.sessionPingPolling); // We want to stop pinging if we are logged out
        clearInterval(this.notificationPingPolling);
        this.sessionInfo = data;
        if (doMigration) return;
        // window.location.href = `${data.login}?login_redirect=${encodeURIComponent(cur)}`;
        if (this.isIdsEnabled(this.sessionInfo)) {
          // perform the logout, this will clear the cms and ids session and then redirect
          // to ids login page with port redirect set to the current cms page
          window.location.href = this.sessionInfo.loggedOut;
        } else {
          this.aurelia.setRoot(this.loginRoot);
        }
      });
  }

  // This logs the user in if they give a valid email and password.  It returns as a promise so
  // we can execute aurelia.setRoot a the proper time.
  login(loginEmail, loginPassword, persistLogin, recaptcha, doMigration = false) {
    const loginPersist = persistLogin ? 1 : 0;
    let params = `remember_me=${loginPersist}&username=${encodeURIComponent(loginEmail)}&passwordHash=${CryptoJS.SHA1(
      loginPassword,
    )}`;
    if (recaptcha) {
      params += `&recaptcha=${recaptcha}`;
    }

    const promise = new Promise(resolve => {
      this.httpClient
        .fetch('/session/login2/', {
          method: 'post',
          headers: {
            'Content-Type': this.form_url_encoded,
          },
          type: this.form_url_encoded,
          body: params,
        })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(res => {
              this.loginResponse = res;
              if (!this.checkResponseForError(res)) {
                if (!doMigration) {
                  this.aurelia.setRoot(this.appRoot);
                }
              }
            });
          } else {
            this.notification.error('Error retrieving session.', 'SessionService (error)');
          }
          resolve(true);
        })
        .catch(() => {
          this.notification.error('Error retrieving session.', 'SessionService (catch)');
          resolve(false);
        });
    });
    return promise;
  }

  isIdsEnabled(sessionResponse = null) {
    const idsQueryParmSet = this.getQueryString('idsEnabled') === 'true';
    if (sessionResponse) {
      // idsLogin url is only set when there is no session (haveSession is false)
      return (
        (sessionResponse.idsEnabled || idsQueryParmSet) &&
        (sessionResponse.haveSession || sessionResponse.idsLogin != null)
      );
    }
    return idsQueryParmSet;
  }

  // Check and see if we have an existing session.  This will return the appropriate ROOT.
  isAuthenticated() {
    const promise = new Promise((resolve, reject) => {
      const resetPasswordParameter = this.getQueryString('resetp');
      const errorCode = this.getQueryString('errorCode');
      const idsLoginUrl = this.getQueryString('idsLogin');

      if (errorCode || idsLoginUrl) {
        // migration ui or login error case - simply display ui, no auth required.
        // for now, we use login html itself to show migration ui or login errors.
        resolve(this.loginRoot);
        return;
      }

      let params = '';

      if (resetPasswordParameter) {
        params = `resetp=${resetPasswordParameter}`;
      }
      this.httpClient
        .fetch(`/session/check/?origin=${encodeURIComponent(window.location.href)}`, {
          method: 'post',
          headers: {
            'Content-Type': this.form_url_encoded,
          },
          type: this.form_url_encoded,
          body: params,
        })
        .then(r => r.json())
        .then(data => {
          this.sessionInfo = data;
          // If the user is also trying to reset their password (from an email), then take
          // them to the login page and show them the appropriate screen.
          if (
            this.sessionInfo.haveSession === false ||
            (this.sessionInfo.error && this.sessionInfo.msg === 'Invalid session') ||
            this.sessionInfo.resetp === 1
          ) {
            resolve(this.loginRoot);
          } else {
            resolve(this.appRoot);
          }
        })
        .catch(() => {
          reject(this.loginRoot);
        });
    });
    return promise;
  }

  getQueryString(field) {
    const reg = new RegExp(`[?&]${field}=([^&#]*)`, 'i');
    const string = reg.exec(window.location.href);
    return string ? string[1] : null;
  }

  // We use this when resetting a password via an email link.  If the user successfully
  // does a successful login, then redirect them to the main application home.
  navigateToRoot() {
    this.aurelia.setRoot(this.appRoot);
    this.router.navigate('/');
    window.location.assign('#');
  }

  resetLoginResponse() {
    this.loginResponse.msg = '';
    this.loginResponse.err = 0;
  }

  // loginError so we can dipslay to the end user if they give an invalid email and password.
  @computedFrom('loginResponse', 'loginResponse.msg')
  get loginError() {
    if (this.loginResponse != null) {
      return this.loginResponse.msg;
    }
    return null;
  }

  @computedFrom('sessionInfo', 'sessionInfo.username', 'sessionInfo.ids_email', 'sessionInfo.ids_username')
  get username() {
    if (this.sessionInfo != null) {
      /* if (this.isIdsEnabled(this.sessionInfo)) {
                if (this.sessionInfo.ids_username) {
                    return this.sessionInfo.ids_username;
                }
                return this.sessionInfo.ids_email;
            } */
      // For now we always show legacy username
      return this.sessionInfo.username;
    }
    return null;
  }

  @computedFrom('sessionInfo')
  get isDashEnabled() {
    if (this.sessionInfo != null) {
      return !!+this.sessionInfo.dash_enabled;
    }
    return false;
  }

  @computedFrom('sessionInfo')
  get isStudioDRMEnabled() {
    if (this.sessionInfo != null) {
      return !!+(this.sessionInfo.fairplay_enabled || this.sessionInfo.dash_enabled);
    }
    return false;
  }

  @computedFrom('sessionInfo.ids_domain_id')
  get isSsoEnabled() {
    if (!this.sessionInfo) {
      return false;
    }

    const {ids_domain_id = null} = this.sessionInfo;

    return !!ids_domain_id;
  }

  @computedFrom('sessionInfo')
  get isLowLatencyEnabled() {
    if (this.sessionInfo != null) {
      return !!+this.sessionInfo.staticm3u8_enabled;
    }
    return false;
  }

  // only show ad prefetch settings if enabled for a live event
  @computedFrom('sessionInfo')
  get isAdPreFetchEnabled() {
    if (this.sessionInfo != null) {
      return !!+this.sessionInfo.ad_prefetch_enabled;
    }
    return false;
  }

  // list of ad prefetch slicer overrides for a live event
  @computedFrom('sessionInfo')
  get adPreFetchOverride() {
    if (this.sessionInfo != null) {
      return this.sessionInfo.ad_prefetch_override;
    }
    return false;
  }

  // list of ad prefetch slicer overrides for a live event
  @computedFrom('sessionInfo')
  get adPreFetchOverrideEnabled() {
    let value = false;
    if (this.sessionInfo !== null && this.sessionInfo.ad_prefetch_override !== null) {
      Object.values(this.sessionInfo.ad_prefetch_override).forEach(val => {
        if (val) {
          value = true;
        }
      });
    }
    return value;
  }

  // TODO: isStudioDRMEnabled() and isFairplayAndDashEnabled() implementation is same except for '||' vs '&&'.
  // Once we have consensus on using '||' or '&&', we should keep one function and remove the other.
  isFairplayAndDashEnabled() {
    if (this.sessionInfo != null) {
      // +x or +(x) converts to number
      return !!+(this.sessionInfo.fairplay_enabled && this.sessionInfo.dash_enabled);
    }
    return false;
  }

  isFairPlayEnabled() {
    if (this.sessionInfo != null) {
      // +x or +(x) converts to number
      return !!+this.sessionInfo.fairplay_enabled;
    }

    return false;
  }

  // only show multi cdn settings in settings is this is true
  isMultiCdnEnabled() {
    if (this.sessionInfo != null) {
      return !!+this.sessionInfo.multi_cdn_enabled;
    }
    return false;
  }

  isAdvancedStreamRoutingFeaturesEnabled() {
    if (this.sessionInfo != null) {
      return !!+this.sessionInfo.advanced_stream_routing_features_enabled;
    }
    return false;
  }

  // only show syndication tabs (Settings, Live Channels & Live Events) if this is true
  isSyndicationEnabled() {
    if (this.sessionInfo != null) {
      return !!+this.sessionInfo.syndication_enabled;
    }
    return false;
  }

  isWebhookEnabled() {
    if (this.sessionInfo != null) {
      return !!+this.sessionInfo.hasWebhookAccess;
    }
    return false;
  }

  @computedFrom('sessionInfo', 'sessionInfo.ownerID')
  get owner() {
    if (this.sessionInfo != null) {
      return this.sessionInfo.ownerID;
    }
    return null;
  }

  @computedFrom('sessionInfo')
  get sessionIdFingerprint() {
    if (this.sessionInfo) {
      return this.sessionInfo.cms_session_id_fingerprint;
    }
    return null;
  }

  @computedFrom('sessionInfo', 'sessionInfo.demo_account')
  get demoAccount() {
    if (this.sessionInfo != null) {
      return this.sessionInfo.demo_account;
    }
    return 0;
  }

  static hasEntitlement(sessionEntitlements, verifyEntitlements) {
    const index =
      sessionEntitlements === undefined ? -1 : sessionEntitlements.findIndex(d => !(verifyEntitlements.indexOf(d) < 0));
    return index > -1;
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements', 'sessionInfo.perms')
  get hasHostedSlicersAccess() {
    if (this.sessionInfo.perms.includes('admin')) {
      return true;
    }
    return SessionService.hasEntitlement(this.sessionInfo.entitlements, ['slicermanagement']);
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements', 'sessionInfo.perms')
  get hasFastAccess() {
    if (this.sessionInfo.perms.includes('admin')) {
      return true;
    }
    return SessionService.hasEntitlement(this.sessionInfo.entitlements, ['fast_management']);
  }

  /**
   * Determines if a user can modify Identity (IDS) related features
   *
   * @type {boolean}
   */
  @computedFrom('sessionInfo', 'sessionInfo.perms')
  get hasIdentityAccess() {
    return this.sessionInfo.perms.includes('identity');
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements')
  get hasNocEntitlement() {
    return SessionService.hasEntitlement(this.sessionInfo.entitlements, [
      'noc',
      'nocro',
    ]);
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements')
  get hasNocWriteAccess() {
    return SessionService.hasEntitlement(this.sessionInfo.entitlements, ['noc']);
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements')
  get hasMonitoringWriteAccess() {
    return SessionService.hasEntitlement(this.sessionInfo.entitlements, ['monitoring']);
  }

  @computedFrom('sessionInfo', 'sessionInfo.perms')
  get hasOpsAccess() {
    if (this.sessionInfo.perms.includes('admin')) {
      return true;
    }

    return this.sessionInfo.perms.includes('ops');
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements', 'sessionInfo.perms')
  get hasTestPlayersAccess() {
    if (this.sessionInfo.perms.includes('admin')) {
      return true;
    }
    return SessionService.hasEntitlement(this.sessionInfo.entitlements, [
      'testplayers',
      'testplayersro',
    ]);
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements', 'sessionInfo.perms')
  get hasSlicersCloudWriteAccess() {
    if (this.sessionInfo.perms.includes('admin')) {
      return true;
    }
    return SessionService.hasEntitlement(this.sessionInfo.entitlements, ['slicers_cloud']);
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements', 'sessionInfo.perms')
  get hasSlicersCloudReadOnlyAccess() {
    if (this.sessionInfo.perms.includes('admin')) {
      return true;
    }
    return SessionService.hasEntitlement(this.sessionInfo.entitlements, ['slicers_cloudro']);
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements', 'sessionInfo.perms')
  get hasSlicersCloudAccess() {
    if (this.sessionInfo.perms.includes('admin')) {
      return true;
    }
    return this.hasSlicersCloudReadOnlyAccess || this.hasSlicersCloudWriteAccess;
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements', 'sessionInfo.perms')
  get hasSlicersLiveAccess() {
    if (this.sessionInfo.perms.includes('admin')) {
      return true;
    }
    return SessionService.hasEntitlement(this.sessionInfo.entitlements, [
      'slicers_live',
      'slicers_livero',
    ]);
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements', 'sessionInfo.perms')
  get hasSlicersSlicersTabAccess() {
    if (this.sessionInfo.perms.includes('admin')) {
      return true;
    }
    return this.hasSlicersCloudAccess || this.hasSlicersLiveAccess;
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements', 'sessionInfo.perms')
  get hasSlicersProfileTabAccess() {
    if (this.sessionInfo.perms.includes('admin')) {
      return true;
    }
    return this.hasHostedSlicersAccess;
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements', 'sessionInfo.perms')
  get hasSlicersIngestPointsTabAccess() {
    if (this.sessionInfo.perms.includes('admin')) {
      return true;
    }
    return this.hasSlicersCloudAccess;
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements', 'sessionInfo.perms')
  get hasSlicersConfigurationTabAccess() {
    if (this.sessionInfo.perms.includes('admin')) {
      return true;
    }
    return this.hasSlicersSlicersTabAccess;
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements', 'sessionInfo.perms')
  get hasSlicersLatencyAccess() {
    if (this.sessionInfo.perms.includes('admin')) {
      return true;
    }
    return SessionService.hasEntitlement(this.sessionInfo.entitlements, ['slicers:latency']);
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements', 'sessionInfo.perms')
  get hasTestPlayersWriteAccess() {
    if (this.sessionInfo.perms.includes('admin')) {
      return true;
    }
    return SessionService.hasEntitlement(this.sessionInfo.entitlements, ['testplayers']);
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements', 'sessionInfo.perms')
  get hasWebhookAccess() {
    if (this.sessionInfo.perms.includes('admin')) {
      return true;
    }
    return SessionService.hasEntitlement(this.sessionInfo.entitlements, ['webhook']);
  }

  @computedFrom('sessionInfo', 'sessionInfo.entitlements')
  get hasPodAccess() {
    // return SessionService.hasEntitlement(this.sessionInfo.entitlements, ['pods']);
    return true;
  }

  // This indicates if the user has switched to a different account
  @computedFrom('sessionInfo', 'sessionInfo.username', 'sessionInfo.origUsername')
  get hasSwitchedUser() {
    if (this.sessionInfo != null) {
      return this.sessionInfo.username !== this.sessionInfo.origUsername;
    }
    return false;
  }

  // This indicates if a user is paying their bill use the OLD billing system (strip credit card)
  @computedFrom('sessionInfo', 'sessionInfo.billing_stripe')
  get stripeBilling() {
    if (this.sessionInfo != null) {
      return this.sessionInfo.billing_stripe;
    }
    return 0;
  }

  setOwnerMeta(context, dict) {
    const meta = [];
    Object.keys(dict).forEach(k => {
      meta.push({
        key: k,
        value: dict[k],
      });
    });
    const postData = {
      meta,
    };
    return new Promise((resolve, reject) => {
      this.httpClient
        .fetch(`/owner-meta/set/${context}`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        })
        .then(r => r.json())
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getOwnerMeta(context, key) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .fetch(`/owner-meta/get/${context}/${key}`)
        .then(r => r.json())
        .then(data => {
          if (data.error === 0) {
            resolve(data.value);
          }
          reject(new Error('Error or no value'));
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getSlicerMonitoringUISettings() {
    return this.httpClient
      .fetch('/owner-meta/get/slicer-monitoring')
      .then(r => r.json())
      .then(data => {
        if (!this.sessionInfo) {
          this.sessionInfo = {};
        }
        this.sessionInfo.slicerMonitoringUISettings = data.slicer_monitoring_meta;
      });
  }
}
