import {autoinject, computedFrom} from 'aurelia-framework';

import {CToastsService} from '@bindable-ui/bindable';

import {sortBy} from 'utils/sort-tools';
import {HyperionPolling} from 'services/hyperion-polling';

import {SsoDomain} from '../../../models/sso-domain';
import {ISsoDomainUser, SsoDomainUserUpdateResponse} from '../../../models/sso-domain-user';

import {SsoDomainService} from '../../../services/sso-domain';
import {OwnerService} from '../../../services/owners';
import {SsoDomainUserService} from '../../../services/sso-domain-user';
import {ISearchableItem, ISearchActions} from '../../../../../../../components/search-stack';

export interface IActions {
  onClose: () => void;
  onSave: () => void;
}

@autoinject()
export class DomainUsers {
  public model: SsoDomain = null;
  public pollTracker: HyperionPolling;
  public pollInterval: number = 10 * 1000;
  public records: ISsoDomainUser[] = [];
  public trackedUsers: Set<string>;
  public trackedOwners: Set<string>;

  public isProcessing: boolean = false;

  public ownerSearchTxt: string = null;

  private domainId: string = null;
  private actions: IActions;
  private searchTxt: string = null;

  public ownerSearchActions: ISearchActions = {
    onSearch: (txt: string) => {
      this.ownerService.list(txt);
      this.ownerSearchTxt = txt;
      this.trackedOwners = new Set();
    },
    onClear: () => {
      this.ownerService.clear();
      this.ownerSearchTxt = null;
      this.trackedOwners = new Set();
    },
    onSelect: id => this.trackOwner(id),
  };

  public userSearchActions: ISearchActions = {
    onClear: () => {
      this.searchTxt = null;
      this.trackedUsers = new Set();
    },
    onSearch: (txt: string) => {
      this.searchTxt = txt.toLowerCase();
      this.trackedUsers = new Set();
    },
    onSelect: id => this.trackUser(id),
  };

  @computedFrom('model.title')
  public get modalTitle() {
    const title = 'User Management';

    if (!this.model) {
      return title;
    }

    return `${this.model.title} ${title}`;
  }

  @computedFrom('userService.records.length', 'searchTxt')
  public get users(): ISearchableItem[] {
    if (!this.userService.records || this.userService.records.length === 0) {
      return [];
    }

    let filtered = [...this.userService.records];

    if (this.searchTxt?.length > 0) {
      filtered = filtered.filter(r => r.username.toLowerCase().includes(this.searchTxt));
    }

    return sortBy(filtered, 'username').map(u => ({
      value: u.id,
      text: u.username,
      disabled: false,
    }));
  }

  @computedFrom('users.length', 'ownerService.records.length')
  public get owners(): ISearchableItem[] {
    const ids = (this.users || []).map(r => r.value);
    const {records = []} = this.ownerService;

    return records
      .filter(o => !ids.includes(o.id))
      .map(u => ({
        value: u.id,
        text: u.username,
        disabled: false,
      }));
  }

  constructor(
    public domainService: SsoDomainService,
    public notificationService: CToastsService,
    public ownerService: OwnerService,
    public userService: SsoDomainUserService,
  ) {
    this.reset();

    this.pollTracker = new HyperionPolling({
      callbackFn: res => this.userService.processPollData(res),
      getParams: () => this.userService.params,
      ms: this.pollInterval,
      promiseFn: args => {
        if (document.hidden) {
          return null;
        }

        return this.userService.pollRecords(this.domainId, args);
      },
      useAfter: true,
    });
  }

  /*
    Aurelia Hooks
  */

  public activate({actions, domainId}) {
    this.actions = actions;
    this.domainId = domainId;

    this.init();
  }

  public deactivate() {
    this.reset();
  }

  /*
    Static Methods
  */

  static trackRow(collection: Set<string>, id: string) {
    if (collection.has(id)) {
      collection.delete(id);
    } else {
      collection.add(id);
    }

    // Aurelia can't track a set, so replace it to force rendering updates
    return new Set(collection);
  }

  /*
    Public Methods
  */

  public async addOwners(): Promise<void> {
    if (this.isProcessing) {
      this.notificationService.info('Please wait');
      return;
    }

    try {
      this.isProcessing = true;

      const ids = Array.from(this.trackedOwners);

      const resp = await this.domainService.addUsersToDomain(this.model.id, ids);

      this.processResponse(resp, 'trackedOwners', this.users);

      const ct = ids.length;

      this.notificationService.success(`Added ${ct} user${ct === 1 ? '' : 's'}`);
    } catch (err) {
      this.notificationService.error(err.message);
    } finally {
      this.isProcessing = false;
    }
  }

  public async removeUsers(): Promise<void> {
    if (this.isProcessing) {
      this.notificationService.warning('Please wait');
      return;
    }

    const ids = Array.from(this.trackedUsers);

    try {
      this.isProcessing = true;
      const resp = await this.domainService.removeUsersFromDomain(this.model.id, ids);

      this.processResponse(resp, 'trackedUsers', this.owners);

      const ct = ids.length;

      this.notificationService.success(`Removed ${ct} user${ct === 1 ? '' : 's'}`);
    } catch (err) {
      this.notificationService.error(err.message);
    } finally {
      this.isProcessing = false;
    }
  }

  public closeDialog(e?: Event, preventClose: boolean = false) {
    e?.preventDefault();

    if (preventClose) {
      return;
    }

    if (this.domainService.isProcessing) {
      this.notificationService.warning('Processing, please wait.');
      return;
    }

    this.actions.onClose();
  }

  /*
    Private Methods
  */

  private async init() {
    this.model = await this.domainService.getRecord(this.domainId);
    await this.userService.getRecords(this.domainId);
    await this.userService.getMore(this.domainId, true);

    if (this.pollTracker) {
      this.pollTracker.start();
    }
  }

  private processResponse(resp: SsoDomainUserUpdateResponse, setTarget: string, collection: ISearchableItem[]) {
    const {errors = {}} = resp;
    const ids = Object.keys(errors);

    if (!(this[setTarget] instanceof Set)) {
      throw new Error('Invalid target');
    }

    let tracked: Set<string> = new Set();

    if (ids.length > 0) {
      tracked = new Set(ids);

      const users = ids.reduce((d, id) => {
        const user = collection.find(u => u.value === id);
        d[id] = user.text;
        return d;
      }, {});

      const _errors = ids.map(id => `${users[id]} - ${errors[id]}`);

      if (tracked.size === this[setTarget].size) {
        this.notificationService.error(`Update failed: \n ${_errors.join('\n')}`);
      } else {
        this.notificationService.warning(`Completed with errors: \n ${_errors.join('\n')}`);
      }
    }

    this[setTarget] = tracked;
    this.pollTracker.manualPoll();
  }

  private reset() {
    this.records = [];
    this.model = null;
    this.trackedUsers = new Set();
    this.trackedOwners = new Set();

    this.ownerService.clear();

    if (this.pollTracker) {
      this.pollTracker.stop();
      this.pollTracker = null;
    }
  }

  private trackOwner(id: string) {
    this.trackedOwners = DomainUsers.trackRow(this.trackedOwners, id);
  }

  private trackUser(id: string) {
    this.trackedUsers = DomainUsers.trackRow(this.trackedUsers, id);
  }
}
