import {BindingEngine, Disposable} from 'aurelia-binding';

import {FieldValidation} from './field-validation';

export interface IFieldValidations {
  [key: string]: FieldValidation[];
}

export class FormValidator {
  public errors: any = {};
  private observers: Disposable[] = [];
  private fieldValidations: IFieldValidations = {};

  /**
   * @param model
   * @param scope
   */
  constructor(private model, private scope?) {}

  /**
   * Removes observers
   */
  public cleanup(): void {
    for (let i = 0; i < this.observers.length; i++) {
      const observer = this.observers[i];
      observer.dispose();
    }

    this.observers = [];

    this.fieldValidations = null;
    this.errors = {};
  }

  /**
   * Aggregated errors for property
   * @param property
   * @param joinBy String to use to join error messages together
   */
  public getErrorMsg(property: string): string {
    if (this.errors[property]) {
      return this.errors[property];
    }

    return null;
  }

  /**
   * validated state
   */
  public isValid(): boolean {
    const {fieldValidations} = this;

    if (fieldValidations) {
      return Object.keys(fieldValidations).every(key => this.getErrorMsg(key) === null);
    }

    return false;
  }

  /**
   * Registers field validations and optionally listen for changes
   * @param fieldValidations
   * @param bindingEngine
   */
  public register(
    fieldValidations: IFieldValidations = {},
    bindingEngine: BindingEngine = null,
    callback: () => boolean = null,
  ): void {
    Object.keys(fieldValidations).forEach(key => {
      this.setFieldValidation(key, fieldValidations[key], bindingEngine, callback);
    });
  }

  public setFieldValidation(key: string, validations: FieldValidation[], bindingEngine, callback) {
    const fieldValidation = {};
    fieldValidation[key] = validations || [];

    Object.assign(this.fieldValidations, fieldValidation);

    this.addValidationObserver(key, bindingEngine, callback);
  }

  /**
   * Runs all registered validations and returns state of validations
   */
  public validate(): boolean {
    const {fieldValidations} = this;
    const {model} = this;
    const {scope} = this;

    Object.keys(fieldValidations).forEach(key => {
      const validations: FieldValidation[] = fieldValidations[key];
      this.errors[key] = null;

      for (let i = 0; i < validations.length; i++) {
        const validation = validations[i];
        validation.validate(model, key, scope);

        if (this.assignError(validation, key)) {
          // We don't need to validate more than one error
          break;
        }
      }
    });

    return this.isValid();
  }

  private addValidationObserver(key: string, bindingEngine: BindingEngine, callback?: () => void) {
    if (!bindingEngine) {
      return;
    }

    const {model} = this;
    const {scope} = this;
    const {observers} = this;
    const {fieldValidations} = this;

    const o = bindingEngine.propertyObserver(model, key).subscribe(() => {
      const validations: FieldValidation[] = fieldValidations[key];
      this.errors[key] = null;

      validations.forEach(validation => {
        if (validation.passive) {
          return;
        }

        validation.validate(model, key, scope);

        this.assignError(validation, key);

        if (_.isFunction(callback)) {
          callback();
        }
      });
    });

    observers.push(o);
  }

  /**
   * Assigns errors to property
   * @param validation
   * @param key
   */
  private assignError(validation, key): boolean {
    const {errorMsg = ''} = validation;

    if (errorMsg && !this.errors[key]) {
      this.errors[key] = errorMsg;
      return true;
    }

    return false;
  }
}
