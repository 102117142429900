import {autoinject, computedFrom, observable} from 'aurelia-framework';
import * as DOMPurify from 'dompurify';
import {authorizationConstants} from 'services/authorization';
import {
  SyndicationHlsUriType,
  SyndicationPlatformType,
  SyndicationScheduleVideoQualityType,
  SyndicationScte35Types,
  SyndicationTargetProtocolType,
  SyndicationYouTubeIntegratedPrivacy,
} from 'services/models/syndication';

@autoinject()
@authorizationConstants
export class PublishTargetBody {
  public target: any;
  public controller: any;
  public keyValData = [];
  public vKeyValKey: string = '';
  public vKeyValValue: string = '';
  public keyValState: string = '';
  public keyValError: string = '';
  @observable public ytMadeForKidsRadio: string;
  public showTargetPrepareError: boolean = false;

  public srtEventListeners = {
    blur: () => {
      this.onSrtLatencyBlur();
    },
  };

  public activate(model) {
    this.target = model.shared;
    this.controller = model.controller;

    let ytMadeForKids = false;
    if (
      'target_platform_config' in this.target &&
      'integrated_yt_made_for_kids' in this.target.target_platform_config
    ) {
      ytMadeForKids = this.target.target_platform_config.integrated_yt_made_for_kids;
    }
    this.ytMadeForKidsRadio = `yt_made_for_kids_${ytMadeForKids}`;

    if (this.target.url_params_active) {
      this.initKeyValueParams();
    }
    this.showTargetPrepareError =
      this.target.target_info &&
      this.target.status === 'error' &&
      (this.target.target_info.platform === SyndicationPlatformType.YOUTUBE ||
        this.target.target_info.platform === SyndicationPlatformType.FACEBOOK ||
        this.target.target_protocol === SyndicationTargetProtocolType.ZIXI ||
        this.target.target_protocol === SyndicationTargetProtocolType.RTMP_PULL);
  }

  public initKeyValueParams() {
    if (!this.target.url_params_active) return;

    Object.keys(this.target.source_url_params).forEach(key => {
      this.vKeyValKey = key;
      this.vKeyValValue = this.target.source_url_params[key];
      this.addKeyValue(false);
    });
  }

  public removeKeyValue(key) {
    const index = this.keyValData.findIndex(x => x.key === key);
    if (index > -1) {
      this.keyValData.splice(index, 1);
      // to receive dict-changed events, have to clone deep (dicts are reference based)
      const keysValues = _.cloneDeep(this.target.source_url_params);
      delete keysValues[key];
      this.target.source_url_params = _.cloneDeep(keysValues);
    }
  }

  public addKeyValue(isNew) {
    if (!this.target.url_params_active) {
      return;
    }

    if (isNew && this.vKeyValKey in this.target.source_url_params) {
      this.keyValError = 'Duplicate key!';
      this.keyValState = 'error';
      return;
    }

    if (this.vKeyValValue === '' || this.vKeyValKey === '') {
      this.keyValError = 'Empty key/value';
      this.keyValState = 'error';
      return;
    }

    this.keyValState = '';
    this.keyValError = '';
    this.vKeyValKey = DOMPurify.sanitize(this.vKeyValKey);
    this.vKeyValValue = DOMPurify.sanitize(this.vKeyValValue);

    this.keyValData.push({
      action: 'Remove',
      actionButtonState: this.target.status === 'active' || this.target.status === 'scheduled' ? 'disabled' : 'enabled',
      key: this.vKeyValKey,
      value: this.vKeyValValue,
    });
    if (isNew) {
      this.target.source_url_params[this.vKeyValKey] = this.vKeyValValue;
      // to receive dict-changed events, have to clone deep (dicts are reference based)
      const keysValues = _.cloneDeep(this.target.source_url_params);
      keysValues[this.vKeyValKey] = this.vKeyValValue;
      this.target.source_url_params = _.cloneDeep(keysValues);
    }
    this.vKeyValKey = '';
    this.vKeyValValue = '';
  }

  @computedFrom('SyndicationScheduleVideoQualityType')
  get qualityOptions() {
    return _.map(SyndicationScheduleVideoQualityType, info => ({
      text: info.charAt(0).toUpperCase() + info.slice(1),
      value: info,
    }));
  }

  @computedFrom('target.rayDetail')
  get rayDetailAvailable() {
    return this.target.rayDetail !== undefined;
  }

  @computedFrom('target.rayDetail', 'target.video_quality')
  get rayDescription() {
    let value = '';
    if (this.target.rayDetail !== undefined) {
      if (this.target.video_quality === SyndicationScheduleVideoQualityType.HIGH) {
        value = `<span>${this.target.rayDetail.high.max_width}x${this.target.rayDetail.high.max_height}</span> • <span>${this.target.rayDetail.high.fps}fps</span> • <span>${this.rayVideo}</span> • <span>${this.target.rayDetail.high.audio_codec} Audio @ ${this.target.rayDetail.high.audio_kbps}Kbps</span>`;
      } else if (this.target.video_quality === SyndicationScheduleVideoQualityType.MEDIUM) {
        value = `<span>${this.target.rayDetail.medium.max_width}x${this.target.rayDetail.medium.max_height}</span> • <span>${this.target.rayDetail.medium.fps}fps</span> • <span>${this.rayVideo}</span> • <span>${this.target.rayDetail.medium.audio_codec} Audio @ ${this.target.rayDetail.medium.audio_kbps}Kbps</span>`;
      } else if (this.target.video_quality === SyndicationScheduleVideoQualityType.LOW) {
        value = `<span>${this.target.rayDetail.low.max_width}x${this.target.rayDetail.low.max_height}</span> • <span>${this.target.rayDetail.low.fps}fps</span> • <span>${this.rayVideo}</span> • <span>${this.target.rayDetail.low.audio_codec} Audio @ ${this.target.rayDetail.low.audio_kbps}Kbps</span>`;
      }
    }
    return value;
  }

  @computedFrom('target.rayDetail', 'target.video_quality')
  get rayResolution() {
    let value = '';
    if (this.target.rayDetail !== undefined) {
      if (this.target.video_quality === SyndicationScheduleVideoQualityType.HIGH) {
        value = this.rayResolutionDescription(this.target.rayDetail.high);
      } else if (this.target.video_quality === SyndicationScheduleVideoQualityType.MEDIUM) {
        value = this.rayResolutionDescription(this.target.rayDetail.medium);
      } else if (this.target.video_quality === SyndicationScheduleVideoQualityType.LOW) {
        value = this.rayResolutionDescription(this.target.rayDetail.low);
      }
    }
    return value;
  }

  @computedFrom('target.rayDetail', 'target.video_quality')
  get rayFPS() {
    let value = '';
    if (this.target.rayDetail !== undefined) {
      if (this.target.video_quality === SyndicationScheduleVideoQualityType.HIGH) {
        value = this.rayFPSDescription(this.target.rayDetail.high);
      } else if (this.target.video_quality === SyndicationScheduleVideoQualityType.MEDIUM) {
        value = this.rayFPSDescription(this.target.rayDetail.medium);
      } else if (this.target.video_quality === SyndicationScheduleVideoQualityType.LOW) {
        value = this.rayFPSDescription(this.target.rayDetail.low);
      }
    }
    return value;
  }

  @computedFrom('target.rayDetail', 'target.video_quality')
  get rayVideo() {
    let value = '';
    if (this.target.rayDetail !== undefined) {
      if (this.target.video_quality === SyndicationScheduleVideoQualityType.HIGH) {
        value = this.rayVideoDescription(this.target.rayDetail.high);
      } else if (this.target.video_quality === SyndicationScheduleVideoQualityType.MEDIUM) {
        value = this.rayVideoDescription(this.target.rayDetail.medium);
      } else if (this.target.video_quality === SyndicationScheduleVideoQualityType.LOW) {
        value = this.rayVideoDescription(this.target.rayDetail.low);
      }
    }
    return value;
  }

  @computedFrom('target.rayDetail', 'target.video_quality')
  get rayAudio() {
    let value = '';
    if (this.target.rayDetail !== undefined) {
      if (this.target.video_quality === SyndicationScheduleVideoQualityType.HIGH) {
        value = this.rayAudioDescription(this.target.rayDetail.high);
      } else if (this.target.video_quality === SyndicationScheduleVideoQualityType.MEDIUM) {
        value = this.rayAudioDescription(this.target.rayDetail.medium);
      } else if (this.target.video_quality === SyndicationScheduleVideoQualityType.LOW) {
        value = this.rayAudioDescription(this.target.rayDetail.low);
      }
    }
    return value;
  }

  get showAllVideos() {
    return (
      this.target.target_protocol === SyndicationTargetProtocolType.HLS_PUSH ||
      this.target.target_protocol === SyndicationTargetProtocolType.HLS_PULL
    );
  }

  @computedFrom('target.rayDetail')
  get getAllRays() {
    if (this.target.rayDetail === undefined) {
      return [];
    }
    return _.map(this.target.rayDetail.all, ray => ({
      id: ray.ray_name,
      label: `${this.rayResolutionDescription(ray)} • ${this.rayFPSDescription(ray)}fps •
                ${this.rayVideoDescription(ray)} • ${this.rayAudioDescription(ray)}`,
      value:
        this.target.target_platform_config.rays.length === 0 ||
        this.target.target_platform_config.rays.includes(ray.ray_name),
    }));
  }

  public onRayCheckedChange(ray, isChecked) {
    ray.value = isChecked;

    const rays = _.cloneDeep(this.target.target_platform_config.rays);
    if (rays.includes(ray.id)) {
      const idx = rays.indexOf(ray.id);
      if (idx !== -1) {
        rays.splice(idx, 1);
      }
    } else {
      rays.push(ray.id);
    }
    rays.sort();
    this.target.target_platform_config.rays = _.cloneDeep(rays);
  }

  get showChannelIdInput() {
    return this.target.hls_uri_type === SyndicationHlsUriType.CHANNEL_ID;
  }

  public rayVideoDescription(ray) {
    let value = '';
    if (ray !== undefined) {
      if (ray.video_kbps >= 1000) {
        const speed = (ray.video_kbps / 1000).toFixed(2);
        value = `${ray.video_codec} Video @ ${speed}Mbps`;
      } else {
        value = `${ray.video_codec} Video @ ${ray.video_kbps}Kbps`;
      }
    }
    return value;
  }

  public rayAudioDescription(ray) {
    return `${ray.audio_codec} Audio @ ${ray.audio_kbps}Kbps`;
  }

  public rayResolutionDescription(ray) {
    return `${ray.max_width}x${ray.max_height}`;
  }

  public rayFPSDescription(ray) {
    return `${ray.fps}`;
  }

  @computedFrom('SyndicationYouTubeIntegratedPrivacy')
  get youTubeIntegratedPrivacyOptions() {
    return _.map(SyndicationYouTubeIntegratedPrivacy, info => ({
      text: info.charAt(0).toUpperCase() + info.slice(1),
      value: info,
    }));
  }

  public async resetStatusError() {
    const success = await this.target.resetSchedule(this.target.id);
    if (success) {
      this.controller.cancel();
    }
  }

  @computedFrom('SyndicationScte35Types')
  get scte35Types() {
    const s35options = [
      {
        text: 'DATERANGE',
        value: SyndicationScte35Types.DATERANGE,
      },
      {
        text: 'CUE-OUT / IN',
        value: SyndicationScte35Types.CUE,
      },
    ];
    return s35options.filter(
      value =>
        !(
          this.target.target_info.platform === SyndicationPlatformType.WURL &&
          value.value !== SyndicationScte35Types.CUE
        ),
    );
  }

  public ytMadeForKidsRadioChanged(newVal: string) {
    if (
      'target_platform_config' in this.target &&
      'integrated_yt_made_for_kids' in this.target.target_platform_config
    ) {
      this.target.target_platform_config.integrated_yt_made_for_kids = newVal.split('_').pop() === 'true';
    }
  }

  public onSrtLatencyBlur() {
    let srtLatency: any = `${this.target.target_platform_config.srt_latency}`;
    srtLatency = srtLatency.replace(/\D/g, '');
    srtLatency = parseInt(srtLatency, 10);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(srtLatency) || srtLatency < 120 || srtLatency > 15000) {
      srtLatency = 500;
    }
    this.target.target_platform_config.srt_latency = `${srtLatency}`;
  }
}
