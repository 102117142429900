import {Expose, Type} from 'class-transformer';
import {IsInt, IsOptional, IsString, IsUrl, Length, ValidateNested} from 'class-validator';

export class PrebidConfig {
    @IsString()
    public id: string;

    @IsString()
    @Length(3, 50)
    public name: string;

    @Expose({name: 'includebrandcategory.publisher'})
    @IsOptional()
    @IsString()
    public includeBrandCategoryPublisher: string;

    @Expose({name: 'podconfig.configid'})
    @Length(1, 100)
    @IsString()
    public podConfigConfigid: string;

    @Expose({name: 'pricegranularity.range.increment'})
    @IsOptional()
    @IsString()
    public priceGranularityRangeIncrement: string;

    @Expose({name: 'pricegranularity.range.max'})
    @IsOptional()
    @IsString()
    public priceGranularityRangeMax: string;

    @IsString()
    @IsUrl()
    public url: string;

    @Expose({name: 'storedrequestid'})
    @IsOptional()
    @IsString()
    public storedRequestId: string;

    @Expose({name: 'site.page'})
    @IsOptional()
    @IsString()
    public sitePage: string;

    @Expose({name: 'site.publisher'})
    @IsOptional()
    @IsString()
    public sitePublisher: string;

    @Expose({name: 'app.bundle'})
    @IsOptional()
    @IsString()
    public appBundle: string;

    @Expose({name: 'app.domain'})
    @IsOptional()
    @IsString()
    public appDomain: string;

    @Expose({name: 'app.name'})
    @IsOptional()
    @IsString()
    public appName: string;

    @Expose({name: 'app.publisher'})
    @IsOptional()
    @IsString()
    public appPublisher: string;

    @Expose({name: 'app.store_url'})
    @IsOptional()
    @IsString()
    public appStoreUrl: string;
}

export class PrebidConfigCollection {
    @ValidateNested()
    @Type(() => PrebidConfig)
    public items: PrebidConfig[];

    @IsInt()
    public total_items: number;
}
