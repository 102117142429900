import * as CryptoJS from 'crypto-js';
import {computedFrom, inject, LogManager} from 'aurelia-framework';

import {CmsHttpClient} from 'services/cms-http-client';

import {Notification} from 'resources/notification/service';

import {SourceViewMessage} from '../pubsub/source-view';

const logger = LogManager.getLogger('live-event-slicer');

// times in milliseconds
const CONNECT_TIMEOUT = 3000; // timeout of status calls to slicers
const SLICER_API_TIMEOUT = 5000; // timeout for calls to the slicer
const STATUS_CHECK_TIME = 5000; // check connected slicer every x ms
const STATE_CHECK_TIME = 1000; // check slicer state every x ms
const SLOW_CONNECTION_TIME = 1500; // if a api takes longer than x ms, mark it as slow connection
const UPDATE_THUMBNAIL_INTERVAL = 3000; // Interval for getting preview thumbnails

@inject(CmsHttpClient, Notification)
export class SlicerService {
  constructor(cmsHttpClient, notification, acceo) {
    this.httpClient = cmsHttpClient.httpClient;
    this.notification = notification;
    this.acceo = acceo;

    this.SLICER_STATUS = {
      attempting: {
        class: 'neutral',
        message: 'Connecting...',
        icon: 'spinner',
      },
      connected: {
        class: 'healthy',
        message: 'Connected',
        icon: 'checkmark',
      },
      slow: {
        class: 'warning',
        message: 'Slow Connection',
        icon: 'warning',
      },
      mismatch: {
        class: 'warning',
        message: 'Mis-Matched ID',
        icon: 'warning',
      },
      failed: {
        class: 'critical',
        message: 'Failed to Connect',
        icon: 'ban',
      },
      oldVersion: {
        class: 'warning',
        message: 'Old Slicer Version',
        icon: 'warning',
      },
    };

    this.backOffDelays = {};

    this.SLICERSTATES = {
      UNKNOWN: -1,
      SOURCE: 0,
      AD: 1,
      BLACKOUT: 3,
    };

    this._lastSlicerState = {
      // Used to determine switcher state -
      state: null,
      time: null,
    };

    this.thumbInterval = null;
    this.activeStateInterval = null;
    this.slicerStatusCalls = [];

    this.logThrottle = _.throttle((event, msg) => this.addLogEvent(event, msg), 30000, {trailing: false});
    this.notiThrottle = _.throttle(
      msg => {
        this.notification.error(msg, 'HTTPS Error', 15000);
      },
      30000,
      {trailing: false},
    );
    this.retryThrottle = _.throttle(
      (slicer, event) => {
        this.retryConnect(slicer, event);
      },
      10000,
      {trailing: false},
    );
  }

  generateAuthSig(authToken, route, asObject = false) {
    const cnonce = Math.floor(Math.random() * 999999);
    const timestamp = Math.floor(new Date().getTime() / 1000);
    let sig = `${route}:${timestamp}:${cnonce}:${authToken}`;
    sig = CryptoJS.enc.Base64.stringify(CryptoJS.SHA1(sig));
    if (asObject) {
      return {cnonce, timestamp, sig};
    }
    sig = sig.replace(/=/g, ',').replace(/\+/g, '-').replace(/\//g, '_');
    return `cnonce=${cnonce}&timestamp=${timestamp}&sig=${sig}`;
  }

  slicersApi(event, call, method = 'POST', params = {}, timeout = SLICER_API_TIMEOUT) {
    let rval = null;
    let data = null;
    const errMsg = 'Could not communicate with primary slicer';
    const errMsgBackup = 'Could not communicate with backup slicer';
    let modParams = params;

    if (call === '/replace_pod' || call === '/pod_start') {
      if (event.breakSeq > -1) {
        if (!modParams.meta) {
          modParams.meta = {};
        }
        event.breakSeq += 1;
        modParams.meta.breakSeq = event.breakSeq;
      }
    }

    event.slicers.forEach(slicer => {
      if (!slicer || !slicer.apiAddress) {
        return;
      }

      let url = `${slicer.apiAddress}${call}`;

      if (call === '/content_start') {
        // Don't allow Content Start if we aren't Live
        if (!event.isLive) {
          return;
        }
        const hasMetaProperty = Object.prototype.hasOwnProperty.call(modParams, 'meta');
        // Sets the desc on the asset created by this call to match the event's
        if (hasMetaProperty) {
          modParams.meta.__upl_event_title = slicer.active ? 'Primary' : 'Backup';
          if (event.isTesting) {
            modParams.meta.__upl_event_title = `Test ${modParams.meta.__upl_event_title}`;
          }
        }
      }

      if (method === 'POST') {
        if (!modParams.meta) {
          modParams.meta = {};
        }
        modParams.meta.__upl_event_id = event.event.id;
        modParams = Object.assign(modParams, this.generateAuthSig(event.authToken, call, true));
        data = JSON.stringify(modParams);
      } else {
        url += `?${this.generateAuthSig(event.authToken, call)}&__event_id=${event.event.id}`;
      }

      const jqPromise = $.ajax(url, {method, data, timeout}).fail(() => {
        if (slicer.active) {
          this.logThrottle(event.event.id, `${errMsg}: ${slicer.id}`);
        } else {
          this.logThrottle(event.event.id, `${errMsgBackup}: ${slicer.id}`);
        }
      });
      if (slicer.active) {
        rval = jqPromise;
      }
    });

    if (!rval) {
      rval = $.Deferred();
      rval.reject({responseJSON: `${errMsg}.`});
    }

    return rval;
  }

  addLogEvent(eventId, msg) {
    this.httpClient.fetch(`/live-event/${eventId}/add-log`, {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({event: msg}),
    });
  }

  checkHTTPSTimeDifference(err) {
    if (err && err.responseText && err.responseText.indexOf('timestamp too different from current time') > -1) {
      this.notiThrottle('Authentication over HTTPS failed due to time difference with slicer');
    }
  }

  activeSlicerApi(event, call, method = 'POST', params = {}, timeout = SLICER_API_TIMEOUT) {
    let data = null;
    let modParams = params;

    const slicerIndex = event.activeSlicerIndex;
    const slicer = event.slicers[slicerIndex];
    if (slicer.apiAddress == null) {
      return $.Deferred().reject(new Error('Could not find an active slicer.'));
    }

    let url = `${slicer.apiAddress}${call}`;
    modParams.callback = `${Math.floor(Date.now())}L`;
    if (method === 'POST') {
      modParams = Object.assign(modParams, this.generateAuthSig(event.authToken, call, true));
      data = JSON.stringify(modParams);
    } else {
      url += `?${this.generateAuthSig(event.authToken, call)}`;
    }

    const jqPromise = $.ajax(url, {
      method,
      data,
      timeout,
      dataType: 'text',
    });

    return jqPromise;
  }

  replacePod(event, adPod) {
    return this.slicersApi(event, '/replace_pod', 'POST', adPod);
  }

  prefetchPod(event, adPod) {
    return this.slicersApi(event, '/prefetch_pod', 'POST', adPod);
  }

  podStart(event) {
    return this.slicersApi(event, '/pod_start', 'POST');
  }

  podEnd(event) {
    return this.slicersApi(event, '/pod_end', 'POST');
  }

  contentStart(event, correlator) {
    return this.slicersApi(event, '/content_start', 'POST', {
      meta: {__upl_correlator: correlator},
    });
  }

  blackout(event) {
    return this.slicersApi(event, '/blackout');
  }

  getActiveState(event) {
    return this.activeSlicerApi(event, '/state');
  }

  @computedFrom('_lastSlicerState')
  get lastSlicerState() {
    return this._lastSlicerState;
  }

  set lastSlicerState(newValue) {
    this._lastSlicerState = newValue;
  }

  ParseState(data) {
    let _data = {};
    const _dataArray = data.split('L');
    if (_dataArray.length === 2) {
      _dataArray[1] = _dataArray[1].replace('(', '');
      _dataArray[1] = _dataArray[1].replace(');', '');
      _data = JSON.parse(_dataArray[1]);
      _data.timestamp = Number(_dataArray[0]);
    }

    return _data;
  }

  pollActiveSlicerState(event) {
    if (this.activeStateInterval) {
      this.stopPollActiveState();
    }

    this.activeStateInterval = setInterval(() => {
      this.getActiveState(event)
        .then(data => {
          const parsedData = this.ParseState(data);
          if (parsedData.error || parsedData.state === undefined) {
            logger.error('Error getting active slicer state', parsedData);
          } else if (
            parsedData.state != null &&
            parsedData.timestamp &&
            this.lastSlicerState.state !== parsedData.state &&
            this.lastSlicerState.time < parsedData.timestamp
          ) {
            logger.info('#pollActiveSlicerState', {state: parsedData.state, time: Math.floor(Date.now())});
            this.lastSlicerState = {state: parsedData.state, time: Math.floor(Date.now())};
          } else if (
            parsedData.ad_prefetch_waiting !== null &&
            parsedData.ad_prefetch_waiting === 1 &&
            parsedData.ad_prefetch_remaining !== this.lastSlicerState.ad_prefetch_remaining
          ) {
            this.lastSlicerState.ad_prefetch_remaining = parsedData.ad_prefetch_remaining;
          } else if (this.lastSlicerState.ad_prefetch_remaining > 0) {
            this.lastSlicerState.ad_prefetch_remaining = 0;
          }
        })
        .fail(err => {
          // warn level currently has issues.
          logger.error('Could not get active slicer state', err);
          this.checkHTTPSTimeDifference(err);
        });
    }, STATE_CHECK_TIME);
  }

  stopPollActiveState() {
    clearInterval(this.activeStateInterval);
    this.activeStateInterval = null;
  }

  retryConnect(slicer, event) {
    if (slicer && slicer.ips) {
      slicer.ips.forEach(ip => {
        ip.status = this.SLICER_STATUS.attempting;
        this.getSlicerStatus(event, ip, slicer).then(() => {
          this.setSlicerStatus(event, slicer);
        });
      });
    }
  }

  getSlicerStatus(event, ip, slicer, modal) {
    const slicerKey = ip.address + slicer.id;
    clearTimeout(this.slicerStatusCalls[slicerKey]);

    const route = '/status';
    const url = `http${ip.ssl ? 's' : ''}://${ip.address}:${ip.port}`;
    const ipPort = `${ip.address}:${ip.port}`;
    const endpoint = `${url}${route}?${this.generateAuthSig(event.authToken, route)}`;

    if (this.backOffDelays[ipPort] === undefined) {
      this.backOffDelays[ipPort] = {};
    }

    const ajaxTime = new Date().getTime();
    return $.ajax(endpoint, {
      timeout: CONNECT_TIMEOUT,
    })
      .done(resp => {
        const doneTime = new Date().getTime() - ajaxTime;
        let newStatus = this.SLICER_STATUS.connected;
        if (resp.error || resp.status === undefined) {
          ip.status = this.SLICER_STATUS.failed;
          this.setSlicerStatus(event, slicer);
        } else {
          if (slicer.id !== resp.status.slicer_id) {
            newStatus = this.SLICER_STATUS.mismatch;
            slicer.mismatchId = resp.status.slicer_id;
          }

          if (modal) {
            modal.type = newStatus.class;
          }

          if (doneTime > SLOW_CONNECTION_TIME) {
            ip.status = this.SLICER_STATUS.slow;
          } else {
            ip.status = newStatus;
            if (!slicer.apiAddress) {
              slicer.apiAddress = url;
              slicer.active_interface = ip.interface;
            }
          }

          this.setSlicerStatus(event, slicer);
          if (resp.status.version !== undefined) {
            slicer.version = resp.status.version.trim();
            if (slicer.version < this.minimumSlicerVersion) {
              slicer.status = this.SLICER_STATUS.oldVersion;
              const warning = {
                slicerId: slicer.id,
                version: slicer.version,
                minimum: this.minimumSlicerVersion,
              };
              let found = false;
              this.versionWarnings.forEach(vw => {
                if (vw.slicerId === slicer.id) {
                  found = true;
                }
              });
              if (!found) {
                this.versionWarnings.push(warning);
              }
            }
            if (slicer.version < event.minimumSlicerVersionAdPrefetch) {
              // Set this if you want to disable the slicer from working.
              // With talking with Keith, we don't want to disable.  We'll
              // leave it enabled, BUT adPrefetch will not work and will
              // revert to CUE (intead of PRE) during POD usage.
              // slicer.status = this.SLICER_STATUS.oldVersion;
              const warning = {
                slicerId: slicer.id,
                version: slicer.version,
                minimum: event.minimumSlicerVersionAdPrefetch,
              };
              let found = false;
              event.versionWarningsAdPrefetch.forEach(vw => {
                if (vw.slicerId === slicer.id) {
                  found = true;
                }
              });
              if (!found) {
                event.versionWarningsAdPrefetch.push(warning);
              }
            }
            if (slicer.version >= event.minimumSlicerVersionPreviewLiveAudio) {
              slicer.livePreviewAudioEnabled =
                resp.status.livepreview_with_audio !== undefined && resp.status.livepreview_with_audio === 1;
            } else {
              slicer.livePreviewAudioEnabled = false;
            }
          }
        }

        // Check status again soon
        // But only needed for one IP per slicer
        if (!event.isEnded() && slicer.apiAddress === url) {
          // this resets the time in case of failure
          this.backOffDelays[ipPort][slicer.id] = STATUS_CHECK_TIME;
          this.slicerStatusCalls[slicerKey] = setTimeout(() => {
            this.getSlicerStatus(event, ip, slicer, null);
          }, STATUS_CHECK_TIME);
        }
      })
      .fail(err => {
        ip.status = this.SLICER_STATUS.failed;
        this.setSlicerStatus(event, slicer);
        this.checkHTTPSTimeDifference(err);
        if (slicer.status !== this.SLICER_STATUS.connected && slicer.status !== this.SLICER_STATUS.slow) {
          // eslint-disable-next-line max-len
          this.backOffDelays[ipPort][slicer.id] = (this.backOffDelays[ipPort][slicer.id] || STATUS_CHECK_TIME) * 1.5;

          // exponential back-off
          if (!event.isEnded()) {
            this.slicerStatusCalls[slicerKey] = setTimeout(() => {
              this.getSlicerStatus(event, ip, slicer, null);
            }, this.backOffDelays[ipPort][slicer.id]);
          }
        }
      });
  }

  setSlicerStatus(event, slicer) {
    slicer.ips.some(ip => {
      if (ip.status === this.SLICER_STATUS.connected || ip.status === this.SLICER_STATUS.slow) {
        if (slicer.status !== this.SLICER_STATUS.oldVersion) {
          slicer.status = ip.status;
        }
        slicer.apiAddress = `http${ip.ssl ? 's' : ''}://${ip.address}:${ip.port}`;
        slicer.active_interface = ip.interface;

        event.allowEnter = true;
        this.updateHealth(event, slicer);
        this.updateConnectedSlicerCount(event);
        if (event.sourceViewEnabled) {
          if (this.slicerConnected(event)) {
            event.eventAggregator.publish(new SourceViewMessage('connect'));
          }
        }
        return true;
      }
      if (slicer.status !== this.SLICER_STATUS.mismatch && slicer.status !== this.SLICER_STATUS.oldVersion) {
        slicer.status = ip.status;
      }

      if (ip.status === this.SLICER_STATUS.attempting && slicer.status !== this.SLICER_STATUS.oldVersion) {
        slicer.status = this.SLICER_STATUS.attempting;
      }
      return false;
    });

    slicer.ips.forEach(ip => {
      if (ip.interface !== slicer.active_interface) {
        ip.status = this.SLICER_STATUS.failed;
      }
    });

    this.updateHealth(event, slicer);
    this.updateConnectedSlicerCount(event);
  }

  updateHealth(event, slicer) {
    // if event has ended, set health to 'neutral'
    if (
      [
        event.STATES.post,
        event.STATES.complete,
      ].includes(event.state)
    ) {
      event.activeSlicerHealth = 'neutral';
      return;
    }
    if (slicer.active) {
      event.activeSlicerHealth = slicer.status.class;
    }
  }

  updateConnectedSlicerCount(event) {
    let count = 0;
    event.slicers.forEach(slicer => {
      if (slicer.status === this.SLICER_STATUS.connected || slicer.status === this.SLICER_STATUS.slow) {
        count += 1;
      }
    });
    event.connectedSlicerCount = count;

    // If we lose connection to all - during enter event page - don't allow entering
    if (event.connectedSlicerCount === 0) {
      event.allowEnter = false;
    }
  }

  // Determine if the current slicer is connected - based on slicer connections
  slicerConnected(event) {
    try {
      return (
        event.slicers[event.activeSlicerIndex].status === this.SLICER_STATUS.connected ||
        event.slicers[event.activeSlicerIndex].status === this.SLICER_STATUS.slow
      );
    } catch (e) {
      return false;
    }
  }

  getThumbnails(event) {
    if (this.thumbInterval) {
      clearInterval(this.thumbInterval);
    }

    if (event.slicers) {
      event.slicers.forEach(slicer => {
        if (
          slicer.status === this.SLICER_STATUS.connected ||
          slicer.status === this.SLICER_STATUS.slow ||
          slicer.status === this.SLICER_STATUS.oldVersion
        ) {
          const url = `${slicer.apiAddress}/preview/genthumb?h=80&w=80`;
          this.convertImgToData(url, base64Img => {
            // Check to see if we got back image data ('data:image/jpeg;base64,/9j/...')
            if (base64Img.indexOf('image/jpeg') > -1) {
              slicer.thumbnailSrc = base64Img;
            } else {
              slicer.thumbnailSrc = '';
            }
          });
        } else {
          slicer.thumbnailSrc = '';
        }
      }, this);

      this.thumbInterval = setTimeout(() => {
        if (!event.isEnded()) {
          this.getThumbnails(event);
        }
      }, UPDATE_THUMBNAIL_INTERVAL);
    }
  }

  convertImgToData(url, callback) {
    // see http://jsfiddle.net/handtrix/yvq5y/
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.send();
  }
}
