import {autoinject} from 'aurelia-framework';
import {
  SyndicationHlsUriType,
  SyndicationPlatformType,
  SyndicationTarget,
  SyndicationTargetProtocolType,
} from 'services/models/syndication';
import {SyndicationTargetService} from 'services/syndication-target-service';
import {CToastsService} from '@bindable-ui/bindable';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';
import {DialogService} from 'aurelia-dialog';
import {TableColumn} from 'utils/table-sorting-tools';

@autoinject()
export class SyndicationTargetsList {
  public isLoading: boolean = false;
  public isLoadingMore: boolean = false;
  public loadingError: boolean = false;
  public selectedTargets: any[] = [];
  public target;
  public targets: SyndicationTarget[] = [];
  public targetModel: any = {};

  public tableColumns = [
    new TableColumn('Name', 'description'),
    new TableColumn('Platform', 'platform'),
    new TableColumn('Protocol', 'target_protocol'),
  ];

  constructor(
    public router: Router,
    public dialogService: DialogService,
    public syndicationTargetService: SyndicationTargetService,
    public notification: CToastsService,
  ) {
    this.router = router;
  }

  public async activate() {
    this.syndicationTargetService.updateParams({page: 1});
    this.isLoading = true;
    this.loadTargets(true);
  }

  public detached() {
    this.syndicationTargetService.params = {};
  }

  public async loadTargets(overrideTargets) {
    try {
      const ans = await this.syndicationTargetService.getSyndicationTargets(overrideTargets);
      if (ans) {
        this.targets = _.cloneDeep(this.syndicationTargetService.syndicationTargets);
      }
    } catch (error) {
      this.loadingError = true;
    } finally {
      this.isLoading = false;
      this.isLoadingMore = false;
    }
  }

  public openTarget(target) {
    this.target = target;
    this.targetModel = {
      audio_track: '',
      description: '',
      onDone: model => this.saveTarget(model),
      platform: SyndicationPlatformType.TWITCH,
      platform_config: {port: 2088},
      ray: 'a',
      status: '',
      syndication_target: '',
      target_protocol: 'rtmp',
      target_stream: '',
    };

    // for edit
    if (target) {
      this.targetModel.id = target.id;
      this.targetModel.syndication_target = target.syndication_target;
      this.targetModel.description = target.description;
      this.targetModel.platform = target.platform;
      this.targetModel.platform_config = target.platform_config || {};
      this.targetModel.target_protocol = target.target_protocol;
      this.targetModel.target_protocol_pristine = target.target_protocol;
      this.targetModel.target_stream = target.target_stream;
    }

    this.dialogService.open({
      model: {
        bodyViewModel: PLATFORM.moduleName('apps/cms/routes/settings/publishing/targets/details/index'),
        footerEnable: true,
        footerText: 'footer',
        footerViewModel: PLATFORM.moduleName('apps/cms/routes/settings/publishing/targets/details/savebar'),
        sharedModel: this.targetModel,
        size: 'medium',
        title: target ? `${target.description}` : 'Add Publishing Target',
      },
      viewModel: PLATFORM.moduleName('@bindable-ui/bindable/components/modal/c-modal/c-modal'),
    });
  }

  public async deleteTargets() {
    this.selectedTargets = await Promise.filter(this.selectedTargets, async targetId => {
      const target = _.find(this.targets, {id: targetId});
      if (target.status === 'active') {
        this.notification.error(`Unable to delete active target ${target.description}`);
      } else {
        try {
          await this.syndicationTargetService.deleteTarget(targetId);
          return false;
        } catch (e) {
          return true;
        }
      }
      return false;
    });
    this.targets = _.cloneDeep(this.syndicationTargetService.syndicationTargets);
  }

  public async saveTarget(model) {
    await this.validateTarget(model);
    if (
      model.error.description ||
      model.error.stream ||
      model.error.platform_config.host ||
      model.error.platform_config.port ||
      model.error.platform_config.stream_id
    ) {
      return false;
    }

    if (
      model.platform === SyndicationPlatformType.FACEBOOK &&
      model.platform_config.integrated &&
      (model.error.platform_config.clipping_profile_id || model.error.platform_config.page_id)
    ) {
      return false;
    }

    if (
      model.platform === SyndicationPlatformType.YOUTUBE &&
      model.platform_config.integrated &&
      model.error.platform_config.clipping_profile_id
    ) {
      return false;
    }

    const target = new SyndicationTarget();
    if (model.id) {
      target.id = model.id;
      if (model.target_stream === '******') {
        delete model.target_stream;
      }
    }

    target.platform = model.platform;
    target.target_protocol = model.target_protocol;
    target.target_stream = model.target_stream;
    target.description = model.description;

    if (
      target.target_protocol === SyndicationTargetProtocolType.ZIXI ||
      target.platform === SyndicationPlatformType.HULU ||
      target.platform === SyndicationPlatformType.FACEBOOK ||
      target.platform === SyndicationPlatformType.YOUTUBE ||
      target.target_protocol === SyndicationTargetProtocolType.HLS_PULL
    ) {
      target.platform_config = model.platform_config;
    }
    try {
      await this.syndicationTargetService.saveSyndicationTarget(target);
      this.targets = _.cloneDeep(this.syndicationTargetService.syndicationTargets);
    } catch (e) {
      return false;
    }
    return true;
  }

  public validateURI(model) {
    return (
      !model.id ||
      (model.id && model.target_stream !== '******') ||
      model.target_protocol !== model.target_protocol_pristine
    );
  }

  public onlyStreamKeyPlatform(model) {
    return [
      SyndicationPlatformType.FACEBOOK,
      SyndicationPlatformType.YOUTUBE,
      SyndicationPlatformType.TWITCH,
      SyndicationPlatformType.TIKTOK,
    ].includes(model.platform);
  }

  public async validateTarget(model) {
    model.error = {
      platform_config: {},
    };
    let streamRequired = 'Stream URI is required.';
    if (this.onlyStreamKeyPlatform(model)) {
      streamRequired = 'Stream Key is required.';
    }
    // eslint-disable-next-line prefer-regex-literals
    const rtmpRegex = new RegExp(/^(rtmp?|rtmps):\/\/[^\s$.?#].[^\s]*$/);

    if (!model.description) {
      model.error.description = 'Target Name is required.';
    }

    if (model.platform === SyndicationPlatformType.FACEBOOK && model.platform_config.integrated) {
      if (!model.platform_config.clipping_profile_id) {
        model.error.platform_config.clipping_profile_id = 'Clipping profile is required.';
      } else if (!model.platform_config.page_id) {
        model.error.platform_config.page_id = 'Facebook Page is required.';
      }
      return;
    }

    if (model.platform === SyndicationPlatformType.YOUTUBE && model.platform_config.integrated) {
      if (!model.platform_config.clipping_profile_id) {
        model.error.platform_config.clipping_profile_id = 'Clipping profile is required.';
      }
      return;
    }

    if (model.target_protocol === SyndicationTargetProtocolType.ZIXI) {
      if (!model.platform_config.host) {
        model.error.platform_config.host = 'Host is required.';
      }
      if (!model.platform_config.port) {
        model.error.platform_config.port = 'Port is required and must be greater than 0.';
      }
      if (!model.platform_config.stream_id) {
        model.error.platform_config.stream_id = 'Stream ID is required.';
      }
      return;
    }

    if (
      model.target_protocol === SyndicationTargetProtocolType.HLS_PUSH ||
      model.platform === SyndicationPlatformType.HULU
    ) {
      if (!model.target_stream) {
        model.error.stream = streamRequired;
      } else if (
        model.target_stream &&
        this.validateURI(model) &&
        model.target_stream.toLowerCase().substring(0, 8) !== 'https://' &&
        model.target_stream.toLowerCase().substring(0, 7) !== 'http://'
      ) {
        model.error.stream = 'Stream URI must start with http:// or https://';
      } else if (model.target_stream && this.validateURI(model) && !model.target_stream.endsWith('/')) {
        model.error.stream = 'Stream URI must end with a trailing /';
      }

      if (
        model.platform === SyndicationPlatformType.HULU &&
        (!model.id || (model.id && model.target_stream !== '******'))
      ) {
        // check and see if <hulu_channel_id> is in the URI
        model.platform_config.hls_uri_type =
          model.target_stream.indexOf('<hulu_channel_id>') === -1
            ? SyndicationHlsUriType.NONE
            : SyndicationHlsUriType.CHANNEL_ID;
      }
      return;
    }

    if (
      model.target_protocol === SyndicationTargetProtocolType.HLS_PULL ||
      model.target_protocol === SyndicationTargetProtocolType.RTMP_PULL
    ) {
      // nothing to verify at the moment, just an empty if to avoid validating the stream_uri
      return;
    }

    if (model.target_protocol === SyndicationTargetProtocolType.SRT) {
      if (!model.target_stream) {
        model.error.stream = streamRequired;
      } else if (
        model.target_stream &&
        this.validateURI(model) &&
        model.target_stream.toLowerCase().substring(0, 6) !== 'srt://'
      ) {
        model.error.stream = 'Stream URI must start with srt://';
      }

      return;
    }

    if (model.target_protocol === SyndicationTargetProtocolType.RIST) {
      if (!model.target_stream) {
        model.error.stream = streamRequired;
      } else if (this.validateURI(model) && model.target_stream.toLowerCase().substring(0, 7) !== 'rist://') {
        model.error.stream = 'Stream URI must start with rist://';
      } else if (
        this.validateURI(model) &&
        Number(model.target_stream.slice(model.target_stream.lastIndexOf(':') + 1)) % 2 !== 0
      ) {
        model.error.stream = 'RIST port must be an even number.';
      }

      return;
    }

    if (!model.target_stream) {
      model.error.stream = streamRequired;
      return;
    }

    if (this.onlyStreamKeyPlatform(model) && this.validateURI(model) && rtmpRegex.test(model.target_stream)) {
      model.error.stream = 'Stream Key should not be a full URI.';
      return;
    }

    if (!this.onlyStreamKeyPlatform(model)) {
      if (this.validateURI(model) && !rtmpRegex.test(model.target_stream)) {
        model.error.stream = 'Stream URI is not valid.';
      } else if (
        this.validateURI(model) &&
        model.target_protocol === SyndicationTargetProtocolType.RTMP &&
        model.target_stream.toLowerCase().substring(0, 5) !== `${SyndicationTargetProtocolType.RTMP}:`
      ) {
        model.error.stream = 'Stream URI must start with rtmp://';
      } else if (
        this.validateURI(model) &&
        model.target_protocol === SyndicationTargetProtocolType.RTMPS &&
        model.target_stream.toLowerCase().substring(0, 6) !== `${SyndicationTargetProtocolType.RTMPS}:`
      ) {
        model.error.stream = 'Stream URI must start with rtmps://';
      }
    }
  }

  public rowClickHandler(target) {
    this.openTarget(target);
  }

  public toggleTargetSelected(target) {
    target.checkbox = !target.checkbox;
    this.trackSelectedEvent(target);
  }

  public trackSelectedEvent(target) {
    if (target.checkbox) {
      this.selectedTargets.push(target.id);
    } else {
      _.remove(this.selectedTargets, targetId => targetId === target.id);
    }

    this.selectedTargets = _.uniq(this.selectedTargets);
  }

  public toggleSelectAll(isSelected: boolean) {
    _.forEach(this.targets, target => {
      target.checkbox = isSelected;
    });

    this.selectedTargets = _.map(
      _.filter(this.targets, target => target.checkbox),
      target => target.id,
    );
  }

  public async loadMoreTargets() {
    if (this.isLoading || this.isLoadingMore || !this.syndicationTargetService.meta.hasMore) {
      return;
    }

    this.isLoadingMore = true;
    this.syndicationTargetService.getMoreTargets();
    await this.loadTargets(false);
  }
}
