import {autoinject, LogManager} from 'aurelia-framework';
import {Logger} from 'aurelia-logging';

import {Acceo} from 'services/acceo';

import {Owner, OwnerResponse} from '../models/owner';

export const BASE_URL = '/ops/owners';

@autoinject()
export class OwnerService {
  public isLoading: boolean = false;
  public records: Owner[];

  private logger: Logger;

  constructor(protected acceo: Acceo) {
    this.logger = LogManager.getLogger('Owner Service');
    this.records = [];
  }

  public clear() {
    this.records = [];
  }

  public async list(username: string): Promise<void> {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    try {
      const url = `${BASE_URL}?username=${username}`;
      const {owners = []} = await this.acceo.get(OwnerResponse)(url);

      this.records = owners;
    } catch (err) {
      this.logger.error(err.message);
      throw err;
    } finally {
      this.isLoading = false;
    }
  }
}
