import {autoinject, computedFrom, LogManager} from 'aurelia-framework';
import {Logger} from 'aurelia-logging';
import {PLATFORM} from 'aurelia-pal';

import {CToastsService} from '@bindable-ui/bindable';

import {ILazyLoaderActions} from 'components/lazy-loader';
import {HyperionPolling} from 'services/hyperion-polling';

import {SsoDomainService} from '../services/sso-domain';
import {SsoDomain} from '../models/sso-domain';

function sorted(key: string, value: string): number {
  switch (value) {
    case `-${key}`:
      return 1;
    case key:
      return -1;
    default:
      return 0;
  }
}

@autoinject()
export class SsoIndex {
  public pollTracker: HyperionPolling;
  public pollInterval: number = 10 * 10000;

  public modalView = null;
  public modalModel = null;
  public showModal = false;

  public selected: SsoDomain = null;
  public trackedRows: Set<string>;

  public loaderActions: ILazyLoaderActions = {
    getMore: () => this.ssoService.getMore(),
  };

  protected logger: Logger;

  @computedFrom('ssoService.isLoading', 'ssoService.isLoadingMore')
  public get isLoading(): boolean {
    return this.ssoService.isLoading || this.ssoService.isLoadingMore;
  }

  @computedFrom('ssoService.order')
  public get sortedCreated(): number {
    return sorted('created', this.ssoService.order);
  }

  @computedFrom('ssoService.order')
  public get sortedDomain(): number {
    return sorted('domain', this.ssoService.order);
  }

  @computedFrom('ssoService.order')
  public get sortedTitle(): number {
    return sorted('search', this.ssoService.order);
  }

  constructor(public ssoService: SsoDomainService, public notificationService: CToastsService) {
    this.logger = LogManager.getLogger('Acuity | Identity | SSO');

    this.pollTracker = new HyperionPolling({
      callbackFn: res => this.ssoService.processPollData(res),
      getParams: () => this.ssoService.params,
      ms: this.pollInterval,
      promiseFn: args => {
        if (document.hidden) {
          return null;
        }

        return this.ssoService.pollRecords(args);
      },
      useAfter: true,
    });
  }

  /*
    Aurelia Hooks
   */

  public async attached(): Promise<void> {
    if (this.ssoService) {
      const params = {};
      await this.ssoService.getRecords(params);
    }

    if (this.pollTracker) {
      this.pollTracker.start();
    }

    this.trackedRows = new Set();
  }

  public detached(): void {
    if (this.pollTracker) {
      this.pollTracker.stop();
      this.pollTracker = null;
    }
  }

  /*
    Table Methods
   */

  /**
   * Handles table row click events
   * @param event
   */
  public rowClicked(event: any) {
    event.preventDefault();

    const tr = event.target.closest('lynk-tr');
    const {id} = tr.dataset;

    if (!id) {
      return;
    }

    const btn = event.target.closest('lynk-button');

    if (btn) {
      const {dataset} = btn;
      if (dataset?.action === 'view_config') {
        this.showDomainConfigModal(id);
        return;
      }

      if (dataset?.action === 'view_users') {
        this.showDomainUsersModal(id);
        return;
      }
    }

    this.trackRow(id);
  }

  public async sortRecords(key: string, defaultSortDirection: string = null) {
    const {order} = this.ssoService;
    let newOrder = '';

    if (order.replace(/^-/, '') === key) {
      newOrder = /^-/.test(order) ? key : `-${key}`;
    } else {
      newOrder = defaultSortDirection || key;
    }

    this.ssoService.getRecords({order: newOrder});
  }

  /*
    Public Methods
   */

  public async deleteDomains(): Promise<void> {
    if (this.ssoService.isProcessing || this.trackedRows.size === 0) {
      return;
    }

    const ids = Array.from(this.trackedRows);

    const promises: Promise<any>[] = ids.map(id => this.deleteDomain(id));

    await Promise.all(promises);

    this.trackedRows = new Set<string>();
    this.pollTracker.manualPoll();
  }

  public showDomainConfigModal(domainId?: string) {
    if (this.ssoService.isLoading) {
      return;
    }

    this.modalModel = {
      domainId,
      actions: {
        onClose: () => {
          this.closeDialog();
        },
        onSave: () => {
          this.closeDialog();
        },
      },
    };

    this.modalView = PLATFORM.moduleName('apps/acuity/panels/identity/sso/modals/domain-config/index');
    this.showModal = true;
  }

  public async showDomainUsersModal(domainId?: string) {
    if (this.ssoService.isLoading) {
      return;
    }

    this.modalModel = {
      domainId,
      actions: {
        onClose: () => {
          this.closeDialog();
        },
        onSave: () => {
          this.closeDialog();
        },
      },
    };

    this.modalView = PLATFORM.moduleName('apps/acuity/panels/identity/sso/modals/domain-users/index');
    this.showModal = true;
  }

  public trackRow(id: string) {
    if (this.trackedRows.has(id)) {
      this.trackedRows.delete(id);
    } else {
      this.trackedRows.add(id);
    }

    // Aurelia can't track a set, so replace it to force rendering updates
    this.trackedRows = new Set(this.trackedRows);
  }

  /*
    Private Methods
   */

  private closeDialog() {
    this.showModal = false;
    this.modalModel = null;
    this.modalView = null;

    this.pollTracker.manualPoll();
  }

  private async deleteDomain(id: string) {
    const {title} = this.ssoService.records.find(d => d.id === id);

    try {
      await this.ssoService.deleteRecord(id);

      this.notificationService.success(`Deleted ${title}`);
    } catch (err) {
      this.logger.error(`Failed to delete ${title} - ${err.message}`);
    }
  }
}

export default SsoIndex;
