import {CToastsService} from '@bindable-ui/bindable';
import {autoinject, computedFrom} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {activationStrategy, Router} from 'aurelia-router';
import * as moment from 'moment-timezone';

import {ITimezoneActions} from 'resources/components/v-timezone-picker/v-timezone-picker';
import {Authorization, authorizationConstants} from 'services/authorization';
import {SessionService} from 'services/session';
import {LiveChannelsService} from '../services/live-channels';

export const PUBLISH_TITLE = 'Publish';

@authorizationConstants
@autoinject()
export class ChannelSingleIndex {
  @computedFrom('liveChannels.channelSinglePoll', 'liveChannels.currentModel.source_id')
  public get currentBeamUrl() {
    if (this.liveChannels.currentModel && this.liveChannels.currentModel.source_id) {
      return `/static/cms2/index.html#/content/?link=encoding_beam_${encodeURIComponent(
        this.liveChannels.currentModel.source_id,
      )}`;
    }
    return '';
  }

  @computedFrom('liveChannels.channelSinglePoll', 'liveChannels.currentModel.status_type')
  public get currentHealth() {
    if (
      this.liveChannels.currentModel.status_type !== 'ad' &&
      this.liveChannels.currentModel.source_type &&
      this.liveChannels.currentModel.source_type.includes('override')
    ) {
      return 'override';
    }
    if (
      !this.liveChannels.currentModel ||
      !this.liveChannels.currentModel.status_type ||
      this.liveChannels.currentModel.status_type === 'no source'
    ) {
      return 'neutral';
    }
    if (this.liveChannels.currentModel.status_type === 'warning') {
      return 'warning';
    }
    if (this.liveChannels.currentModel.status_type === 'error') {
      return 'danger';
    }
    if (this.liveChannels.currentModel.status_type !== 'ad' && this.liveChannels.currentModel.source_type === 'vod') {
      return 'default';
    }
    if (this.liveChannels.currentModel.status_type === 'healthy') {
      return 'healthy';
    }
    if (this.liveChannels.currentModel.status_type === 'ad') {
      return 'ad';
    }

    return this.liveChannels.currentModel.status_type;
  }

  @computedFrom(
    'liveChannels.channelSinglePoll',
    'liveChannels.currentModel.has_slicer',
    'liveChannels.currentModel.slicer_id',
  )
  public get currentStatusText() {
    return this.liveChannels.currentModel && this.liveChannels.currentModel.source_desc
      ? this.liveChannels.currentModel.source_desc
      : 'No Source';
  }

  @computedFrom('liveChannels.currentModel.status_type')
  public get currentStatusLabel() {
    if (
      !this.liveChannels.currentModel ||
      !this.liveChannels.currentModel.status_type ||
      this.liveChannels.currentModel.status_type === 'no source'
    ) {
      if (
        this.liveChannels.currentModel.status_type !== 'ad' &&
        this.liveChannels.currentModel.source_type &&
        this.liveChannels.currentModel.source_type.includes('override')
      ) {
        return 'Override: No Source';
      }
      return 'No Source';
    }
    if (this.liveChannels.currentModel.status_type === 'error') {
      return 'Stalled Live Asset Critical';
    }
    if (this.liveChannels.currentModel.status_type === 'warning') {
      return 'Stalled Live Asset Warning';
    }
    if (this.liveChannels.currentModel.status_type !== 'ad' && this.liveChannels.currentModel.source_type === 'vod') {
      return 'Scheduled Asset';
    }
    if (
      this.liveChannels.currentModel.status_type !== 'ad' &&
      this.liveChannels.currentModel.source_type &&
      this.liveChannels.currentModel.source_type.includes('override')
    ) {
      return 'Schedule Override';
    }
    if (this.liveChannels.currentModel.status_type === 'healthy') {
      return 'Live Asset';
    }
    if (this.liveChannels.currentModel.status_type === 'ad') {
      return 'Ad Break';
    }
    if (this.liveChannels.currentModel.status_type === 'replay') {
      return 'Replay';
    }

    return this.liveChannels.currentModel.status_type;
  }

  @computedFrom('liveChannels.currentModel.status_type')
  public get pulseSpeed() {
    if (this.liveChannels.currentModel.status_type === 'error') {
      return 'fast';
    }
    if (
      this.liveChannels.currentModel.status_type === 'warning' ||
      (this.liveChannels.currentModel.source_type && this.liveChannels.currentModel.source_type.includes('override'))
    ) {
      return 'medium';
    }
    if (
      this.liveChannels.currentModel.status_type === 'ad' ||
      this.liveChannels.currentModel.status_type === 'replay' ||
      this.liveChannels.currentModel.source_type === 'vod' ||
      this.liveChannels.currentModel.status_type === 'healthy' ||
      this.liveChannels.currentModel.status_type === 'running smooth'
    ) {
      return 'slow';
    }

    return 'none';
  }

  @computedFrom('override_content_id', 'liveChannels.currentModel')
  get disableTriggerOverride(): boolean {
    const {override = {}} = this.liveChannels.currentModel;
    const isValid = this.isOverrideValid(this.override_content_id);

    if ((override.id === undefined && this.override_content_id.length === 0) || !isValid) {
      return true;
    }

    return false;
  }

  @computedFrom('liveChannels.currentModel.override')
  get hasOverrideRunning(): boolean {
    const {override} = this.liveChannels.currentModel;

    return !!(override && override.id);
  }

  @computedFrom('liveChannels.currentModel.override', 'isOverrideSaving', 'override_content_id')
  get getOverrideState(): string {
    const {override = {}} = this.liveChannels.currentModel;
    const isValid = this.isOverrideValid(this.override_content_id);

    if ((!this.override_content_id.length && !override.id) || !isValid) {
      return 'disabled';
    }
    if (this.isOverrideSaving) {
      return 'thinking';
    }

    return '';
  }

  public router: Router;
  public routes: any;
  public currentTimeZone = moment().tz(moment.tz.guess()).format('z');
  public currentTime = moment(new Date()).format('HH:mm:ss');
  public isDeleting: boolean = false;
  public serverTimeTracker;
  public driftTracker;
  public startTimer;
  public serverTime;
  public serverTimeOffset: number = 0;
  public timeTracker;
  public timezoneActions: ITimezoneActions = {
    onChange: () => {
      if (this.liveChannels.routeCallback) {
        this.liveChannels.routeCallback();
      }
    },
  };

  public override_content_id: string = '';
  public overrideTip: any;
  public overrideContent: any;
  public isOverrideSaving: boolean;
  public sidebar: any;

  constructor(
    public liveChannels: LiveChannelsService,
    public notification: CToastsService,
    public session: SessionService,
    public authorization: Authorization,
  ) {
    const restrict = authorization.restrictRoute;

    this.routes = [
      {
        moduleId: PLATFORM.moduleName('./details'),
        name: 'details',
        nav: true,
        route: '',
        title: 'Details',
      },
      {
        moduleId: PLATFORM.moduleName('./metadata'),
        name: 'metadata',
        nav: true,
        route: 'metadata',
        title: 'Metadata',
      },
      {
        moduleId: PLATFORM.moduleName('./blackout'),
        name: 'blackout',
        nav: true,
        route: 'blackout',
        title: 'Blackout',
      },
      {
        moduleId: PLATFORM.moduleName('./slate'),
        name: 'slate',
        nav: true,
        route: 'slate',
        title: 'Slate',
      },
      {
        moduleId: PLATFORM.moduleName('./playback'),
        name: 'playback',
        nav: true,
        route: 'playback',
        title: 'Playback',
      },
      restrict({
        moduleId: PLATFORM.moduleName('./publish'),
        name: 'publish',
        nav: true,
        route: 'publish',
        title: PUBLISH_TITLE,
      }),
      {
        activationStrategy: activationStrategy.replace,
        moduleId: PLATFORM.moduleName('./scheduler'),
        name: 'schedule',
        nav: true,
        route: 'schedule',
        title: 'Schedule',
      },
      // {
      //   // activationStrategy: activationStrategy.replace,
      //   moduleId: PLATFORM.moduleName('./modals/bulk-export/index'),
      //   name: 'scheduleExport',
      //   nav: false,
      //   route: 'schedule/export',
      //   title: 'Export',
      // },
    ];
  }

  public blockPopup = () => true;

  public isOverrideValid(value: string) {
    if (!value.length) {
      return true;
    }

    const valid = /^([a-zA-Z0-9]{32}:)?[a-zA-Z0-9_]{1,100}$/.test(value);
    const elem = this.overrideContent;

    if (elem) {
      elem.state = '';
      elem.errorMsg = '';
      if (!valid) {
        elem.state = 'error';
        elem.errorMsg = 'A valid slicer ID is required';
      }
    }
    return valid;
  }

  /*
   * Aurelia Hooks
   */

  public activate() {
    this.getServerTime();
    // Update diff every half-hour
    this.serverTimeTracker = setInterval(() => this.getServerTime(), 60000 * 30);

    // Calculate the time drift every 30 seconds
    this.driftTracker = setInterval(() => this.checkTimeDrift(), 30 * 1000);

    this.timeTracker = setInterval(() => {
      this.currentTime = moment(new Date()).format('HH:mm:ss');
    }, 1000);
  }

  public deactivate() {
    clearInterval(this.serverTimeTracker);
    clearInterval(this.timeTracker);
    clearInterval(this.driftTracker);

    this.override_content_id = '';
  }

  /*
   * Public Methods
   */

  public backToChannels() {
    this.router.navigate('/live-channels');
  }

  public configureRouter(config, router) {
    this.router = router;

    // fallback to account settings page.
    config.mapUnknownRoutes({redirect: this.routes[0].route[1]});
    config.map(this.routes);
  }

  public async delete() {
    if (this.isDeleting) {
      return;
    }

    this.isDeleting = true;

    try {
      const deleteSuccessful = await this.liveChannels.deleteChannels(this.liveChannels.currentModel.id);
      if (deleteSuccessful) {
        window.location.href = '#/live-channels';
      }
    } catch (e) {
      this.notification.error('Error deleting channel. Please try again.');
    }

    this.isDeleting = false;
  }

  public getNavTitle(title) {
    if (title === 'Schedule') {
      const {currentModel} = this.liveChannels;

      if (!currentModel || currentModel.use_chsched !== 2) {
        return 'History';
      }
    }

    return title;
  }

  public async getServerTime() {
    const now = moment();
    this.startTimer = performance.now();
    this.serverTime = await this.liveChannels.getServerTime();
    const endTimer = performance.now();

    const latency = (endTimer - this.startTimer) / 2 / 1000;

    const diff = moment.duration(moment(now).add(latency, 'seconds').diff(this.serverTime));

    // Inverse it
    this.serverTimeOffset = diff.asSeconds() * -1;
  }

  public checkTimeDrift() {
    if (!this.startTimer || !this.serverTime) {
      return;
    }

    const serverTime = moment(this.serverTime).add(this.startTimer / 1000, 'seconds');
    const diff = Math.abs(moment.duration(moment().diff(serverTime)).asSeconds());

    if (diff > 5) {
      // Get the server time again
      this.getServerTime();
    }
  }

  public openClipCreator() {
    // TODO: in cms1, url would be served by app dir if session.inDev is true.
    window.open(`/static/cms/clipping/dist/index.html?channel=${this.liveChannels.currentModel.id}`, '_blank');
  }

  public async toggleOverride() {
    const {currentModel} = this.liveChannels;

    const updateModel = {
      id: currentModel.id,
      override: !currentModel.override.id ? {id: this.override_content_id, type: 'slicer'} : {},
    };

    this.isOverrideSaving = true;

    try {
      this.liveChannels.currentModel = await this.liveChannels.saveChannel(updateModel);
      this.override_content_id = '';
    } catch (e) {
      if (e.message) {
        this.notification.error(e.message);
      } else {
        this.notification.error('Error updating channel.');
      }
    } finally {
      this.isOverrideSaving = false;
    }
  }
}
