import {autoinject} from 'aurelia-framework';
import {PLATFORM} from 'aurelia-pal';
import {Router} from 'aurelia-router';

@autoinject()
export class SyndicationIndex {
  public router: Router;
  public routes = [
    {
      moduleId: PLATFORM.moduleName('./jobs/index'),
      name: 'SyndicationJobsIndex',
      nav: true,
      route: [
        '',
        'jobs',
      ],
      title: 'Syndication Jobs',
    },
    {
      moduleId: PLATFORM.moduleName('./targets/index'),
      name: 'SyndicationTargetsIndex',
      nav: true,
      route: [
        'targets',
      ],
      title: 'Syndication Targets',
    },
  ];

  public configureRouter(config, router) {
    this.router = router;
    config.map(this.routes);
  }
}
