import {autoinject, computedFrom} from 'aurelia-framework';
import {isEqual} from 'lodash';
import {authorizationConstants} from 'services/authorization';
import {SyndicationPlatformType, SyndicationTargetProtocolType} from 'services/models/syndication';

@autoinject()
@authorizationConstants
export class PublishTargetFooter {
  public controller: any;
  public target: any;
  public model: any;

  public activate(model) {
    this.model = model.shared;
    this.controller = model.controller;
  }

  public async startSyndication() {
    const success = await this.model.startSchedule(this.model.id);
    if (success) {
      this.controller.cancel();
    }
  }

  public async stopSyndication() {
    const success = await this.model.stopSchedule(this.model.id);
    if (success) {
      this.controller.cancel();
    }
  }

  public async onSave() {
    const success = await this.model.saveSchedule(this.model);
    if (success) {
      this.model.resetTargetModel();
    }
  }

  public async testTarget() {
    await this.model.testTarget(this.model.syndication_target_id);
  }

  get isHls() {
    return (
      this.model.target_protocol === SyndicationTargetProtocolType.HLS_PUSH ||
      this.model.target_protocol === SyndicationTargetProtocolType.HLS_PULL
    );
  }

  get isIntegrated() {
    return (
      this.model.target_info.platform_config.integrated &&
      (this.model.target_info.platform === 'YouTube' || this.model.target_info.platform === 'Facebook')
    );
  }

  get isSrt() {
    return this.model.target_protocol === SyndicationTargetProtocolType.SRT;
  }

  public dictsEqual(dict1, dict2) {
    if (JSON.stringify(dict1) === JSON.stringify(dict2)) {
      return true;
    }
    return false;
  }

  @computedFrom(
    'model.video_quality',
    'model.video_quality_pristine',
    'model.scte35_enabled',
    'model.scte35_enabled_pristine',
    'model.region',
    'model.region_pristine',
    'model.target_platform_config.channel_id',
    'model.target_platform_config.channel_id_pristine',
    'model.target_platform_config.rays',
    'model.target_platform_config.rays_pristine',
    'model.target_platform_config.integrated_title',
    'model.target_platform_config.integrated_title_pristine',
    'model.target_platform_config.integrated_description',
    'model.target_platform_config.integrated_description_pristine',
    'model.target_platform_config.integrated_yt_privacy',
    'model.target_platform_config.integrated_yt_privacy_pristine',
    'model.target_platform_config.integrated_yt_made_for_kids',
    'model.target_platform_config.integrated_yt_made_for_kids_pristine',
    'model.target_platform_config.scte35_type',
    'model.target_platform_config.scte35_type_pristine',
    'model.target_platform_config.srt_latency',
    'model.target_platform_config.srt_latency_pristine',
    'model.target_platform_config.fast_channel_id',
    'model.target_platform_config.fast_channel_id_pristine',
    'model.source_url_params',
    'model.source_url_params_pristine',
    'model.url_params_active',
    'model.url_params_active_pristine',
  )
  get isDirty() {
    const {model} = this;
    // only for HLS, verify user selected at least one ray
    if (this.isHls && model.target_platform_config.rays.length === 0) {
      return false;
    }
    if (this.isIntegrated && model.target_platform_config.integrated_title === '') {
      return false;
    }
    return (
      model.video_quality !== model.video_quality_pristine ||
      model.scte35_enabled !== model.scte35_enabled_pristine ||
      model.region !== model.region_pristine ||
      model.url_params_active !== model.url_params_active_pristine ||
      model.target_platform_config.channel_id !== model.target_platform_config.channel_id_pristine ||
      (model.target_platform_config.rays.length > 0 &&
        !isEqual(model.target_platform_config.rays, model.target_platform_config.rays_pristine)) ||
      model.target_platform_config.integrated_title !== model.target_platform_config.integrated_title_pristine ||
      // tslint:disable-next-line:max-line-length
      model.target_platform_config.integrated_description !==
        model.target_platform_config.integrated_description_pristine ||
      // tslint:disable-next-line:max-line-length
      model.target_platform_config.integrated_yt_privacy !==
        model.target_platform_config.integrated_yt_privacy_pristine ||
      // tslint:disable-next-line:max-line-length
      model.target_platform_config.integrated_yt_made_for_kids !==
        model.target_platform_config.integrated_yt_made_for_kids_pristine ||
      // tslint:disable-next-line:max-line-length
      model.target_platform_config.srt_latency !== model.target_platform_config.srt_latency_pristine ||
      !this.dictsEqual(model.source_url_params, model.source_url_params_pristine) ||
      model.target_platform_config.scte35_type !== model.target_platform_config.scte35_type_pristine ||
      model.target_platform_config.fast_channel_id !== model.target_platform_config.fast_channel_id_pristine
    );
  }

  @computedFrom(
    'model.is_new',
    'model.video_quality',
    'model.video_quality_pristine',
    'model.scte35_enabled',
    'model.scte35_enabled_pristine',
    'model.region',
    'model.region_pristine',
    'model.target_platform_config.channel_id',
    'model.target_platform_config.channel_id_pristine',
    'model.target_platform_config.rays',
    'model.target_platform_config.rays_pristine',
    'model.target_platform_config.integrated_title',
    'model.target_platform_config.integrated_title_pristine',
    'model.target_platform_config.integrated_description',
    'model.target_platform_config.integrated_description_pristine',
    'model.target_platform_config.integrated_yt_privacy',
    'model.target_platform_config.integrated_yt_privacy_pristine',
    'model.target_platform_config.integrated_yt_made_for_kids',
    'model.target_platform_config.integrated_yt_made_for_kids_pristine',
    'model.target_platform_config.scte35_type',
    'model.target_platform_config.scte35_type_pristine',
    'model.target_platform_config.srt_latency',
    'model.target_platform_config.srt_latency_pristine',
    'model.target_platform_config.fast_channel_id',
    'model.target_platform_config.fast_channel_id_pristine',
    'model.source_url_params',
    'model.source_url_params_pristine',
    'model.url_params_active',
    'model.url_params_active_pristine',
  )
  get saveButtonState() {
    const {model, isDirty, isHls, isIntegrated, isSrt} = this;
    // for HLS we want to validate and require at least one ray selected,
    // so it must be "dirty" and not pass the if "is_new" part
    if (isHls && !isDirty) {
      return 'disabled';
    }
    // for YT/FB integrated we want the title to be not empty
    if (isIntegrated && !isDirty) {
      return 'disabled';
    }
    // for SRT validate srt latency is valid number and allowed range
    if (isSrt) {
      const srtLatency: any = model.target_platform_config.srt_latency;
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(srtLatency) || srtLatency < 120 || srtLatency > 15000) {
        return 'disabled';
      }
    }
    if (isDirty || model.is_new) {
      return '';
    }
    return 'disabled';
  }

  @computedFrom('model.is_new', 'model.isDirty', 'model.status')
  get disableStart() {
    const {model, isDirty} = this;
    return model.is_new || isDirty || this.model.status !== 'stopped' || this.wurlWithoutAccess;
  }

  @computedFrom('model.status')
  get disableStop() {
    return this.model.status !== 'active' || this.wurlWithoutAccess;
  }

  @computedFrom('model.target_info.platform', 'model.session')
  get wurlWithoutAccess() {
    return this.model.target_info.platform === SyndicationPlatformType.WURL && !this.model.session.hasFastAccess;
  }
}
